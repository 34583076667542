import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { CouponService } from 'src/shared/coupon.service';

@Component({
  selector: 'form-apply-coupon',
  templateUrl: './form-apply-coupon.component.html',
  styleUrls: ['./form-apply-coupon.component.scss']
})
export class FormApplyCouponComponent implements OnInit {
  @Input() applyCouponTemplate: string
  @Input() keyword: string = '';
  @Input() defaultSearchKeyword: string[] = [];
  @Input() defaultSearchKeywordIndex: number = 0;
  @Output() onActionNavigateToApplyCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionEmitKeyword: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  CouponCardTemplate = CouponCardTemplate;
  defaultPlaceHolder = 'ระบุโค้ด';
  currentPlaceholder: string = 'ระบุโค้ด';

  constructor(
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  goApplyCoupon() {
    this.onActionNavigateToApplyCoupon.emit();
  }

  searchCouponAction() {
    this.keyword = this.keyword.replace(/\s+/g, "").toUpperCase();
    this.onActionEmitKeyword.emit(this.keyword)
  }

  onSearchChange() {

  }

  clearKeyword() {
    this.keyword = "";
  }

}
