export enum AnalyticAction {
    CLICK = 'Click',
    VIEW = 'View',
    ERROR = 'Error',
    POPUP = 'Popup',
}

export enum AnalyticLabel {
    CONFIRM = 'Confirm',
    CANCEL = 'Cancel',
    OK = 'Ok',
    SEE_OTHER = 'SeeOthers',
    REGISTER = 'Register',
    LATER = 'Later',
    REGISTERING = 'Registering',
    CLOSE = 'Close',
}

export enum AnalyticCategory {
    COUPON_LANDING = 'CouponLanding',
    COUPON_LANDING_MULTIPLE = 'CouponLandingMultiple',
    MY_COUPON_CANNOT_USE_MULTIPLE = 'MyCouponCannotUseMultiple',
    MY_COUPON_USE_MULTIPLE = 'MyCouponUseMultiple',
    MY_COUPON_READY_TO_USE_MULTIPLE = 'MyCouponReadyToUseMultiple',
    COUPON_COPAY_CHOOSING = 'CouponCoPayChoosing',
    COUPON_COPAY_CHOOSING_MULTIPLE = 'CouponCoPayChoosingMultiple',
    COUPON_SEARCH = 'CouponSearch',
    COUPON_SEARCH_MULTIPLE = 'CouponSearchMultiple',
    APPY_COUPON = 'ApplyCoupon',
    APPY_COUPON_MULTIPLE = 'ApplyCouponMultiple',
    COUPON_DETAILS = 'CouponDetails',
    COUPON_CODE_SCREEN = 'CouponCodeScreen',
    COUPON_CONFIRM_USING = 'CouponConfirmUsing',
    COUPON_CONFIRM_USED = 'CouponConfirmUsed',
    COUPON_API_ERROR = 'CouponAPIError',
    COUPON_NOTELIGIBLE = 'CouponNotEligible',
    COUPON_NOTFOUND = 'CouponNotFound',
    COUPON_EXPIRED = 'CouponExpired',
    COUPON_OUT_OF_QUOTA = 'CouponOutOfQuota',
    COUPON_PTP_REGISTER = 'CouponPTPRegister',
    COUPON_COMING_SOON = 'CouponComingSoon',
    COUPON_UN_AUTHORIZED = 'CouponUnAuthorized',
    COUPON_UNDER_MAINTENANCE = 'CouponUnderMaintenance',
    COUPON_NAVIGATE_EXTERNAL_URL = 'CouponNavigateExternalUrl',
}

export enum AnalyticFirebaseParam {
    CAMPAIGN_ID = 'campaign_id',
    C_DIM_19 = 'c_dim_19',
    C_DIM_14 = 'c_dim_14',
    INFORMATION = 'Information',
    HTTP_STATUS_CODE = 'HTTP Status Code',
    ERROR_CODE = 'Error Code',
    IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR = 'Is Financial Transaction System Error',
    ERROR_TITLE = 'Error Title',
}


export enum interfaceToNativeCommand {
    COMMAND_ROUTING = 'ROUTING',
}

export enum interfaceToNativeContentDestination {
    DESTINATION_PT_PAYMENT = 'PT_PAYMENT',
    DESTINATION_PT_TOPUP = 'PT_TOPUP',
    DESTINATION_EXTERNAL_WEBVIEW = 'EXTERNAL_WEBVIEW',
    DESTINATION_SCAN_COPAY = 'PT_SCANQR'
}

