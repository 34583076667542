import { forwardRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as RootSelectors from './root-selectors';
import { AppState, ICouponAccressToken, JWTSession, Session } from './root-store.model';
import { RootStoreModule } from './root-store.module';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ICouponResponse } from '../model/coupon-model.model';

@Injectable({ providedIn: forwardRef(() => RootStoreModule) })
export class RootStoreService {
  api_url = environment.api_url;
  session: Session | undefined;
  jwtSession: JWTSession | undefined;
  session$ = this.store.select(RootSelectors.selectSession);
  jwtSession$ = this.store.select(RootSelectors.selectJWTSession);
  couponChannelSession$ = this.store.select(RootSelectors.selectCouponChannel);

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.jwtSession$.subscribe((jwtSession) => {
      this.jwtSession = jwtSession;
    });
  }

  postAuthVerify(authorizationCode: string, webConfig: string): Observable<ICouponResponse<ICouponAccressToken>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')

    return this.http.post<ICouponResponse<ICouponAccressToken>>(
      this.api_url + `/api/v1/mp/token/auth/core-web`, { authorizationCode: authorizationCode, webConfig: webConfig }, { headers: headers }
    ).pipe(
      map((response: ICouponResponse<ICouponAccressToken>) => { return response })
    );
  }
}
