<div #loadingPage class="sticky-skeleton" [class]="isAndroid ? ' android-device bg-blue bg-blue-android-device' : 'bg-blue'">

    <!-- // Width and display text depend on Component -->
    <skeleton-header [withPadding]="withPadding" [path]="path"></skeleton-header>

    <!-- // Home -->
    <div [ngClass]="{'padding-header': path !== PageRoute.HOME && path !== PageRoute.QR_CODE && path !== PageRoute.MY_COUPON && path !== PageRoute.COUPON_LIST}">
        <div class="column" *ngIf="path === PageRoute.HOME">
            <div class="button is-medium is-fullwidth btn-coupon has-text-left text-btn">
                <div class="item">
                    <ngx-skeleton-loader count="1" animation="progress" [theme]="{ 
                            'height': '24px', 
                            'width': '135px',
                            'background': '#F2F2F2'
                    }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                        'height': '24px', 
                        'width': '24px' , 
                        'position': 'absolute', 
                        'right': '16px',
                        'background': '#F2F2F2'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="column" *ngIf="path === PageRoute.MY_COUPON || path === PageRoute.COUPON_LIST">
            <div class="button is-medium is-fullwidth btn-coupon has-text-left text-btn" style="padding: 0px !important;">
                <div class="item">
                    <ngx-skeleton-loader count="1" animation="progress" [theme]="{ 
                            'height': '48px', 
                            'width': '100%',
                            'background': '#F2F2F2',
                            'border-radius': '24px'
                    }"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>

    <div class="notification"
        [ngClass]="{'bg-white': path === PageRoute.COUPON_DETAIL,  'mg-CNX':!withPadding, 'bg-qr': path === PageRoute.QR_CODE}">
        <div *ngIf="path === PageRoute.MY_COUPON">
            <!-- *ngIf="path === PageRoute.MY_COUPON" -->
            <div class="column pt-0 pl-0 pr-0">
                <div class="button is-medium is-fullwidth section-tab">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column has-text-left">
                            <div class="item">
                                <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                    'width': '88px',
                                    'height': '24px',
                                    'margin-top': '10px',
                                    'margin-left': '0px',
                                    'border-radius': '10px',
                                    'background': '#F2F2F2'
                                  }">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div class="column">
                            <div class="item">
                                <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                    'width': '88px',
                                    'height': '24px',
                                    'margin-top': '10px',
                                    'border-radius': '10px',
                                    'background': '#F2F2F2'
                                  }">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div class="column has-text-right">
                            <div class="item">
                                <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                    'width': '88px',
                                    'height': '24px',
                                    'margin-top': '10px',
                                    'margin-right': '0px',
                                    'border-radius': '10px',
                                    'background': '#F2F2F2'
                                  }">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="item">
                        <ngx-skeleton-loader count="3" appearance="circle" [theme]="{
                            'width': '88px',
                            'height': '24px',
                            'margin-top': '10px',
                            'border-radius': '10px',
                            'margin-right': '30px'
                          }">
                        </ngx-skeleton-loader>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- coupon card -->
        <div *ngIf="path === PageRoute.HOME || path === PageRoute.COUPON_LIST || path === PageRoute.MY_COUPON">
            <skeleton-card></skeleton-card>
        </div>

        <!-- // Detail -->
        <div *ngIf="path === PageRoute.COUPON_DETAIL">
            <skeleton-detail>
            </skeleton-detail>
        </div>

        <!-- // QR Code -->
        <div *ngIf="path === PageRoute.QR_CODE">
            <skeleton-qr-code [withPadding]="withPadding" [isAndroid]="isAndroid">
            </skeleton-qr-code>
        </div>

    </div>
</div>