import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilService } from 'src/app/service/util.service';
import { Store } from '@ngrx/store';
import * as RootStoreActions from './root-store/root-actions';
import { AppState } from './root-store/root-store.model';
import { PageRoute } from './configs/route-config';
import { CouponService } from 'src/shared/coupon.service';
import { RootStoreService } from './root-store/root-store.service';
import { CouponChannel } from './model/coupon-enum.model';
import { Location } from '@angular/common';

declare var window: Window;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  isRelative: boolean = false;
  currentChannel = "";
  stepRouteConfig: string[] = [];
  currentPage: string = '';

  constructor(
    private router: Router,
    private utilService: UtilService,
    private store: Store<AppState>,
    private couponService: CouponService,
    private rootStoreService: RootStoreService,
    private location: Location
  ) {
    this.displayFirstSkeletonLoadAsRoutePage();
  }

  ngOnInit(): void {
    this.rootStoreService.couponChannelSession$.pipe(filter((v) => Boolean(v))).subscribe((response) => {
      this.currentChannel = response;
    });

    this.checkNetworkStatus();
    this.store.dispatch(RootStoreActions.initialization());
    this.checkRouterEvent();

    this.couponService.stepRouteConfig$.subscribe((routes: string[]) => {
      this.stepRouteConfig = routes;
    });

    this.couponService.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  displayFirstSkeletonLoadAsRoutePage() {
    const path = this.location.path()

    if (path.includes(PageRoute.PATH_HOME) || path === '/') {
      this.utilService.startLoading(PageRoute.HOME);
      return
    }

    if (path.includes(PageRoute.PATH_MY_COUPON)) {
      this.utilService.startLoading(PageRoute.MY_COUPON);
      return
    }

    if (path.includes(PageRoute.PATH_COUPON_DETAIL)) {
      this.utilService.startLoading(PageRoute.COUPON_DETAIL);
      return
    }

    if (path.includes(PageRoute.PATH_COUPON_LIST)) {
      localStorage.removeItem("selectedCoupon");
      localStorage.removeItem("selectedCategory");
      this.utilService.startLoading(PageRoute.COUPON_LIST);
      return
    }

    if (path.includes(PageRoute.PATH_QR_CODE)) {
      this.utilService.startLoading(PageRoute.QR_CODE);
      return
    }

  }

  checkRouterEvent() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (this.currentChannel !== CouponChannel.PT) {
          window.history.pushState(null, '', null);
          this.addPopStateEventListener();
        }
        if (
          event.url.includes(PageRoute.PATH_QR_CODE) || event.url.includes(PageRoute.QR_CODE)
        ) {
          this.isRelative = true;
        } else {
          this.isRelative = false;
        }
      })
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).subscribe(status => {
      if (status?.type === 'offline' && !navigator.onLine) {
        this.router.navigateByUrl('/offline')
      }
    });
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }


  private addPopStateEventListener(): void {
    window.addEventListener('popstate', () => {
      window.history.pushState(null, '', null);
    });
  }


}
