import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState, Session } from './root-store.model';

// export const selectFeature = (state: AppState) => state;
export const selectFeature = createFeatureSelector<Session>('session');

export const selectSession = createSelector(
  selectFeature,
  (state: Session) => state
);

export const selectWebToken = createSelector(
  selectFeature,
  (state: Session) => state.webToken
);

export const selectJWTSession = createSelector(
  selectFeature,
  (state: Session) => state.jwtSession
);

export const selectCouponChannel = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.couponChannel
);

export const selectStatus = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.status
);

export const selectCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.callbackUrl
);

export const selectRegisterCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.registerCallbackUrl
);

export const selectProcessingCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.processingCallbackUrl
)

export const selectWithHeader = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.withHeader
);

export const selectPaymentCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.paymentCallbackUrl
);

export const selectChooseCouponCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.chooseCouponCallbackUrl
);

export const selectPreviousUrl = createSelector(
  selectFeature,
  (state: Session) => state.previousUrl
);

export const selectCurrentPage = createSelector(
  selectFeature,
  (state: Session) => state.currentPage
);

export const selectedCoupon = createSelector(
  selectFeature,
  (state: Session) => state.selectedCoupon
);

export const selectCouponId = createSelector(
  selectedCoupon,
  (selectedCoupon) => selectedCoupon.couponId
);

export const selectStepRouteConfig = createSelector(
  selectFeature,
  (state: Session) => state.stepRouteConfig
);
export const selectCategoryId = createSelector(
  selectFeature,
  (state: Session) => state.selectedCategoryId
);

export const selectIsInitialised = createSelector(
  selectFeature,
  (state: Session) => state.isInitialised
);