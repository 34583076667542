import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNil } from 'lodash';
import { Subject } from 'rxjs';
import { PageRoute } from 'src/app/configs/route-config';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  pageRoute: PageRoute;
  @ViewChild('loadingPage') loadingPage: any;

  constructor(
    private utilService: UtilService
  ) {
  }

  ngOnInit(): void {
    // this.utilService.startLoading();
    this.utilService.loadingStatus.subscribe((value: any) => {

      this.pageRoute = value.pageRoute;
      if (this.loading == !value.isloading) {
        this.toggleContent(value.isloading);
      }
    })
  }

  ngAfterViewInit() {
    this.preventSwipe();
  }

  toggleContent(val: any) {
    this.loading = val;

    if (this.loading) {
      this.loadingPage.nativeElement.hidden = false;
    } else {
      this.loadingPage.nativeElement.hidden = true;
    }
  }

  preventSwipe() {
    // prevent swipe to navigate gesture
    const element = document.getElementById("loading-content");
    let pointPage: number = 0;

    element!.addEventListener('touchstart', (e: TouchEvent) => {
      pointPage = e.touches[0].pageX;

      if (isNil(pointPage) || (pointPage > 27 && pointPage < window.innerWidth - 27)) {
        return true;
      }

      e.preventDefault();
      return false;

    }, { passive: false });

    element!.addEventListener('touchmove', (e: TouchEvent) => {
      pointPage = e.touches[0].pageX;

      if (isNil(pointPage) || (pointPage > 27 && pointPage < window.innerWidth - 27)) {
        return true;
      }

      e.preventDefault();
      return false;

    }, { passive: false });

  }

  ngOnDestroy(): void {
    this.utilService.loadingStatus.unsubscribe();
  }
}
