<ng-container *ngIf="isloadingApplyCoupon else resultApplyCoupon">
    <div [class]="isAndroid ? ' android-device' : ''" class="bg-standard loading-page">
        <div
            [class]="'header has-text-centered header-flex' + (withHeader ? '' : ' without-header') + (isAndroid ? ' android-header' : '')">
            <div class="has-text-left back-btn">
                <a [routerLink]="(stepRouteConfig$ | async)![(stepRouteConfig$ | async)!.length - 2]"
                    [replaceUrl]="true" (click)="removeStepRouteConfig();" class="link-item"
                    data-testid="apply-coupon-back">
                </a>
            </div>
            <div class="header-title">
                <h3>ระบุโค้ด</h3>
            </div>
            <div class="header-temp-last">
            </div>
        </div>

        <div id="apply-coupon-content">

            <div class="column container-apply-coupon" #column>
                <div class="w-100">
                    <form-apply-coupon [applyCouponTemplate]="applyCouponTemplate" [keyword]="applyCouponKeyword">
                    </form-apply-coupon>
                </div>
            </div>

            <div class="coupon-list-card"></div>

            <div [class]="'notification pd-content' + (withHeader ? '' : ' mg-CNX')">

                <div class="loading-search-coupon">
                    <skeleton-card [isloadingSearchCoupon]="true" [numberOfSkeletonCards]="1"></skeleton-card>
                </div>

            </div>
        </div>
    </div>
</ng-container>

<ng-template #resultApplyCoupon>

    <div [class]="isAndroid ? ' android-device' : ''" class="bg-standard loading-page">
        <div
            [class]="'header has-text-centered header-flex' + (withHeader ? '' : ' without-header') + (isAndroid ? ' android-header' : '')">
            <div class="has-text-left back-btn">
                <a [routerLink]="(stepRouteConfig$ | async)![(stepRouteConfig$ | async)!.length - 2]"
                    [replaceUrl]="true" (click)="removeStepRouteConfig();" class="link-item"
                    data-testid="search-coupon-back">
                </a>
            </div>
            <div class="header-title">
                <h3>ระบุโค้ด</h3>
            </div>
            <div class="header-temp-last">
            </div>
        </div>

        <div id="apply-coupon-content">

            <ng-container *ngIf="this.countTimeCoupon > 0 && this.countTimeCoupon < 3600">
                <countdown hidden [config]="{ leftTime: this.countTimeCoupon }" (event)="handleEvent($event)">
                </countdown>
            </ng-container>

            <div class="column container-apply-coupon" #column>
                <div class="w-100">
                    <form-apply-coupon #formApplyCoupon [applyCouponTemplate]="applyCouponTemplate"
                        [keyword]="applyCouponKeyword" (onActionEmitKeyword)="onLoadApplyCoupon($event)">
                    </form-apply-coupon>
                </div>
            </div>
            <div class="coupon-list-card"></div>

            <div [class]="'notification pd-content' + (withHeader ? '' : ' mg-CNX')">

                <div class="wrap-scroll"
                    *ngIf="applyCouponStatus === ApplyCouponActionType.COLLECT || applyCouponStatus === ApplyCouponActionType.DISPLAY">

                    <ng-container *ngFor="let coupon of applyCouponResult; let i = index">
                        <ng-container *ngFor="let couponInfo of coupon.couponInfos; let j = index">
                            <ng-container
                                *ngIf="coupon.hasCountdownTimer && couponInfo.countdownTimeInSeconds &&couponInfo.countdownTimeInSeconds > 0 && couponInfo.status === CouponStatus.REDEEMED">
                                <countdown hidden [config]="{ leftTime: couponInfo.countdownTimeInSeconds }"
                                    (event)="handleCountdownEvent($event, i, j)"></countdown>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <apply-coupon-card-horizontal #couponCard [coupons]="applyCouponResult"
                        [applyCouponKeyword]="applyCouponKeyword" [selectedCoupon]="selectedCoupon$| async"
                        [couponChannel]="couponChannel" [entryPoint]="entryPoint" [userSOF]="userSOF"
                        [chooseCouponCallbackUrl]="chooseCouponCallbackUrl" [paymentCallbackUrl]="paymemtCallbackUrl"
                        [registerCallbackUrl]="registerCallbackUrl" [processingCallbackUrl]="processingCallbackUrl"
                        [stepRouteConfig]="(stepRouteConfig$ | async)!"
                        (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                        (emitOpenDialogButtom)="openOverlay($event.coupon, $event.isInChannel)"
                        (onActionUseCoupon)="useCoupon($event.element ,$event.coupon)"
                        (onActionSetStepRoute)="setStepRouteFlag($event.couponId,$event.isUseCoupon)"
                        (onActionSelectedCoupon)="onActionSelectedCoupon($event)"
                        (onActionUseCouponCopay)="onActionUseCouponCopay($event.eventLabel , $event.campaignCode)"
                        (onActionUseCouponPaymentTopup)="onActionUseCouponPaymentTopup($event.eventLabel, $event.campaignCode, $event.destination)"
                        (onActionUseCouponExternalURL)="navigateExternalURL($event.coupon , $event.externalUrl)"
                        (onActionPermissionError)="displayPermissionError($event.campaignCode,$event.permisionCoupon,$event?.isNavigate)"
                        (onActionSetToLoadFech)="setToLoadFech()">
                    </apply-coupon-card-horizontal>
                </div>

                <div *ngIf="applyCouponStatus === ApplyCouponActionType.UNMATCH">
                    <div class="">
                        <div class="container margin-top">
                            <div class="has-text-centered">
                                <img class="coupon-empty-img" src="../../../assets/images/coupon_empty.png">
                                <p class="font-bold space-pt" data-testid="empty-apply-coupon">
                                    ไม่พบโค้ดที่คุณระบุ
                                </p>
                                <p class="text-sub-title" data-testid="empty-apply-coupon-description">
                                    โค้ดไม่ถูกต้องหรือไม่สามารถใช้ได้
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div #toastPopup class="toast-noti is-hidden">
            <table>
                <tr>
                    <td><img class="mr-2" src="../../../assets/icon/Icon-checked.png" width="25"></td>
                    <td><span>เก็บคูปองสำเร็จ</span></td>
                </tr>
            </table>
        </div>
    </div>
</ng-template>