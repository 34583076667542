import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as RootStoreType from '../root-store/root-store.model';

import * as CouponStoreReducer from './coupon.reducers';
import { CouponEffect } from './coupon.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
        RootStoreType.COUPON_STATE,
        CouponStoreReducer.CouponReducer),
    EffectsModule.forFeature([CouponEffect]),
  ],
})
export class CouponStoreModule {}
