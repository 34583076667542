import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ICouponCategory } from 'src/app/model/coupon-model.model';
import { CouponService } from 'src/shared/coupon.service';
import { EventsParams, SwiperComponent } from 'swiper/angular';


@Component({
  selector: 'coupon-category-section',
  templateUrl: './coupon-category-section.component.html',
  styleUrls: ['./coupon-category-section.component.scss'],
})
export class CouponCategorySectionComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  @Input() categories: ICouponCategory[];
  @Input() selectedCategoryId: string | undefined | null;
  @Output() onActionSelectedCategory: EventEmitter<string> = new EventEmitter<string>();
  indexSlide: number | undefined = 0;
  initialCategory = false;
  limitCategory = false;
  centeredSlides = true;
  isPassSetTranslate = false;
  lastIndex = 0;
  categoryActive = '';
  currentIndex = 0;
  lastElementWidth = 0;
  snapGridLastIndex = 0;
  rerederCategoryType = false;


  constructor(
    private couponService: CouponService,
    private elem: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.lastIndex = this.categories.length ? this.categories.length - 1 : 0;
    this.indexSlide = this.categories.findIndex((category) => category.categoryUUID === this.selectedCategoryId) ?? 0;
    this.categoryActive = this.selectedCategoryId!;
  }

  ngAfterViewInit() {
    const swiperElement = this.elem.nativeElement.querySelector('.swiper-wrapper');
    const swiperLastElement = this.elem.nativeElement.querySelector('.last');
    this.lastElementWidth = swiperLastElement?.offsetWidth;
    this.snapGridLastIndex = this.swiper.swiperRef?.snapGrid[this.lastIndex];
    if (swiperElement && swiperElement.scrollWidth <= swiperElement.offsetWidth) {
      this.limitCategory = true;
      this.centeredSlides = false;
    }
  }

  ngOnChanges(changes: any) {
    if (changes['categories']) {
      if (this.initialCategory) {
        this.rerederCategoryType = true;
      } else {
        this.initialCategory = true;
      }
    }
  }

  onSetTranslate(params: EventsParams['setTranslate']) {
    const [swiper] = params;
    const index = swiper.clickedIndex || 0;
    const snapGrid = swiper.snapGrid;
    const snapGridIndex = 0 - snapGrid[snapGrid.length - 1];
    const translateIndex = swiper.translate;
    if (!this.isPassSetTranslate && this.selectedCategoryId == '0' && index == 0 && !this.limitCategory) {
      this.isPassSetTranslate = true;
      this.swiper.swiperRef.setTranslate(0);
    } else if (!this.isPassSetTranslate && index == 0 && this.indexSlide == this.lastIndex && snapGridIndex !== translateIndex) {
      this.isPassSetTranslate = true;
      this.swiper.swiperRef.setTranslate(snapGridIndex);
    }

    // when re-render categories set transition on first and last category
    if (this.rerederCategoryType) {
      this.rerederCategoryType = false;
      if (this.selectedCategoryId == '0' && index == 0 && !this.limitCategory) {
        this.swiper.swiperRef.setTranslate(0);
      } else if (index == this.lastIndex && !this.limitCategory) {
        this.swiper.swiperRef.setTranslate(snapGridIndex);
      }
    }
  }

  tap(params: EventsParams['tap']) {
    const [swiper, e] = params;
    const index = swiper.clickedIndex || 0;
    this.currentIndex = index;
  }

  setTransitionSlide() {
    this.swiper.swiperRef.updateSlides();
    const snapGrid = this.swiper.swiperRef.snapGrid;
    this.swiper.swiperRef.translateTo(-snapGrid[this.currentIndex], 300);
  }

  onClickCategory(categoryId: string): void {
    this.couponService.isLoadingAnimation$.next(true);
    this.categoryActive = categoryId;
    this.onActionSelectedCategory.emit(categoryId)
    this.changeDetectorRef.detectChanges();
    this.setTransitionSlide();
  }
}
