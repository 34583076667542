import { ResponseCode, modalWording } from "../configs/app-config";
import { CouponActionType, RegisterType, sourceOfFundsType } from "../model/coupon-enum.model";
import { IModalTemplate } from "../model/coupon-modal.model";
import { IFetchNavigationList } from "../model/coupon-model.model";
import { AnalyticCategory, AnalyticLabel } from "../webview-to-native-interface/webview-to-native-interface-enum.model";
import { FirebaseParam } from "../webview-to-native-interface/webview-to-native-interface.model";

export function checkRegisterStatus(
    status: string,
    unRegisteredCallbackUrl: string,
    pendingRegisteredCallbackUrl: string,
    firebaseParams: { [key: string]: FirebaseParam[] }
) {

    const modalObj: IModalTemplate = {
        title: "",
        detail: "",
        analyticModal: {
            eventCategory: AnalyticCategory.COUPON_PTP_REGISTER,
            firebaseParam: undefined,
        },
        button: [],
        isFullWidthBtn: false,
        deeplinkCallbackUrl: "",
        templateName: ""
    }

    switch (status) {
        case ResponseCode.ERROR_NOT_REGISTERED: {
            modalObj.title = modalWording.registerPTOrProcessing;
            modalObj.detail = modalWording.registerPTOrProcessingDetail;
            modalObj.analyticModal.firebaseParam = firebaseParams[ResponseCode.ERROR_NOT_REGISTERED];
            modalObj.button = [
                { name: modalWording.doItLater, action: "", eventLabel: AnalyticLabel.LATER },
                { name: modalWording.registerOrCheckResults, action: "unregistered", eventLabel: AnalyticLabel.REGISTER }
            ];
            modalObj.isFullWidthBtn = true;
            modalObj.deeplinkCallbackUrl = unRegisteredCallbackUrl;
            modalObj.templateName = 'registered';
            break;
        }

        case ResponseCode.ERROR_REGISTERING: {
            modalObj.title = modalWording.useCouponAfterRegisterPT;
            modalObj.detail = modalWording.processingResultCheckAgain;
            modalObj.analyticModal.firebaseParam = firebaseParams[ResponseCode.ERROR_REGISTERING];
            modalObj.button = [
                { name: modalWording.close, action: "", eventLabel: AnalyticLabel.CLOSE },
                { name: modalWording.checkResultHomePage, action: "registering", eventLabel: AnalyticLabel.REGISTER }
            ];
            modalObj.isFullWidthBtn = true;
            modalObj.deeplinkCallbackUrl = pendingRegisteredCallbackUrl;
            break;
        }
    }

    return modalObj;
}

export function checkRegisterStatusAndType(
    data: IFetchNavigationList[],
    unRegisteredCallbackUrl: string, // Support PT Old version !! Remove next phase
    firebaseParams: FirebaseParam[]
) {

    const modalObj: IModalTemplate = {
        title: "",
        detail: "",
        analyticModal: {
            eventCategory: AnalyticCategory.COUPON_PTP_REGISTER,
            firebaseParam: undefined,
        },
        button: [],
        isFullWidthBtn: false,
        deeplinkCallbackUrl: "",
        templateName: ""
    }

    if (data.length > 1) {
        const callbackDestinationPT = data.find((v) => v.sof === sourceOfFundsType.PTP)?.destination || '';
        const callbackDestinationCASA = data.find((v) => v.sof === sourceOfFundsType.AC)?.destination || '';
        modalObj.title = modalWording.registerPTOrCASATitle;
        modalObj.detail = modalWording.registerPTOrCASADescription;
        modalObj.analyticModal.firebaseParam = firebaseParams;
        modalObj.button = [
            { name: modalWording.doItLater, action: "", eventLabel: AnalyticLabel.LATER },
            { name: modalWording.registerPTOrCheckResults, action: callbackDestinationPT, eventLabel: AnalyticLabel.REGISTER },
            { name: modalWording.registerAccount, action: callbackDestinationCASA, eventLabel: AnalyticLabel.REGISTER }
        ];
        modalObj.isFullWidthBtn = true;
        modalObj.deeplinkCallbackUrl = unRegisteredCallbackUrl;
        modalObj.templateName = 'registerMultipleSOF'
    } else {
        const accountType = data[0]?.sof;
        const callbackDestination = data[0]?.destination;
        switch (accountType) {
            case sourceOfFundsType.PTP: {
                modalObj.title = modalWording.registerPTTitle;
                modalObj.detail = modalWording.registerPTDescription;
                modalObj.analyticModal.firebaseParam = firebaseParams;
                modalObj.button = [
                    { name: modalWording.doItLater, action: "", eventLabel: AnalyticLabel.LATER },
                    { name: modalWording.registerPTOrCheckResults, action: callbackDestination, eventLabel: AnalyticLabel.REGISTER }
                ];
                modalObj.isFullWidthBtn = true;
                modalObj.deeplinkCallbackUrl = unRegisteredCallbackUrl;
                modalObj.templateName = 'registerSOFPTP'
                break
            }
            case sourceOfFundsType.AC: {
                modalObj.title = modalWording.registerCASATitle;
                modalObj.detail = modalWording.registerCASADescription;
                modalObj.analyticModal.firebaseParam = firebaseParams;
                modalObj.button = [
                    { name: modalWording.doItLater, action: "", eventLabel: AnalyticLabel.LATER },
                    { name: modalWording.registerAccount, action: callbackDestination, eventLabel: AnalyticLabel.REGISTER }
                ];
                modalObj.isFullWidthBtn = true;
                break
            }
        }

    }
    return modalObj;
}

export function handleErrorCouponStatus(code: string, firebaseParams: { [key: string]: FirebaseParam[] }) {

    const modalObj: IModalTemplate = {
        title: "",
        detail: "",
        analyticModal: {
            eventCategory: '',
            firebaseParam: undefined,
        },
        button: []
    };

    switch (code) {
        case ResponseCode.ERROR_OUT_OF_QUOTA: {
            modalObj.title = modalWording.outOfQuota;
            modalObj.detail = modalWording.chooseAnotherCoupon;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_OUT_OF_QUOTA,
                firebaseParam: firebaseParams[ResponseCode.ERROR_OUT_OF_QUOTA],
            };
            modalObj.button = [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }];
            break;
        };

        case ResponseCode.ERROR_COUPON_EXPIRED: {
            modalObj.title = modalWording.thisCouponExpired;
            modalObj.detail = modalWording.chooseAnotherCoupon;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_EXPIRED,
                firebaseParam: firebaseParams[ResponseCode.ERROR_COUPON_EXPIRED],
            };
            modalObj.button = [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }];
            break;
        };

        case ResponseCode.ERROR_COUPON_NOTFOUND: {
            modalObj.title = modalWording.notfoundCoupon;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_NOTFOUND,
                firebaseParam: firebaseParams[ResponseCode.ERROR_COUPON_NOTFOUND],
            };
            modalObj.button = [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }];
            break;
        }

        case ResponseCode.ERROR_NOT_ELIGIBLE: {
            modalObj.title = modalWording.conditionsUsingCouponNotMet;
            modalObj.detail = modalWording.checkConditionAtCouponDetail;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_NOTELIGIBLE,
                firebaseParam: firebaseParams[ResponseCode.ERROR_NOT_ELIGIBLE],
            };
            modalObj.button = [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }];
            break;
        }
    }

    return modalObj;
}

export function handleDisplayPermissionUse(code: string, couponActionType: CouponActionType, firebaseParams: { [key: string]: FirebaseParam[] }) {

    const modalObj: IModalTemplate = {
        title: "",
        detail: "",
        analyticModal: {
            eventCategory: '',
            firebaseParam: undefined,
        },
        button: []
    };

    switch (code) {
        case ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND: {
            modalObj.title = modalWording.conditionsUsingCouponNotMet;
            modalObj.detail = couponActionType == CouponActionType.COLLECT ? "" : modalWording.checkConditionAtCouponDetail;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_NOTELIGIBLE,
                firebaseParam: firebaseParams[ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND],
            };
            modalObj.button = [{ name: modalWording.ok, action: "confirm", eventLabel: AnalyticLabel.OK }];
            break;
        };

        default: {
            modalObj.title = modalWording.outOfService;
            modalObj.detail = modalWording.pleaseTryAgainLater;
            modalObj.analyticModal = {
                eventCategory: AnalyticCategory.COUPON_API_ERROR,
                firebaseParam: firebaseParams[ResponseCode.ERROR_API],
            };
            modalObj.button = [{ name: modalWording.ok, action: "confirm", eventLabel: AnalyticLabel.OK }];
            break;
        }
    }

    return modalObj;
}

export function mappingUserSOFWithCoupon(userSOF: string[], couponSOF: string[]) {
    return userSOF.filter(sof => couponSOF.includes(sof));
}