import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/service/util.service';
import { CouponService } from 'src/shared/coupon.service';
import { filter, Subject, take, takeUntil, zip, first } from 'rxjs';
import { ICouponCategory, ICouponModel, IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { PageRoute } from 'src/app/configs/route-config';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { CouponCardDialogTemplate, CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { modalWording, ResponseCode } from 'src/app/configs/app-config';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { ModalService } from 'src/app/service/modal.service';
import { ModalTemplateComponent } from 'src/app/components/modal-template/modal-template.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CountdownEvent } from 'ngx-countdown';
import { getCouponRepetitiveTime, getExpiredTime, getPeriodTime } from 'src/app/helpers/period-time.helper';
import { BottomContentSliderDialogComponent } from 'src/app/components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { calculateTimeDiff } from 'src/app/helpers/date-time.helper';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponActionType, CouponChannel, CouponStatus, InternalNavigationType, PeriodStatus, UseActionTypeModalTemplate } from 'src/app/model/coupon-enum.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { checkRegisterStatus, handleDisplayPermissionUse, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { CouponCardHorizontalComponent } from 'src/app/components/coupon-card-horizontal/coupon-card-horizontal.component';
import { FormSearchCouponComponent } from 'src/app/components/form-search-coupon/form-search-coupon.component';

declare let window: any;
declare let gtag: Function;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  couponTemplate = CouponCardTemplate.COUPON_HOME
  @ViewChild("wrapScroll") wrapScroll: any;
  @ViewChild("couponCard") couponCards: CouponCardHorizontalComponent;
  @ViewChild("searchCoupon") searchCoupon: FormSearchCouponComponent;
  @ViewChild('toastPopup') toastPopup: any;
  @ViewChild('coachMark', { static: false }) coachMark: ElementRef;
  @ViewChild('coachMarkApplyCoupon', { static: false }) coachMarkApplyCoupon: ElementRef;
  @ViewChild('op', { static: false }) op: OverlayPanel
  @ViewChild('overlayFirst', { static: false }) overlayFirst: OverlayPanel
  @ViewChild('overlayApplyCode', { static: false }) overlayApplyCode: OverlayPanel
  isAndroid: boolean = this.utilService?.isMobileDevice();
  couponData: ICouponModel[] = [];
  filterCouponData: ICouponModel[] = [];
  couponCategories: ICouponCategory[] = [];
  numberOfCollectedCoupon: number = 0;
  categoryId: string | undefined;
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl;
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymemtCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  entryPoint: string = this.rootStoreService.jwtSession!.entryPoint;
  withHeader: boolean = this.rootStoreService.jwtSession!.withHeader ?? true;
  isShowEmptyCoupon: boolean = false;
  isLoading$ = this.couponService.isLoading$;
  customerTutorial$ = this.couponService.customerTutorial$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  selectedCategoryId$ = this.couponService.selectedCategoryId$;
  isLoadingCollectCoupon$ = this.couponService.isLoadingCollectCoupon$;
  isloadingAnimation$ = this.couponService.isLoadingAnimation$;
  searchDefaultKeyword$ = this.couponService.searchDefaultKeyword$;
  CouponChannel = CouponChannel;
  coachSecond = false;
  coachThird = false;
  countTimeCoupon = 0;
  isOpenModal: boolean = false;
  couponIdOverlay = '';
  stepCoachMark = 0;
  CouponStatus = CouponStatus;
  InternalNavigationType = InternalNavigationType;
  CouponActionType = CouponActionType;
  userSOF: string[] = [];

  private _today: Date;
  private _countRepetitiveTime = 0;
  private _countTimePeriod = 0;
  private _countTimeAllowed = 0;
  private _countTimeCouponsRedeem = 0;
  private _countTimeOutOfQuota = 0;
  private _countTimeOutRedeemConfirmOrOfUsedQuota = 0;
  private _destroy = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.stepCoachMark === 1) {
      setTimeout(() => {
        this.overlayFirst.hide();
        this.overlayFirst.toggle(null, this.couponCards.coachMarkButton.nativeElement);
      }, 100);
    }
    if (this.stepCoachMark === 2) {
      setTimeout(() => {
        this.overlayApplyCode.hide();
        this.overlayApplyCode.toggle(null, this.coachMarkApplyCoupon.nativeElement);
      }, 100);
    }
    if (this.stepCoachMark === 3) {
      setTimeout(() => {
        this.op.hide();
        this.op.toggle(null, this.coachMark.nativeElement);
      }, 100);
    }
  }

  constructor(
    private router: Router,
    private couponService: CouponService,
    private rootStoreService: RootStoreService,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponGAService: CouponGAService,
    private modalService: ModalService,
    private titleService: Title,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private sanitizer: DomSanitizer,

  ) {
    this.utilService.startLoading(PageRoute.HOME);
    this.titleService.setTitle("CouponLanding");
  }


  ngOnInit(): void {

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.paymemtCallbackUrl = jwtSession.paymentCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.entryPoint = jwtSession.entryPoint;
      this.withHeader = jwtSession.withHeader ?? true;
      this.userSOF = jwtSession.userSOF ?? [];
    })

    this.selectedCategoryId$.pipe(takeUntil(this._destroy)).subscribe(categoryId => this.categoryId = categoryId);

    this.couponService.saveStepRouteConfig([PageRoute.PATH_HOME]);
    this.couponService.savePreviousUrl(PageRoute.PATH_HOME);
    this.couponService.saveCurrentPage(PageRoute.PATH_HOME);

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      this.couponService.loadFetchCoupons(true);
      this.changeDetectorRef.detectChanges();
    });

    this.couponService.couponCategory$.pipe(takeUntil(this._destroy)).subscribe((category) => {
      this.couponCategories = category;
    });

    this.couponService.coupons$
      .pipe(filter((v) => Boolean(v.data.collections.length > 0)), takeUntil(this._destroy))
      .subscribe((response) => {
        this.couponService.isLoadingCollectCoupon$.next(false);
        this.couponCards?.loadAnimationCollectButton();
        this.couponData = cloneDeep(response.data.collections[0].coupons);
        if (this.categoryId === '0') this.filterCouponData = cloneDeep(response.data.collections[0].coupons)
        else this.filterCouponData = this.filterCoupon();
        this.numberOfCollectedCoupon = response.data.collections[0].numberOfCollectedCoupon;
        this.isShowEmptyCoupon = true;
        this._today = new Date();
        this._countTimeCouponsRedeem = this.getCouponRedeemPeriodTimeCountNearCurrentTime();
        this._countRepetitiveTime = this.getCouponRepetitiveTime();
        this._countTimePeriod = this.getCouponPeriodTimeCountNearCurrentTime();
        this._countTimeAllowed = this.getCouponAllowedTimeCountNearCurrentTime();
        this._countTimeOutOfQuota = this.getCouponOutOfQuotaNearCurrentTime();
        this._countTimeOutRedeemConfirmOrOfUsedQuota = this.getCouponRedeemConfirmOrOutOfUsedQuotaNearCurrentTime();

        // Active Coupon
        if (this._countTimePeriod > 0 && this._countTimeAllowed > 0)
          this.countTimeCoupon = this._countTimeAllowed > this._countTimePeriod ? this._countTimePeriod : this._countTimeAllowed;
        else
          this.countTimeCoupon = this._countTimeAllowed === 0 ? this._countTimePeriod : this._countTimeAllowed;

        // Repetitive Time Coupon
        if (this._countRepetitiveTime > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countRepetitiveTime ? this._countRepetitiveTime : this.countTimeCoupon;
        }

        // Redeem Coupon
        if (this._countTimeCouponsRedeem > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeCouponsRedeem ? this._countTimeCouponsRedeem : this.countTimeCoupon;
        }

        // Out of quota
        if (this._countTimeOutOfQuota > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeOutOfQuota ? this._countTimeOutOfQuota : this.countTimeCoupon;
        }

        // Out of used quota
        if (this._countTimeOutRedeemConfirmOrOfUsedQuota > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countTimeOutRedeemConfirmOrOfUsedQuota ? this._countTimeOutRedeemConfirmOrOfUsedQuota : this.countTimeCoupon;
        }

        if (!isEmpty(this.couponIdOverlay)) {

          const filterCouponOverlay = this.couponData.find(coupon => coupon.couponId === this.couponIdOverlay);
          if (filterCouponOverlay) this.modalService.couponData$.next(filterCouponOverlay);
          else this.modalService.couponData$.next(null);
        }

        this.changeDetectorRef.detectChanges();
      });

    this.couponService.isLoadingCollectedSccuess$
      .pipe(filter((v) => Boolean(v)), takeUntil(this._destroy))
      .subscribe(() => {
        this.toastPopup.nativeElement.classList.remove("is-hidden");
        this.toastPopup.nativeElement.classList.add("fade-out-text");
        setTimeout(() => {
          this.toastPopup.nativeElement.classList.add("is-hidden");
          this.toastPopup.nativeElement.classList.remove("fade-out-text");
        }, 3000);
      });

  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.isLoadingCollectedSccuess$.next(false);
  }

  ngAfterViewInit() {
    zip(this.isLoading$, this.customerTutorial$)
      .pipe(filter(([isLoading, result]) => !Boolean(isLoading) && result.data?.hasDoneTutorial !== undefined), takeUntil(this._destroy))
      .subscribe(([isLoading, result]) => {
        this.changeDetectorRef.detectChanges();
        if (!result.data.hasDoneTutorial) {
          this.displayCoachMark();
        }
      });
  }

  displayCoachMark(action?: string) {
    switch (action) {
      case 'first': {
        this.stepCoachMark = 1;
        this.op.hide();
        this.overlayApplyCode.hide();
        this.overlayFirst.toggle(null, this.couponCards.coachMarkButton.nativeElement);
        this.coachSecond = false;
        this.coachThird = false;
        break;
      }
      case 'second': {
        this.stepCoachMark = 2;
        this.overlayFirst.hide();
        this.op.hide();
        this.overlayApplyCode.toggle(null, this.coachMarkApplyCoupon.nativeElement);
        this.coachSecond = true;
        this.coachThird = false;
        break;
      }
      case 'third': {
        this.stepCoachMark = 3;
        this.overlayFirst.hide();
        this.overlayApplyCode.hide();
        this.op.toggle(null, this.coachMark.nativeElement);
        this.coachSecond = false;
        this.coachThird = true;
        break;
      }
      case 'start': {
        this.stepCoachMark = 0;
        this.completeCoachMark();
        this.op.hide();
        this.overlayFirst.hide();
        this.overlayApplyCode.hide();
        this.coachSecond = false;
        this.coachThird = false;
        break;
      }
      default: {
        //First
        this.stepCoachMark = 1;
        this.overlayFirst.toggle(null, this.couponCards.coachMarkButton.nativeElement);
        break;
      }
    }
  }

  private getPeriodTimeCouponStartDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;
    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponStartDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponStartRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponStartRedemptionDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponEndDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfo[0].couponEndRedemptionDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getExpiredTimeCouponEndDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getExpiredTime(this._today, coupon.couponInfo[0].couponEndDatetime);

      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  private getExpiredTimeCouponEndRedemptionDateTime(periodCoupons: ICouponModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponModel) => {
      const timeResult = getExpiredTime(this._today, coupon.couponInfo[0].couponEndRedemptionDateTime);

      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  getCouponPeriodTimeCountNearCurrentTime() {

    const newCouponsPeriod = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD && coupon.couponInfo[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponStartDateTime(newCouponsPeriod);

    // Support case multiple status :: Collected and Redeem
    const collectedCouponsPeriod = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.PERIOD && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponStartRedemptionDateTime(collectedCouponsPeriod);

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponAllowedTimeCountNearCurrentTime() {
    const newCouponsAllowed = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED && coupon.couponInfo[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponEndDateTime(newCouponsAllowed);

    // Support case multiple :: Collected and Redeem
    const collectedCouponsAllowed = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.ALLOWED && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponEndRedemptionDateTime(collectedCouponsAllowed);

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponOutOfQuotaNearCurrentTime() {
    const couponsOutOfQuota = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA || coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA || (coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.couponInfo[0].receivedOrder === 0));
    const averageCouponOutOfQuota = this.getExpiredTimeCouponEndDateTime(couponsOutOfQuota);
    return averageCouponOutOfQuota;
  }

  getCouponRedeemConfirmOrOutOfUsedQuotaNearCurrentTime() {
    const couponsRedeemConfirmOrOutOfUsedQuota = this.couponData.filter((coupon: ICouponModel) => (coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.couponInfo[0].receivedOrder > 0) || coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED);
    const averageCouponRedeemConfirmOrOutOfUsedQuota = this.getExpiredTimeCouponEndRedemptionDateTime(couponsRedeemConfirmOrOutOfUsedQuota);
    return averageCouponRedeemConfirmOrOutOfUsedQuota;
  }

  getCouponRedeemPeriodTimeCountNearCurrentTime() {
    const couponsRedeem = this.couponData.filter((coupon: ICouponModel) => coupon.couponInfo.every((couponInfo) => couponInfo.status === CouponStatus.REDEEMED) && coupon.hasCountdownTimer === false);
    const averageCouponRedeem = this.getPeriodTimeCouponEndRedemptionDateTime(couponsRedeem);
    return averageCouponRedeem;
  }

  getCouponRepetitiveTime() {
    const couponRepetitive = this.couponData.filter((coupon: ICouponModel) => coupon.repetitiveStatus && coupon.isRepetitiveResetQuota);
    const averageNew = getCouponRepetitiveTime(couponRepetitive);
    return averageNew
  }

  goLink() {
    this.couponService.clearSelectedCouponValue();
    this.couponService.saveSelectedCategoryIdValue('0');
    this.router.navigateByUrl(`${PageRoute.PATH_MY_COUPON}`, { replaceUrl: true });

    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_MyCoupon`);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_MyCoupon`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      }
    );
  }

  navigateToSearchCoupon() {
    this.couponService.clearSelectedCouponValue();
    this.couponService.saveSelectedCategoryIdValue('0');
    this.router.navigateByUrl(`${PageRoute.PATH_SEARCH_COUPON}`, { replaceUrl: true });

    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_SearchCoupon`);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_SearchCoupon`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      }
    );
  }

  navigateToApplyCoupon() {
    this.couponService.clearSelectedCouponValue();
    this.couponService.saveSelectedCategoryIdValue('0');
    this.router.navigateByUrl(`${PageRoute.PATH_APPLY_COUPON}`, { replaceUrl: true });
  }

  navigateToCouponDetail(coupon: ICouponModel, isSetGA: boolean = false, index: number = 0) {
    if (isSetGA) {
      this.setGATag(`${AnalyticCategory.COUPON_LANDING}_CouponDetails`, coupon.campaignCode);

      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: AnalyticCategory.COUPON_LANDING,
          label: `${AnalyticCategory.COUPON_LANDING}_CouponDetails`,
          firebase_screen: AnalyticCategory.COUPON_LANDING,
        },
        this.couponChannel === CouponChannel.NEXT ?
          [
            {
              index: 4,
              key: AnalyticFirebaseParam.INFORMATION,
              value: coupon.couponShortTitle
            }
          ] :
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,
            }
          ]
      );
    }

    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      order: coupon.couponInfo[index].receivedOrder,
      couponStatus: coupon.couponInfo[index].status
    }
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_HOME);
    this.router.navigate([PageRoute.PATH_COUPON_DETAIL], { queryParams: { couponId: coupon.couponId, order: coupon.couponInfo[index].receivedOrder }, replaceUrl: true });
  }

  setStepRouteFlag(couponId: string) {
    localStorage.setItem("navigateExternalPage", couponId);
    this.utilService.removeTokenSession();
  }

  setToLoadFech() {
    this.utilService.startLoading(PageRoute.HOME);
    this.changeDetectorRef.detectChanges();
    this.couponService.loadFetchCoupons();
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  pickCoupon(element: ElementRef<any>, couponId: string, couponOrder: number, campaignCode: string, couponShortTitle: string) {
    this.couponCards.collectedCoupon.push(couponId);
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_Collect`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_Collect`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );

    this.couponService.loadPermissionUseCoupon(couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response?.permissionUseCoupon) {
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.displayPermissionError(campaignCode, response, CouponActionType.COLLECT);
          return;
        }

        this.couponService.loadFetchCollectCoupon(couponId, couponOrder);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.collectCoupon$
      .pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        this.couponService.clearCollectCoupon();
        this.couponCards.collectedCoupon = this.couponCards.collectedCoupon.filter((v) => v !== couponId);

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {

          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ]
          };
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.couponCards?.loadAnimationCollectButton();
          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponCards.disableActionCard(element, false);
              this.isOpenModal = false;
              this.setStepRouteFlag(couponId);
            });
          }
          return;
        }

        if (response.result.code == ResponseCode.ERROR_OUT_OF_QUOTA || response.result.code == ResponseCode.ERROR_COUPON_EXPIRED || response.result.code == ResponseCode.ERROR_COUPON_NOTFOUND || response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) { // quota = 0 || expired || Notfound

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_OUT_OF_QUOTA]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_COUPON_EXPIRED]: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: couponShortTitle
                }
              ] : [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: campaignCode,
                }
              ],
            [ResponseCode.ERROR_COUPON_NOTFOUND]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
          };
          this.couponService.isLoadingCollectCoupon$.next(false);
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.isOpenModal = false;
              this.couponService.loadFetchCoupons();
            });
          }
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) { // success
          this.countTimeCoupon = 0;
          this.changeDetectorRef.detectChanges();
          this.couponService.loadFetchCoupons(false, true);
        }
      })
  }

  onActionUseCouponPaymentTopup(eventLabel: string, campaignCode: string, couponShortTitle: string, destination: string) {
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_${eventLabel}`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_${eventLabel}_${destination}`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  navigateExternalURL(coupon: any, externalUrl: string = "", index: number = 0) {

    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_Use`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_Use_ExternalURL`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,

          }
        ]
    );

    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.couponInfo[index].receivedOrder, true);

    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response?.permissionUseCoupon) {

          this.displayPermissionError(coupon.campaignCode, response, CouponActionType.USE, true);
          return;
        }

        const modalObj: IModalTemplate = {
          title: modalWording.confirmNavigateExternalUrl,
          detail: modalWording.exitFromAppGoToParner,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!
              }
            ]
          },
          button: [
            { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
            { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
          ],
        };

        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            window.location.href = this.utilService.safeURL(externalUrl);
          }
        });

        this.couponService.clearPermissionUseCoupon();
      });
  }

  useCoupon(element: ElementRef<any>, coupon: ICouponModel, index: number = 0) {
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_Use`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_Use`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: coupon.couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,
          }
        ]
    );

    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);
    const dateRedeemdiff = this.calculateTimeDiff(coupon.couponInfo[0].couponEndRedemptionDateTime);

    if (dateRedeemdiff < 0 && coupon.couponInfo[index].status !== CouponStatus.REDEEMED) {
      // Error case
      const modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: coupon.couponShortTitle
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!,
              }
            ]
        },
        button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: "SeeOthers" }],
      };

      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.couponService.loadFetchCoupons();
        return;
      });

    } else {

      // Success case
      this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_HOME);
      const queryParamsCoupon = cloneDeep(selectedCoupon);
      delete queryParamsCoupon['couponStatus'];
      if (coupon.couponInfo[index].status == CouponStatus.REDEEMED) {

        this.setGATag(`${AnalyticCategory.COUPON_LANDING}_Code`, coupon.campaignCode);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.COUPON_LANDING,
            label: `${AnalyticCategory.COUPON_LANDING}_Code`,
            firebase_screen: AnalyticCategory.COUPON_LANDING,
          },
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,
            }
          ]
        );
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
      } else {
        // Check in period time
        this.couponService.loadPermissionUseCoupon(selectedCoupon.couponId, selectedCoupon.order!);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response?.permissionUseCoupon) {
              this.displayPermissionError(coupon.campaignCode, response, CouponActionType.USE);
              return;
            }

            let txtDetail = "";
            if (coupon.hasCountdownTimer && coupon.couponInfo[index].countdownTimeInSeconds! > 0) {
              let countdownTimer = (coupon.couponInfo[index].countdownTimeInSeconds!) / 60;
              txtDetail = modalWording.afterConfirmCouponHaveTime + countdownTimer + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: this.couponChannel === CouponChannel.NEXT ?
                  [
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponInfo[index].countdownTimeInSeconds > 0 ? (coupon.couponInfo[index].countdownTimeInSeconds).toString() : '0'
                    },
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponShortTitle
                    }
                  ] :
                  [
                    {
                      key: AnalyticFirebaseParam.CAMPAIGN_ID,
                      value: coupon.campaignCode!,
                    }
                  ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.couponCards.disableActionCard(element);
              this.couponService.clearPermissionUseCoupon();

              if (result == "confirm") {
                this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
              }
            });
          });
      }
    }
  }

  useCouponFormDialogBottomContent(coupon: ICouponModel, actionType: UseActionTypeModalTemplate, index: number = 0,) {
    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);

    switch (actionType) {
      case UseActionTypeModalTemplate.EXPIRED: {
        const modalObj: IModalTemplate = {
          title: modalWording.thisCouponExpired,
          detail: modalWording.chooseAnotherCoupon,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_EXPIRED,
            firebaseParam: this.couponChannel === CouponChannel.NEXT ?
              [
                {
                  index: 4,
                  key: AnalyticFirebaseParam.INFORMATION,
                  value: coupon.couponShortTitle
                }
              ] :
              [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!,
                }
              ]
          },
          button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }],
        };
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
          this.couponService.loadFetchCoupons();
          return;
        });

        break;
      }
      case UseActionTypeModalTemplate.REDEEMED: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_HOME);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
        break;
      }
      case UseActionTypeModalTemplate.SUCCESS: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_HOME);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
      }
    }
  }

  openOverlay(coupon: ICouponModel, isInChannel: boolean) {
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_MultipleCoupons`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_MultipleCoupons`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode,
        }
      ]
    );

    this.couponIdOverlay = coupon.couponId;
    const filterRedeemStatus = coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.REDEEMED);
    const periodStatus = filterRedeemStatus ? PeriodStatus.ALLOWED : coupon.couponInfo[0].periodStatus;
    const titleDialog = this.modalService.getHeaderDialogButtomContnet(periodStatus, coupon.couponInfo[0].status as CouponStatus, isInChannel);

    const couponData = {
      coupon: cloneDeep(coupon),
      couponChannel: this.couponChannel,
      entryPoint: this.entryPoint,
      titleDialog: titleDialog,
      isMultipleStatus: filterRedeemStatus,
      isInChannel: isInChannel,
      template: CouponCardDialogTemplate.COUPON_HOME_BOTTOM_DIALOG,
      categoryEvent: AnalyticCategory.COUPON_LANDING_MULTIPLE,
      userSOF: this.userSOF
    }
    this.modalService.openDialogButtomContent(BottomContentSliderDialogComponent, { data: couponData, position: { bottom: '0px' } }).pipe(take(1)).subscribe((response) => {
      this.couponIdOverlay = '';
      if (response) {
        switch (response.path) {
          case PageRoute.COUPON_DETAIL: {
            this.navigateToCouponDetail(response.coupon);
            break;
          }
          case PageRoute.QR_CODE: {
            this.useCouponFormDialogBottomContent(response.coupon, response.actionType);
            break;
          }
          case PageRoute.SCAN_COPAY: {
            this.setStepRouteFlag(coupon.couponId);
            // Support PT Old version !! Remove next phase
            this.selectedCoupon$.pipe(take(1)).subscribe((selectedCoupon) => {
              localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
              if (this.categoryId !== '0')
                localStorage.setItem("selectedCategory", this.categoryId!);
            });
            break;
          }
          case PageRoute.EXTERNAL_URL: {
            this.navigateExternalURL(response.coupon, response.externalUrl);
            break;
          }
          case PageRoute.HOME: {
            this.setToLoadFech();
            break;
          }
        }
      }
    });
  }

  completeCoachMark() {
    // Action complete coach mark
    this.couponService.loadUpdateCustomerTutorialCoachMark();
  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.countTimeCoupon = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchCoupons();
    }
  }

  private mappingSelectedCoupon(coupon: ICouponModel, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.couponInfo[index].couponCode ?? '',
      hasCountdownTimer: coupon.hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.couponInfo[index].countdownTimeInSeconds ?? 0,
      order: coupon.couponInfo[index].receivedOrder,
      couponStatus: coupon.couponInfo[index].status
    };

    return selectedCoupon;

  }

  onActionSelectedCoupon(ev: ICouponModel) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.setStepRouteFlag(selectedCoupon.couponId);
    // Support PT Old version !! Remove next phase
    localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
    if (this.categoryId !== '0')
      localStorage.setItem("selectedCategory", this.categoryId!);
  }

  displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, couponAction: CouponActionType, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.couponService.clearPermissionUseCoupon();
        this.couponService.loadFetchCoupons();
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchCoupons();
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 5,
                key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                value: permisionCoupon?.status
              },
              {
                index: 6,
                key: AnalyticFirebaseParam.ERROR_CODE,
                value: permisionCoupon.result?.code
              },
              {
                index: 7,
                key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                value: 'False'
              },
              {
                index: 8,
                key: AnalyticFirebaseParam.ERROR_TITLE,
                value: permisionCoupon.result?.message
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchCoupons();
          }
        });
      }
    }
  }

  setGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.COUPON_LANDING,
      eventCategory: AnalyticCategory.COUPON_LANDING,
      eventLabel: label,
      eventAction: AnalyticAction.CLICK,
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? ''
    }

    this.couponGAService.trackActionGATag(gaObj);
  }

  onActionUseCouponCopay(eventLabel: string, campaignCode: string, couponShortTitle: string) {
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_${eventLabel}`, campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_${eventLabel}`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: couponShortTitle
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ]
    );
  }

  setBackGATag() {
    this.setGATag(`${AnalyticCategory.COUPON_LANDING}_Back`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_LANDING,
        label: `${AnalyticCategory.COUPON_LANDING}_Back`,
        firebase_screen: AnalyticCategory.COUPON_LANDING,
      }
    );
  }
  async onActionSelectedCategory(categoryId: string) {
    this.loadAnimationCategory(true);
    this.couponService.clearSelectedCouponValue();
    this.couponService.saveSelectedCategoryIdValue(categoryId);
    this.categoryId = categoryId;

    await this.sleep(1000);
    this.filterCouponData = [];
    this.changeDetectorRef.detectChanges();

    if (this.categoryId === '0') {
      this.seperateCouponAllCategory();
    }
    else this.filterCouponData = this.filterCoupon();
    this.changeDetectorRef.detectChanges();
    this.couponCards.couponCards.first.nativeElement.scrollIntoView();
    this.couponService.isLoadingAnimation$.next(false);
    this.loadAnimationCategory();
  }

  async seperateCouponAllCategory() {
    const couponLength = this.couponData.length;
    if (couponLength <= 100)
      this.filterCouponData = this.couponData;
    else {
      const firstCoupons = this.couponData.slice(0, 100);
      this.filterCouponData = firstCoupons;
      await this.sleep(1000);
      this.filterCouponData.push(...this.couponData.slice(100, couponLength));
      this.changeDetectorRef.detectChanges();
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  filterCoupon(): ICouponModel[] {
    return this.couponData.filter((coupon) => {
      if (coupon.categoryUUIDs) return coupon.categoryUUIDs.includes(this.categoryId!)
      else return false
    });
  }

  loadAnimationCategory(value: boolean = false) {
    const wrapCouponClass = document?.querySelector(".coupon-list-card")
    const loadpageClass = document?.querySelector(".loading-page");
    if (value) {
      wrapCouponClass?.classList?.add('disabled-click-action')
      loadpageClass?.classList?.add('loading-style')
    } else {
      wrapCouponClass?.classList?.remove('disabled-click-action')
      loadpageClass?.classList?.remove('loading-style')
    }
  }

  handleHomePageCountdownEvent(e: CountdownEvent, index: number, order: number) {
    if (e.action === 'done') {
      const expiredCoupon = this.couponData[index];
      const expiredCouponOrder = this.couponData[index].couponInfo[order].receivedOrder
      this.couponData[index].couponInfo[order].status = CouponStatus.REDEEM_CONFIRMED;
      const redeemConfirm = this.couponData[index].couponInfo[order];
      const redeemCoupon = this.couponData[index].couponInfo.filter((couponInfo: any) => couponInfo.status !== CouponStatus.REDEEM_CONFIRMED);
      if (redeemCoupon.length > 0) {
        this.couponData[index].numberOfCoupons = redeemCoupon.length;
        this.couponData[index].couponInfo = redeemCoupon;
      } else {
        this.couponData.splice(index, 1);
      }

      const couponRedeemConfirm = this.couponData.find((coupon: any) => coupon.couponId === expiredCoupon.couponId && coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED);
      if (couponRedeemConfirm) {
        couponRedeemConfirm.couponInfo.push(redeemConfirm);
        couponRedeemConfirm.numberOfCoupons = couponRedeemConfirm.couponInfo.length;
      } else {
        if (new Date(redeemConfirm.couponEndRedemptionDateTime).getTime() < new Date().getTime()) return;
        const couponWithoutRedeemConfirm = this.couponData.filter((coupon: any) => coupon.couponInfo[0].status !== CouponStatus.REDEEM_CONFIRMED) ?? [];
        const couponFilterRedeemConfirm = this.couponData.filter((coupon: any) => coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED) ?? [];
        const usedCoupon: ICouponModel = {
          couponId: expiredCoupon.couponId,
          campaignCode: expiredCoupon.campaignCode,
          couponName: expiredCoupon.couponName,
          couponImage: expiredCoupon.couponImage,
          couponShortTitle: expiredCoupon.couponShortTitle,
          couponSubtitle: expiredCoupon.couponSubtitle,
          badgeLabel: expiredCoupon.badgeLabel,
          couponType: expiredCoupon.couponType,
          hasCountdownTimer: expiredCoupon.hasCountdownTimer,
          isHiddenProgressBar: expiredCoupon.isHiddenProgressBar,
          couponUseMethod: expiredCoupon.couponUseMethod,
          copayChannels: expiredCoupon.copayChannels,
          numberOfCoupons: 1,
          couponRedemptionWithIn: expiredCoupon.couponRedemptionWithIn,
          deductionType: expiredCoupon.deductionType,
          progressionPercent: expiredCoupon.progressionPercent,
          maximumCouponPerUser: expiredCoupon.maximumCouponPerUser,
          repetitiveStatus: expiredCoupon.repetitiveStatus,
          maximumRepetitiveCouponPerUser: expiredCoupon.maximumRepetitiveCouponPerUser,
          numberOfOutOfQuotaCoupon: expiredCoupon.numberOfOutOfQuotaCoupon,
          numberOfTemporaryOutOfQuotaCoupon: expiredCoupon.numberOfTemporaryOutOfQuotaCoupon,
          isRepetitiveResetQuota: expiredCoupon.isRepetitiveResetQuota,
          repetitiveTime: expiredCoupon.repetitiveTime,
          isUseButtonDisplay: expiredCoupon.isUseButtonDisplay,
          sourceOfFunds: expiredCoupon.sourceOfFunds,
          categoryUUIDs: expiredCoupon.categoryUUIDs ?? [],
          tags: expiredCoupon.tags ?? [],
          copayNavigation: expiredCoupon.copayNavigation,
          isCoPayNavigate: expiredCoupon.isCoPayNavigate,
          couponInfo: [redeemConfirm]
        };
        couponFilterRedeemConfirm.unshift(usedCoupon)
        this.couponData = couponWithoutRedeemConfirm.concat(couponFilterRedeemConfirm);

      }

      this.modalService.handleCouponCountdown$.next({
        couponId: expiredCoupon.couponId,
        couponOrder: expiredCouponOrder
      });

      if (this.categoryId === '0') this.filterCouponData = this.couponData;
      else this.filterCouponData = this.filterCoupon();
    }

    this.changeDetectorRef.detectChanges();
  }
}


