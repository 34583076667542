<ng-container *ngIf="!(isLoading$ | async) && couponDetail">
    <div id="couponDetail" class="bg-standard">
        <div [class]="isAndroid ? ' android-device' : '' " class="loading-page">
            <div [class]="'header has-text-centered header-flex' + (withHeader ? '' : ' without-header')">
                <div class="has-text-left back-btn">
                    <ng-container *ngIf="stepRouteConfig!.length > 1 && !cancelMode; else callBackMissionBaseOnNEXT">
                        <a [routerLink]="stepRouteConfig[stepRouteConfig.length - 2]" [replaceUrl]="true"
                            (click)="removeStepRouteConfig(); setGATagBack($event);" data-testid="coupon-detail-back"
                            id="CouponDetails_Back">

                        </a>
                    </ng-container>

                    <ng-template #callBackMissionBaseOnNEXT>
                        <ng-container
                            *ngIf="callbackDestination && couponChannel === CouponChannel.NEXT && !cancelMode; else callBackUrl">
                            <a (click)="setGATagBack($event); navigateBackWithJsbridge();"
                                data-testid="CouponDetails_Back_Callback_MB">
                            </a>
                        </ng-container>
                    </ng-template>

                    <ng-template #callBackUrl>
                        <a [href]="callbackUrl | safe: 'url'"
                            (click)="cancelMode ? setStepRouteFlag(true) : null; setGATagBack($event);"
                            data-testid="link-callback-icon" id="CouponDetails_Back_Callback">
                        </a>
                    </ng-template>
                </div>
                <div class="header-title">
                    <h3>รายละเอียดคูปอง</h3>
                </div>
                <div class="header-temp-last"></div>
            </div>
            <div class="coupon-list-card"></div>
            <div id="coupon-detail-content"
                [class]="'notification pd-content bg-white' + (withHeader ? '' : ' mg-CNX')  + (showTimerBox? ' notification-increase' : '')"
                [class.fix-height-next-cnx]="(isAndroid && (couponChannel === CouponChannel.NEXT || couponChannel === CouponChannel.CNX))">
                <div [class.prevent-scroll]="clickedCollect"
                    [class]="'wrap-scroll' + (isInChannels ? '' : ' decrease-bottom') + 
                        (couponChannel!=CouponChannel.PT && showTimerBox ? ' increase-bottom-timer-notpt' : ' increase-bottom')">
                    <div class="columns is-gapless is-mobile mb-0">
                        <div class="column is-fullwidth ">
                            <div class="wrap-image"
                                *ngIf="couponDetail.status !== CouponStatus.EXPIRED && couponDetail.status !== CouponStatus.OUT_OF_QUOTA && couponDetail.status !== CouponStatus.OUT_OF_USED_QUOTA && couponDetail.status !== CouponStatus.TEMPO_OUT_OF_QUOTA">
                                <div class="card wrap-card bg-image"
                                    style="background: url({{couponDetail.couponImage}}) 0 0 / cover;">
                                </div>
                                <figure class="image"
                                    *ngIf="(couponDetail.status !== CouponStatus.REDEEM_CONFIRMED && couponDetail.isEligibleToUse) || (couponDetail.status == CouponStatus.REDEEMED && !couponDetail.isEligibleToUse )">
                                    <img [src]="couponDetail.couponImage" width="279" height="279">
                                </figure>
                                <div class="stamp-used" data-testid="stamp-used"
                                    *ngIf="couponDetail.status === CouponStatus.REDEEM_CONFIRMED">
                                    <figure class="image">
                                        <img [src]="couponDetail.couponImage">
                                    </figure>
                                </div>
                                <div class="stamp-unconditional" data-testid="stamp-unconditional"
                                    *ngIf="couponDetail.status !== CouponStatus.REDEEM_CONFIRMED && couponDetail.status !== CouponStatus.REDEEMED && !couponDetail.isEligibleToUse">
                                    <figure class="image">
                                        <img [src]="couponDetail.couponImage">
                                    </figure>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="couponDetail.status === CouponStatus.OUT_OF_QUOTA || couponDetail.status === CouponStatus.OUT_OF_USED_QUOTA || couponDetail.status === CouponStatus.EXPIRED || couponDetail.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                <ng-container *ngIf="couponDetail.isEligibleToUse else stampNotEligibleToUse">
                                    <div class="wrap-image"
                                        *ngIf="couponDetail.status === CouponStatus.OUT_OF_QUOTA || couponDetail.status === CouponStatus.OUT_OF_USED_QUOTA">
                                        <div class="card wrap-card bg-image"
                                            style="background: url({{couponDetail.couponImage}}) 0 0 / cover;">
                                        </div>
                                        <div class="stamp-out-of-quota" data-testid="stamp-out-of-quota">
                                            <figure class="image">
                                                <img [src]="couponDetail.couponImage" width="279" height="279">
                                            </figure>
                                        </div>
                                    </div>

                                    <div class="wrap-image" *ngIf="couponDetail.status === CouponStatus.EXPIRED">
                                        <div class="card wrap-card bg-image"
                                            style="background: url({{couponDetail.couponImage}}) 0 0 / cover;">
                                        </div>
                                        <div class="stamp-expired">
                                            <figure class="image">
                                                <img [src]="couponDetail.couponImage">
                                            </figure>
                                        </div>
                                    </div>

                                    <div class="wrap-image"
                                        *ngIf="couponDetail.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                        <div class="card wrap-card bg-image"
                                            style="background: url({{couponDetail.couponImage}}) 0 0 / cover;">
                                        </div>
                                        <div class="stamp-tempo-out-of-quota" data-testid="stamp-tempo-out-of-quota">
                                            <figure class="image">
                                                <img [src]="couponDetail.couponImage">
                                            </figure>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #stampNotEligibleToUse>
                                    <div class="wrap-image">
                                        <div class="card wrap-card bg-image"
                                            style="background: url({{couponDetail.couponImage}}) 0 0 / cover;">
                                        </div>
                                        <div class="stamp-unconditional" data-testid="stamp-unconditional">
                                            <figure class="image">
                                                <img [src]="couponDetail.couponImage">
                                            </figure>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>

                    <div class="px-4">
                        <div class="box-special mt-4" *ngIf="false">
                            สิทธิพิเศษจาก Fun Card
                        </div>

                        <div>
                            <h2 class="title">
                                {{couponDetail.couponTitle}}
                            </h2>
                        </div>

                        <div *ngIf="isInChannels">
                            <ng-container *ngIf="couponDetail.status === CouponStatus.UNCOLLECTED">
                                <ng-container *ngIf="couponDetail.isEligibleToUse else cardBoxNotEligibleToUse">
                                    <ng-container
                                        *ngIf="isOpenDeepLinkCouponDetail() && couponDetail.couponType === CouponType.APPLYCODE else cardBoxOtherCouponType">
                                        <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary box-flex">
                                                    <img src="../../../assets/icon/ic_info.png" width="16"
                                                        class="mr-2" />
                                                    <span>คุณจำเป็นต้องระบุโค้ดที่ <span
                                                            class="text-underline-and-bold">หน้าแรกคูปอง</span><br>เพื่อใช้คูปองนี้</span><br />
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #cardBoxOtherCouponType>
                                        <ng-container
                                            *ngIf="couponDetail.periodStatus !== PeriodStatus.ALLOWED else periodAllow ">
                                            <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary">
                                                        <h2>
                                                            <img src="../../../assets/icon/ic_info.png" width="16"
                                                                class="mr-2" />
                                                            <span class="pr-1"
                                                                data-testid="uncollected-pick-out-period-button">คูปองนี้เริ่มเก็บได้
                                                            </span>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.PERIOD">
                                                                {{
                                                                convertToLocaleTimeString(couponDetail.couponStartDatetime)
                                                                + '-' +
                                                                convertToLocaleTimeString(couponDetail.couponEndDatetime)
                                                                + ' น.'
                                                                }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.DATE">
                                                                {{
                                                                convertToLocaleDateString(couponDetail.couponStartDatetime)
                                                                }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.START_COLLECT">
                                                                {{
                                                                convertToLocaleTimeString(couponDetail.couponStartDatetime)
                                                                + ' น.'
                                                                }}
                                                            </ng-container>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #periodAllow>
                                            <div *ngIf="!couponDetail.isAboveMinimumSpend"
                                                class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary">
                                                        <h2>
                                                            <img src="../../../assets/icon/ic_info.png" width="16"
                                                                class="mr-2" />
                                                            <span
                                                                data-testid="message-uncollected-isaboveminimumspend">ยอดชำระไม่ถึงขั้นต่ำที่กำหนด</span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                    <div class="card custom-box-summary box-gray-coupon">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <ng-container
                                                    *ngIf="couponDetail.couponRedemptionWithIn > 0 else notCouponRedemptionWithIn">
                                                    <h2><span class="">วันหมดอายุ:</span> {{
                                                        couponDetail.couponRedemptionWithIn }} วันหลังจากเก็บคูปอง</h2>
                                                </ng-container>
                                                <ng-template #notCouponRedemptionWithIn>
                                                    <h2><span class="">วันหมดอายุ:</span> {{
                                                        convertToLocaleDateString(couponDetail.couponEndDatetime) + ' '
                                                        +
                                                        convertToLocaleTimeString(couponDetail.couponEndDatetime) + '
                                                        น.' }}
                                                    </h2>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #cardBoxNotEligibleToUse>
                                    <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <h2 class="is-flex">
                                                    <img src="../../../assets/icon/ic_info.png" width="16"
                                                        class="mr-2" />
                                                    <span
                                                        class="coupon-info-ellipsis-text">{{couponDetail.segmentErrorMessage}}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card custom-box-summary box-gray-coupon">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary pt-0">
                                                <h2>
                                                    <span class="pr-1">วันหมดอายุ:</span> {{
                                                    convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                    น.'}}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                            <ng-container
                                *ngIf="couponDetail.status === CouponStatus.NEW || couponDetail.status === CouponStatus.COLLECTED">
                                <ng-container *ngIf="couponDetail.isEligibleToUse else cardBoxNotEligibleToUse">

                                    <ng-container *ngIf="(isOpenDeepLinkCouponDetail() && couponDetail.couponType === CouponType.APPLYCODE && !cancelMode) || 
                                        isCouponApplyCodeQRONCouponList() else cardBoxOtherCouponType">

                                        <ng-container
                                            *ngIf="(isOpenDeepLinkCouponDetail() && couponDetail.couponType === CouponType.APPLYCODE)">

                                            <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary box-flex">
                                                        <img src="../../../assets/icon/ic_info.png" width="16"
                                                            class="mr-2" />
                                                        <span>คุณจำเป็นต้องระบุโค้ดที่ <span
                                                                class="text-underline-and-bold">หน้าแรกคูปอง</span><br>เพื่อใช้คูปองนี้</span><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="isCouponApplyCodeQRONCouponList()">
                                            <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary box-flex">
                                                        <img src="../../../assets/icon/ic_info.png" width="16"
                                                            class="mr-2" />
                                                        <span>
                                                            คูปองนี้เป็น QR/บาร์โค้ด กรุณาไปที่เมนู<br>'คูปองของฉัน'
                                                            เพื่อกดใช้และแสดง QR/<br>บาร์โค้ดให้กับพนักงาน
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card custom-box-summary box-gray-coupon">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary">
                                                        <h2><span class="">วันหมดอายุ:</span> {{
                                                            convertToLocaleDateString(couponDetail.redemptionEndDateTime)
                                                            +
                                                            ' '
                                                            +
                                                            convertToLocaleTimeString(couponDetail.redemptionEndDateTime)
                                                            +
                                                            '
                                                            น.' }}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </ng-container>

                                    <ng-template #cardBoxOtherCouponType>
                                        <ng-container
                                            *ngIf="couponDetail.periodStatus !== PeriodStatus.ALLOWED else periodAllow">
                                            <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                <div class="columns is-mobile my-0">
                                                    <div class="column box-summary">
                                                        <h2>
                                                            <img src="../../../assets/icon/ic_info.png" width="16"
                                                                class="mr-2" />
                                                            <span class="pr-1">คูปองนี้เริ่มใช้ได้ </span>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.PERIOD">
                                                                {{
                                                                convertToLocaleTimeString(couponDetail.redemptionStartDateTime)
                                                                + '-' +
                                                                convertToLocaleTimeString(couponDetail.redemptionEndDateTime)
                                                                + ' น.'
                                                                }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.DATE">
                                                                {{
                                                                convertToLocaleDateString(couponDetail.redemptionStartDateTime)
                                                                }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="couponDetail.periodStatus === PeriodStatus.START_REDEEM">
                                                                {{
                                                                convertToLocaleTimeString(couponDetail.redemptionStartDateTime)
                                                                + ' น.'
                                                                }}
                                                            </ng-container>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #periodAllow>
                                            <ng-container *ngIf="!couponDetail.isAboveMinimumSpend">
                                                <div
                                                    class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                                    <div class="columns is-mobile my-0">
                                                        <div class="column box-summary">
                                                            <h2>
                                                                <img src="../../../assets/icon/ic_info.png" width="16"
                                                                    class="mr-2" />
                                                                <span
                                                                    data-testid="message-new-collected-isaboveminimumspend">ยอดชำระไม่ถึงขั้นต่ำที่กำหนด
                                                                </span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                        <div class="card custom-box-summary"
                                            [class]="couponDetail.periodStatus !== PeriodStatus.ALLOWED || !couponDetail.isAboveMinimumSpend? 'box-gray-coupon' : 'box-coupon-info'">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary">
                                                    <h2><span class="">วันหมดอายุ:</span> {{
                                                        convertToLocaleDateString(couponDetail.redemptionEndDateTime)
                                                        +
                                                        ' '
                                                        +
                                                        convertToLocaleTimeString(couponDetail.redemptionEndDateTime)
                                                        +
                                                        '
                                                        น.' }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #cardBoxNotEligibleToUse>
                                    <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <h2 class="is-flex">
                                                    <img src="../../../assets/icon/ic_info.png" width="16"
                                                        class="mr-2" />
                                                    <span
                                                        class="coupon-info-ellipsis-text">{{couponDetail.segmentErrorMessage}}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card custom-box-summary box-gray-coupon">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary pt-0">
                                                <h2>
                                                    <span class="pr-1">วันหมดอายุ:</span> {{
                                                    convertToLocaleDateString(couponDetail.redemptionEndDateTime) +
                                                    ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.redemptionEndDateTime) +
                                                    '
                                                    น.' }}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                            <ng-container *ngIf="couponDetail.status === CouponStatus.REDEEMED">
                                <ng-container *ngIf="!isCouponApplyCodeQRONCouponList() else messageQRONCouponList">
                                    <ng-container *ngIf="showTimerBox else notShowTimerBox">
                                        <div class="card custom-box-summary box-coupon-info">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary">
                                                    <h2 class="padding-minute" data-testid="count-down-time">
                                                        <span class="pr-1">เวลาที่เหลือ: </span>
                                                        <countdown class="time" [config]="{
                                                            leftTime: couponDetail.countdownTimeInSeconds,
                                                            format: 'mm:ss'
                                                        }" (event)="handleEvent($event)" data-testid="count-down-tag">
                                                        </countdown>
                                                        <div class="text">นาที</div>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #notShowTimerBox>
                                        <div class="card custom-box-summary box-coupon-info">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary">
                                                    <h2><span class="">วันหมดอายุ:</span> {{
                                                        convertToLocaleDateString(couponDetail.redemptionEndDateTime) +
                                                        ' '
                                                        +
                                                        convertToLocaleTimeString(couponDetail.redemptionEndDateTime) +
                                                        '
                                                        น.' }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #messageQRONCouponList>
                                    <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary box-flex">
                                                <img src="../../../assets/icon/ic_info.png" width="16" class="mr-2" />
                                                <span>
                                                    คูปองนี้เป็น QR/บาร์โค้ด กรุณาไปที่เมนู<br>'คูปองของฉัน'
                                                    เพื่อกดใช้และแสดง QR/<br>บาร์โค้ดให้กับพนักงาน
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                            <div *ngIf="periodTimeCount > 0" style="opacity:0; height: 0.01rem"
                                data-testid="count-down-period">
                                periodTimeCount : <countdown [config]="{
                                    leftTime: periodTimeCount,
                                    format: 'mm:ss'
                                }" (event)="handlePeriodEvent($event)">
                                </countdown>
                            </div>
                        </div>

                        <div
                            *ngIf="!isInChannels && (couponDetail.status === CouponStatus.UNCOLLECTED || couponDetail.status === CouponStatus.NEW || couponDetail.status === CouponStatus.COLLECTED || couponDetail.status === CouponStatus.REDEEMED)">
                            <ng-container *ngIf="couponDetail.isEligibleToUse else cardBoxNotEligibleToUse">
                                <ng-container *ngIf="isOpenDeepLinkCouponDetail() && couponDetail.couponType === CouponType.APPLYCODE && 
                                    (couponDetail.status === CouponStatus.UNCOLLECTED ||  couponDetail.status === CouponStatus.NEW || 
                                    couponDetail.status === CouponStatus.COLLECTED) else cardBoxOtherCouponType">
                                    <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary box-flex">
                                                <img src="../../../assets/icon/ic_info.png" width="16" class="mr-2" />
                                                <span>คุณจำเป็นต้องระบุโค้ดที่ <span
                                                        class="text-underline-and-bold">หน้าแรกคูปอง</span><br>เพื่อใช้คูปองนี้</span><br />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #cardBoxOtherCouponType>
                                    <ng-container *ngIf="
                                        couponDetail.copayChannels.includes(CouponChannel.PT) && (couponChannel !== CouponChannel.PT || entryPoint === CouponEntryPoint.PWP )
                                        else notusedCouponChannel">
                                        <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary">
                                                    <h2>
                                                        <img src="../../../assets/icon/ic_info.png" width="16"
                                                            class="mr-2" />
                                                        <span>ใช้กับการชำระเงินผ่านแอปฯ เป๋าตังเท่านั้น</span>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #notusedCouponChannel>
                                        <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                            <div class="columns is-mobile my-0">
                                                <div class="column box-summary">
                                                    <img src="../../../assets/icon/ic_info.png" width="16"
                                                        class="mr-2" />
                                                    <span>ใช้กับการชำระเงินบนเว็บไซต์หรือแอปฯ</span><br />
                                                    <span
                                                        style="padding-left: 1.5em;">พาร์ทเนอร์ด้วยเป๋าตังเท่านั้น</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                                <div class="card custom-box-summary box-gray-coupon">
                                    <div class="columns is-mobile my-0">
                                        <div class="column box-summary pt-0">
                                            <h2>
                                                <span class="pr-1">วันหมดอายุ:</span> {{
                                                convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                +
                                                convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                น.' }}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #cardBoxNotEligibleToUse>
                                <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                    <div class="columns is-mobile my-0">
                                        <div class="column box-summary">
                                            <h2 class="is-flex">
                                                <img src="../../../assets/icon/ic_info.png" width="16" class="mr-2" />
                                                <span
                                                    class="coupon-info-ellipsis-text">{{couponDetail.segmentErrorMessage}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="card custom-box-summary box-gray-coupon">
                                    <div class="columns is-mobile my-0">
                                        <div class="column box-summary pt-0">
                                            <h2>
                                                <span class="pr-1">วันหมดอายุ:</span> {{
                                                convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                +
                                                convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                น.' }}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <!-- Use all channels -->
                        <ng-container *ngIf="couponDetail.status === CouponStatus.REDEEM_CONFIRMED">
                            <div class="card custom-box-summary box-coupon-blue">
                                <div class="columns is-mobile my-0">
                                    <div class="column box-summary">
                                        <h2><span class="pr-1">วันที่ใช้: </span> {{ redeemedDate }}</h2>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="couponDetail.status === CouponStatus.EXPIRED || couponDetail.status === CouponStatus.OUT_OF_QUOTA || couponDetail.status === CouponStatus.OUT_OF_USED_QUOTA || couponDetail.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                            <ng-container *ngIf="couponDetail.isEligibleToUse else cardBoxNotEligibleToUse">
                                <ng-container
                                    *ngIf="couponDetail.status === CouponStatus.EXPIRED || couponDetail.status === CouponStatus.OUT_OF_QUOTA">
                                    <div class="card custom-box-summary box-gray-coupon">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <h2><span class="">วันหมดอายุ:</span> {{
                                                    couponDetail.couponOrder === 0 ?
                                                    convertToLocaleDateString(couponDetail.couponEndDatetime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.couponEndDatetime) + '
                                                    น.' :
                                                    convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                    น.' }}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="couponDetail.status === CouponStatus.OUT_OF_USED_QUOTA">
                                    <div class="card custom-box-summary box-gray-coupon">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <h2><span class="">วันหมดอายุ:</span> {{
                                                    couponDetail.couponOrder === 0 ?
                                                    convertToLocaleDateString(couponDetail.couponEndDatetime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.couponEndDatetime) + '
                                                    น.' :
                                                    convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                    น.' }}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="couponDetail.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                    <div [class]="'card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4'">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary">
                                                <h2 data-testid="message-tempo-out-of-quota">
                                                    <img src="../../../assets/icon/ic_info.png" width="16"
                                                        class="mr-2" />
                                                    <div class="box-coupon-info-content">
                                                        คุณสามารถเก็บคูปองได้ในครั้งถัดไป<br />
                                                        ตามวันที่กำหนดในเงื่อนไขด้านล่างนี้
                                                    </div>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card custom-box-summary box-gray-coupon mt-3">
                                        <div class="columns is-mobile my-0">
                                            <div class="column box-summary pt-0">
                                                <h2>
                                                    <span class="">วันหมดอายุ:</span> {{
                                                    convertToLocaleDateString(couponDetail.couponEndDatetime) + ' '
                                                    +
                                                    convertToLocaleTimeString(couponDetail.couponEndDatetime) + '
                                                    น.' }}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #cardBoxNotEligibleToUse>
                                <div class="card custom-box-summary box-coupon-info with-bg mt-2 mb-2 pl-4">
                                    <div class="columns is-mobile my-0">
                                        <div class="column box-summary">
                                            <h2 class="is-flex">
                                                <img src="../../../assets/icon/ic_info.png" width="16" class="mr-2" />
                                                <span
                                                    class="coupon-info-ellipsis-text">{{couponDetail.segmentErrorMessage}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="card custom-box-summary box-gray-coupon">
                                    <div class="columns is-mobile my-0">
                                        <div class="column box-summary pt-0">
                                            <h2>
                                                <span class="pr-1">วันหมดอายุ:</span> {{
                                                convertToLocaleDateString(couponDetail.redemptionEndDateTime) + ' '
                                                +
                                                convertToLocaleTimeString(couponDetail.redemptionEndDateTime) + '
                                                น.' }}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>

                        <div *ngIf="isDisplayProgressBarLabel() && ((couponDetail.deductionType === DeductionType.COLLECTED && couponDetail.status === CouponStatus.UNCOLLECTED) || couponDetail.deductionType === DeductionType.REDEEMED)"
                            class="section-progress background-light text-dark-gray px-4" [class]="barColor">
                            <div class="columns is-mobile my-0">
                                <div class="column is-half pt-0 pb-0">
                                    <ng-container
                                        *ngIf="couponDetail.deductionType === DeductionType.COLLECTED && couponDetail.status === CouponStatus.UNCOLLECTED">
                                        <span class="text-progress">
                                            เก็บแล้ว
                                        </span>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="couponDetail.deductionType === DeductionType.REDEEMED && couponDetail.status !== CouponStatus.REDEEM_CONFIRMED">
                                        <span class="text-progress">
                                            ใช้แล้ว
                                        </span>
                                    </ng-container>

                                </div>
                                <div class="column is-half pt-0 pb-0">
                                    <span class="text-progress-num is-pulled-right font-bold">
                                        {{couponDetail.progressionPercent}}%</span>
                                </div>
                            </div>
                            <progress class="progress" value="{{couponDetail.progressionPercent}}"
                                max="100">{{couponDetail.progressionPercent}}%</progress>
                        </div>

                        <div
                            *ngIf="couponDetail.couponUseMethod === CouponMethod.COPAY && this.couponDetail.campaignSOFList.length > 0">
                            <h2 class="text-desc-title">ช่องทางการจ่าย</h2>
                            <div class="section-payment-channel">
                                <div class="payment-channel-item"
                                    *ngIf="couponDetail.campaignSOFList.includes(SourceOfFundsType.PTP)">
                                    <img src="../../../assets/icon/ic_casa_ptp.png" alt="Coupon" width="20" height="20">
                                    <span>
                                        เป๋าตังเปย์
                                    </span>
                                </div>
                                <div class="payment-channel-item"
                                    *ngIf="couponDetail.campaignSOFList.includes(SourceOfFundsType.AC)">
                                    <img src="../../../assets/icon/ic_casa_next.png" alt="Coupon" width="20"
                                        height="20">
                                    <span>
                                        บัญชีกรุงไทย
                                    </span>
                                </div>
                            </div>
                        </div>

                        <h2 class="text-desc-title">รายละเอียดคูปอง</h2>
                        <div class="text-detail newline-wrap mb-24" data-testid="text-description"
                            (click)="navigateExternalURL($event);"
                            [innerHtml]="couponDetail.description | safe: 'html'">
                        </div>

                        <h2 class="text-desc-title">เงื่อนไขการใช้คูปอง</h2>
                        <div class="text-detail newline-wrap" data-testid="text-termsAndCondition"
                            (click)="navigateExternalURL($event);"
                            [innerHtml]="couponDetail.termsAndCondition | safe: 'html'">
                        </div>

                        <div class="box-bottom-action"
                            *ngIf="couponDetail.status !== CouponStatus.REDEEM_CONFIRMED && 
                            couponDetail.status !== CouponStatus.EXPIRED && 
                            couponDetail.status !== CouponStatus.OUT_OF_QUOTA && 
                            couponDetail.status !== CouponStatus.OUT_OF_USED_QUOTA && 
                            (!isCouponApplyCodeQRONCouponList() || (isCouponApplyCodeQRONCouponList() && (couponDetail.status === CouponStatus.UNCOLLECTED || couponDetail.status === CouponStatus.REDEEMED))) && 
                            !(isOpenDeepLinkCouponDetail() && couponDetail.couponType === CouponType.APPLYCODE && !cancelMode)">

                            <!-- Collect Button -->
                            <ng-container *ngIf="couponDetail.status === CouponStatus.UNCOLLECTED">
                                <ng-container
                                    *ngIf="couponDetail.periodStatus !== PeriodStatus.ALLOWED else periodAllow">
                                    <button class="button is-medium btn-medium btn-gray has-text-white-bis Disabled"
                                        data-testid="uncollected-pick-button">อยู่นอกเหนือเวลาเก็บ</button>
                                </ng-container>
                                <ng-template #periodAllow>
                                    <button class="button is-medium btn-medium btn-orange collect-button"
                                        [disabled]="clickedCollect" data-testid="uncollected-pick-button"
                                        id="CouponDetails_Collect" (click)="pickCoupon($event);">เก็บคูปอง
                                    </button>
                                </ng-template>
                            </ng-container>

                            <ng-container
                                *ngIf="(couponDetail.status === CouponStatus.NEW || couponDetail.status === CouponStatus.COLLECTED)">

                                <ng-container *ngIf="couponDetail.isEligibleToUse">

                                    <ng-container
                                        *ngIf="couponDetail.periodStatus !== PeriodStatus.ALLOWED else periodAllow">
                                        <button *ngIf="isInChannels"
                                            class="button is-medium btn-gray has-text-white-bis Disabled "
                                            [class]="couponDetail.periodStatus === PeriodStatus.PERIOD ? 'btn-medium' : ' btn-startused'"
                                            data-testid="out-period-used-button">{{ couponDetail.periodStatus ===
                                            PeriodStatus.DATE ? 'คูปองเริ่มใช้ได้วันที่ ' +
                                            convertToLocaleDateString(couponDetail.redemptionStartDateTime) :
                                            'อยู่นอกเหนือเวลาใช้' }}</button>
                                    </ng-container>

                                    <ng-template #periodAllow>
                                        <ng-container
                                            *ngIf="couponDetail.couponUseMethod === CouponMethod.COPAY else QRCodeCoupon">

                                            <ng-container *ngIf="isInChannels">

                                                <ng-container *ngIf="cancelMode else activeMode">
                                                    <a [href]="cancelCallbackUrl | safe : 'url'"
                                                        (click)="setStepRouteFlag(true); setGATagUseLater($event);"
                                                        class="button btn-red is-medium font-bold btn-medium"
                                                        id="CouponDetails_UseLater"
                                                        data-testid="cancel-used-button">เก็บไว้ใช้ภายหลัง</a>
                                                </ng-container>

                                                <ng-template #activeMode>
                                                    <ng-container
                                                        *ngIf="stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST) else scanPay">
                                                        <ng-container *ngIf="couponDetail.isAboveMinimumSpend">
                                                            <a #navigateCoPayPaymentUrl
                                                                data-testid="use-coupon-callback-safe"
                                                                [href]="chooseCouponCallbackSafeUrl | safe : 'url'"
                                                                (click)="setStepRouteFlag(true)"
                                                                class="is-hidden button is-medium btn-medium btn-blue font-bold">ใช้คูปอง
                                                            </a>

                                                            <button
                                                                class="button is-medium btn-medium btn-blue font-bold redeem-button"
                                                                [disabled]="clickedRedeem" id="CouponDetails_ScanPTP"
                                                                (click)="useScanCopayCoupon($event,'ScanCoPay');"
                                                                data-testid="scan-pt-pay">
                                                                ใช้คูปอง
                                                            </button>
                                                        </ng-container>
                                                        <ng-container *ngIf="!couponDetail.isAboveMinimumSpend">
                                                            <button
                                                                class="button is-medium btn-medium btn-gray has-text-white-bis Disabled"
                                                                data-testid="scan-pt-pay-disabled">ใช้คูปอง
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-template #scanPay>
                                                        <ng-container
                                                            *ngIf="couponDetail.isUseButtonDisplay && !couponDetail.isCoPayNavigate">
                                                            <button
                                                                class="button btn-blue is-medium font-bold btn-scanpay redeem-button"
                                                                [disabled]="clickedRedeem" id="CouponDetails_ScanPTP"
                                                                (click)="useScanCopayCoupon($event, 'ScanCoPay'); "
                                                                data-testid="scan-pt-pay">
                                                                <div
                                                                    class="button btn-blue is-medium font-bold btn-scanpay redeem-button">
                                                                    สแกนจ่าย
                                                                </div>
                                                            </button>

                                                            <a #navigateCoPayPaymentUrl hidden
                                                                [href]="coPayPaymentUrl | safe : 'url'"
                                                                data-testid="link-scan-pt-pay"
                                                                (click)="setStepRouteFlag();setSelectedCategoryOrSearchKeywordValue()">
                                                            </a>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="!couponDetail.isUseButtonDisplay && couponDetail.isCoPayNavigate">
                                                            <ng-container
                                                                *ngIf="couponDetail.copayNavigation?.navigateType === CopayNavigationType.INTERNAL_PT">

                                                                <button
                                                                    *ngIf="couponDetail.copayNavigation?.destination === InternalNavigationType.PT_PAYMENT"
                                                                    class="button btn-blue is-medium font-bold btn-scanpay redeem-button"
                                                                    [disabled]="clickedRedeem"
                                                                    id="CouponDetails_ScanPTP"
                                                                    (click)="usePaymentTopup($event,'ScanCoPay'); "
                                                                    data-testid="">
                                                                    จ่ายบิล
                                                                </button>

                                                                <button
                                                                    *ngIf="couponDetail.copayNavigation?.destination === InternalNavigationType.PT_TOPUP"
                                                                    class="button btn-blue is-medium font-bold btn-scanpay redeem-button"
                                                                    [disabled]="clickedRedeem"
                                                                    id="CouponDetails_ScanPTP"
                                                                    (click)="usePaymentTopup($event,'ScanCoPay'); "
                                                                    data-testid="">
                                                                    เติมเงิน
                                                                </button>

                                                                <button
                                                                    *ngIf="couponDetail.copayNavigation?.destination !== InternalNavigationType.PT_PAYMENT && couponDetail.copayNavigation?.destination !== InternalNavigationType.PT_TOPUP"
                                                                    class="button btn-blue is-medium font-bold btn-scanpay redeem-button"
                                                                    [disabled]="clickedRedeem"
                                                                    id="CouponDetails_ScanPTP"
                                                                    (click)="usePaymentTopup($event,'ScanCoPay'); "
                                                                    data-testid="">
                                                                    ใช้คูปอง
                                                                </button>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="couponDetail.copayNavigation?.navigateType === CopayNavigationType.EXTERNAL_URL || couponDetail.copayNavigation?.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK">
                                                                <button
                                                                    class="button btn-blue is-medium font-bold btn-scanpay redeem-button"
                                                                    [disabled]="clickedRedeem" data-testid=""
                                                                    id="CouponDetails_Use"
                                                                    (click)="navigatCopayExternalURL($event);">
                                                                    ใช้คูปอง
                                                                </button>
                                                            </ng-container>
                                                        </ng-container>


                                                    </ng-template>
                                                </ng-template>
                                            </ng-container>

                                        </ng-container>

                                        <ng-template #QRCodeCoupon>
                                            <button
                                                class="button is-medium btn-medium btn-blue font-bold btn-used redeem-button"
                                                [disabled]="clickedRedeem" data-testid="use-coupon-button"
                                                id="CouponDetails_Use" (click)="useCoupon($event, 'Use');">ใช้คูปอง
                                            </button>
                                        </ng-template>
                                    </ng-template>
                                </ng-container>
                            </ng-container>


                            <ng-container *ngIf="couponDetail.status === CouponStatus.REDEEMED">
                                <ng-container *ngIf="!isCouponApplyCodeQRONCouponList() else buttonQRONCouponList">
                                    <button class="button is-medium btn-medium btn-blue font-bold"
                                        data-testid="view-code-button" id="CouponDetails_Code"
                                        (click)="useCoupon($event, 'Code');">ดูโค้ด
                                    </button>
                                </ng-container>
                                <ng-template #buttonQRONCouponList>
                                    <button class="button is-medium btn-medium btn-gray has-text-white-bis Disabled"
                                        data-testid="view-code-button">ดูโค้ด
                                    </button>
                                </ng-template>
                            </ng-container>

                            <ng-container *ngIf="couponDetail.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                <button class="button is-medium btn-medium btn-gray has-text-white-bis Disabled"
                                    data-testid="button-tempo-out-of-quota">ยังไม่ถึงวันที่เริ่มเก็บ
                                </button>
                            </ng-container>
                        </div>

                    </div><!-- padding-content -->
                </div>
            </div>
            <app-animation-loading [couponloading]="clickedCollect">

            </app-animation-loading>
            <div #toastPopup class="toast-noti is-hidden"
                [ngClass]="{'toast-upper': couponChannel === CouponChannel.CNX}">
                <table>
                    <tr>
                        <td><img class="mr-2" src="../../../assets/icon/Icon-checked.png" width="25"></td>
                        <td><span>เก็บคูปองสำเร็จ</span></td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</ng-container>