<div [class]="isAndroid ? ' android-device' : ''">
  <section class="modal-card-body modal-card-bottom-dialog">
    <div class="columns is-gapless is-mobile mb-16">
      <div class="column is-three-quarters pb-0 ">
        <h2 class="text-title-confirm">{{ couponNumber }} {{titleDialog}}</h2>
      </div>
      <div class="column has-text-right box-close" data-testid="box-close" (click)="closeDialog()">
        <img src="../../../assets/icon/ic_close_gray.png" width="24">
        <!-- <div class="icon-position pb-0 icon-close" ></div> -->
      </div>
    </div>
    <!-- <div style="padding-bottom: 18px;"> -->

    <mat-dialog-content>

      <ng-container [ngSwitch]="couponDialogTemplate">

        <ng-container *ngSwitchCase="CouponCardDialogTemplate.APPLY_COUPON_BOTTOM_DIALOG">
          <apply-coupon-card-horizontal-dialog [coupons]="coupons" [applyCouponKeyword]="couponKeyword" [userSOF]="userSOF"
            [couponChannel]='couponChannel' [entryPoint]="entryPoint" [stepRouteConfig]="(stepRouteConfig$ | async)!"
            (onActionNavigateToCouponDetail)="navigateToCouponDetail($event)"
            (onActionTrackGAUseCoupon)="onActionTrackGAUseCoupon()" (onActionUseCoupon)="navigateToUseCoupon($event)"
            (onActionUseCouponPaymentTopup)="navigateToInternal($event)"
            (onActionUseCouponExternalURL)="navigateToExternal($event)"
            (onActionSetStepRoute)="setStepRouteFlag($event)" (onActionSetToCloseDialog)="setToCloseDialog($event)">
          </apply-coupon-card-horizontal-dialog>
        </ng-container>

        <ng-container *ngSwitchCase="CouponCardDialogTemplate.SEARCH_COUPON_BOTTOM_DIALOG">
          <search-coupon-card-horizontal-dialog [coupons]="coupons" [keyword]="couponKeyword" [userSOF]="userSOF"
            [couponChannel]='couponChannel' [entryPoint]="entryPoint" (onActionNavigateToCouponDetail)="navigateToCouponDetail($event)"
            (onActionTrackGAUseCoupon)="onActionTrackGAUseCoupon()" (onActionUseCoupon)="navigateToUseCoupon($event)"
            (onActionUseCouponPaymentTopup)="navigateToInternal($event)"
            (onActionUseCouponExternalURL)="navigateToExternal($event)"
            (onActionSetStepRoute)="setStepRouteFlag($event)" (onActionSetToCloseDialog)="setToCloseDialog($event)">
          </search-coupon-card-horizontal-dialog>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <coupon-card-horizontal-dialog [couponDialogTemplate]="couponDialogTemplate" [coupons]="coupons"
            [amount]="amount" [couponChannel]='couponChannel' [entryPoint]="entryPoint" [userSOF]="userSOF"
            (onActionNavigateToCouponDetail)="navigateToCouponDetail($event)"
            (onActionTrackGAUseCoupon)="onActionTrackGAUseCoupon()" (onActionUseCoupon)="navigateToUseCoupon($event)"
            (onActionUseCouponPaymentTopup)="navigateToInternal($event)"
            (onActionUseCouponExternalURL)="navigateToExternal($event)"
            (onActionSetStepRoute)="setStepRouteFlag($event)" (onActionSetToCloseDialog)="setToCloseDialog($event)">
          </coupon-card-horizontal-dialog>
        </ng-container>

      </ng-container>
    </mat-dialog-content>
    <!-- </div> -->

  </section>
</div>