<div id="under-maintenance-content" class="bg-special">
    <div [class]="isAndroid ? ' android-device' : ''">        
        <div class="bg-white">
            <div class="container margin-top">        
                <div class="has-text-centered">
                    <img src="../../../assets/images/bg-unauthorized.png">
                    <p>
                        <span class="font-bold">ปิดปรับปรุงระบบบริการคูปองชั่วคราว</span>
                        ขออภัยในความไม่สะดวก<br>
                        เนื่องจากอยู่ระหว่างการปรับปรุงระบบ<br>
                        เพื่อให้มีประสิทธิภาพที่ดียิ่งขึ้น
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="couponChannel==CouponChannelEnum.PT" class="box-bottom-action">
            <a [href]="callbackUrl | safe: 'url'" data-testid="link-back-icon" class="button btn-blue is-medium is-fullwidth font-bold has-text-weight-semibold">
                ตกลง
            </a>
        </div>
    </div>
</div>
