import { Injectable } from "@angular/core";
import { EventFunctionList, EventAction, FirebaseParam, FirebaseScreen, EventActionWithNative, EventFunctionToNative } from "./webview-to-native-interface.model";
import { CouponChannel } from "../model/coupon-enum.model";
import { Subject } from "rxjs";
import { Store } from '@ngrx/store';
import { AppState } from "../root-store/root-store.model";
import * as RootSelectors from '../root-store/root-selectors';

declare const webviewToNativeInterface: EventFunctionList;
declare const nativeInterface: EventFunctionToNative;
declare const window: any;
@Injectable({
  providedIn: 'root',
})
export class WebviewToNativeInterfaceService {
  jwtSession$ = this.store.select(RootSelectors.selectJWTSession);
  CouponChannel = CouponChannel;
  couponChannel = "";
  private _destroy = new Subject<void>();
  constructor(
    private store: Store<AppState>
  ) {
    this.jwtSession$.subscribe((jwtSession) => {
      this.couponChannel = jwtSession.couponChannel;
    });
  }

  trackAction(event: EventAction, firebaseParam?: FirebaseParam[]) {
    if (this.couponChannel === CouponChannel.NEXT) {
      const data = firebaseParam
        ? {
          ga: {
            event,
            firebaseParam,
          }
        }
        : {
          ga: {
            event
          }
        };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.nativeInterface.postMessage(body);
        } else {
          nativeInterface.onRetrievePayloadString(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        // console.log(body);
        // handle when open on web
      }
    } else {
      const data = firebaseParam
        ? {
          event,
          firebaseParam,
        }
        : { event };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
        } else {
          webviewToNativeInterface.gaTagging(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        // console.log(body);
        // handle when open on web
      }
    }
  }

  trackPageView(event: FirebaseScreen) {
    const data = { event }
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
      } else {
        webviewToNativeInterface.gaTagging(body);
      }
    } catch (error) {
      // uncomment for GA testing purpose
      // console.log(body);
      // handle when open on web
    }
  }

  interfaceToNative(event: EventActionWithNative) {
    const body = JSON.stringify(event);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.nativeInterface.postMessage(body)
      } else {
        nativeInterface.onRetrievePayloadString(body);
      }
    } catch (error) {
      // console.log(error);
    }
  }
}
