<div *ngIf="!(isLoading$ | async)">
    <div [class]="(isAndroid ? ' android-device bg-blue bg-blue-android-device' : 'bg-blue')" class="loading-page">
        <div [class]="'header has-text-centered header-flex' + (withHeader ? ' header-height' : ' without-header')">
            <div class="has-text-left back-btn" *ngIf="withHeader" style="flex-shrink: 0; width: 40px;">
                <a [href]="callbackUrl | safe: 'url'" class="link-item" data-testid="link-back-icon"
                    (click)="setBackGATag()"></a>
            </div>
            <div *ngIf="!withHeader" style="width: 40px;"></div>
            <div class="header-title">
                <h3>คูปอง</h3>
            </div>
            <button class="go-my-coupon" #coachMark (click)="!coachThird && !coachSecond? goLink() : false"
                data-testid="link-my-coupon">
                <img src="../../../assets/images/Coupon.png" alt="Coupon" width="32" height="32">
                <div *ngIf="numberOfCollectedCoupon > 0"
                    [class] = "numberOfCollectedCoupon > 99 ? 'badge-for-icon' : 'badge-for-icon-less-than-3-digit'"
                    data-testid="number-of-collected-coupon">
                    {{ numberOfCollectedCoupon}}
                </div>
                <div [class.coachShadow]="coachThird"></div>
            </button>
        </div>

        <div id="coupon-home-content">
            <!-- new design -->
            <div class="column container-coupon-search" [class.on-coach-mark-pointer-none]="coachSecond" #column>
                <div class="form-search-coupon">
                    <form-search-coupon #searchCoupon [couponTemplate]="couponTemplate"
                        [defaultSearchKeyword]="(searchDefaultKeyword$ | async)!"
                        (onActionSearchForHomePage)="navigateToSearchCoupon()">
                    </form-search-coupon>
                </div>
                <div class="form-apply-coupon" #coachMarkApplyCoupon>
                    <form-apply-coupon [applyCouponTemplate]="couponTemplate"
                        (onActionNavigateToApplyCoupon)="navigateToApplyCoupon()">
                    </form-apply-coupon>
                    <div [ngClass]="{
                        'coachShadowSecond': coachSecond , 
                        'CNXChannel':  couponChannel === CouponChannel.CNX, 
                        'NextChannel': couponChannel === CouponChannel.NEXT}">
                    </div>
                </div>
            </div>

            <ng-container *ngIf="this.countTimeCoupon > 0 && this.countTimeCoupon < 3600">
                <countdown hidden [config]="{ leftTime: this.countTimeCoupon }" (event)="handleEvent($event)">
                </countdown>
            </ng-container>
            <div class="coupon-list-card"></div>
            <div [class]="'notification pd-content' + (withHeader ? '' : ' mg-CNX')">
                <ng-container *ngIf="couponCategories.length > 0">
                    <coupon-category-section [categories]="couponCategories"
                        [selectedCategoryId]="selectedCategoryId$ | async"
                        (onActionSelectedCategory)="onActionSelectedCategory($event)">
                    </coupon-category-section>
                </ng-container>

                <ng-container *ngFor="let coupon of couponData; let i = index">
                    <ng-container *ngFor="let couponInfo of coupon.couponInfo; let j = index">
                        <ng-container
                            *ngIf="coupon.hasCountdownTimer && couponInfo.countdownTimeInSeconds &&couponInfo.countdownTimeInSeconds > 0 && couponInfo.status === CouponStatus.REDEEMED">
                            <countdown hidden [config]="{ leftTime: couponInfo.countdownTimeInSeconds }"
                                (event)="handleHomePageCountdownEvent($event, i, j)"></countdown>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <div class="wrap-scroll" [class.no-coupon-category]="couponCategories.length === 0"
                    *ngIf="filterCouponData.length > 0">
                    <coupon-card-horizontal #couponCard [couponTemplate]="couponTemplate" [coupons]="filterCouponData"
                        [selectedCoupon]="selectedCoupon$ | async" [couponChannel]="couponChannel" [entryPoint]="entryPoint" [userSOF]="userSOF"
                        [chooseCouponCallbackUrl]="chooseCouponCallbackUrl" [paymentCallbackUrl]="paymemtCallbackUrl"
                        [registerCallbackUrl]="registerCallbackUrl" [processingCallbackUrl]="processingCallbackUrl"
                        (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                        (emitOpenDialogButtom)="openOverlay($event.coupon, $event.isInChannel)"
                        (onActionPickCoupon)="pickCoupon($event.element, $event.couponId, $event.couponOrder, $event.campaignCode, $event.couponShortTitle)"
                        (onActionUseCoupon)="useCoupon($event.element ,$event.coupon)"
                        (onActionSetStepRoute)="setStepRouteFlag($event)"
                        (onActionSelectedCoupon)="onActionSelectedCoupon($event)"
                        (onActionUseCouponCopay)="onActionUseCouponCopay($event.eventLabel , $event.campaignCode, $event.couponShortTitle)"
                        (onActionUseCouponPaymentTopup)="onActionUseCouponPaymentTopup($event.eventLabel, $event.campaignCode, $event.couponShortTitle, $event.destination)"
                        (onActionUseCouponExternalURL)="navigateExternalURL($event.coupon , $event.externalUrl)"
                        (onActionPermissionError)="displayPermissionError($event.campaignCode, $event.permisionCoupon, CouponActionType.USE, $event?.isNavigate)"
                        (onActionSetToLoadFech)="setToLoadFech()">
                    </coupon-card-horizontal>
                </div>
                <!-- </ng-template> -->

                <div *ngIf="couponData?.length == 0 && isShowEmptyCoupon">
                    <div class="">
                        <div class="container margin-top">
                            <div class="has-text-centered">
                                <img class="coupon-empty-img" src="../../../assets/images/coupon_empty.png">
                                <p class="font-bold space-pt" data-testid="empty-coupon-text">
                                    พบกับคูปองที่น่าสนใจเร็วๆ นี้
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <app-animation-loading [couponloading]="isLoadingCollectCoupon$ | async"
            [loadingAnimation]="isloadingAnimation$ | async">
        </app-animation-loading>

        <div #toastPopup class="toast-noti is-hidden">
            <table>
                <tr>
                    <td><img class="mr-2" src="../../../assets/icon/Icon-checked.png" width="25"></td>
                    <td><span>เก็บคูปองสำเร็จ</span></td>
                </tr>
            </table>
        </div>

        <p-overlayPanel #overlayFirst [dismissable]="false" [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            [styleClass]="'overlay-first ' + (isAndroid ? ' isAndroid' : '') + (couponChannel === CouponChannel.CNX ? ' inCNXChannel' : '') + (couponChannel === CouponChannel.NEXT ? ' inNextChannel' : '')"
            >
            <ng-template pTemplate>
                <div [class.tutorial-with-category]="couponCategories.length > 0">
                    <div class="coachCollectedButton is-pulled-right" [class.isAndroid]="isAndroid"
                        [class.CNXChannel]="couponChannel === CouponChannel.CNX"
                        [class.NextChannel]="couponChannel === CouponChannel.NEXT">
                        <div class="button font-bold btn-collect">
                            เก็บ
                        </div>
                    </div>
                    <app-coach-mark [isFirstCoachMark]="true" [isAndroid]="isAndroid" [stepCoachMark]="stepCoachMark"
                        (onActionNext)="displayCoachMark('second')"></app-coach-mark>
                </div>
            </ng-template>
            <div class="overlay-skip-coachmark">
                <div (click)="displayCoachMark('start')">ข้ามวิธีใช้</div>
            </div>
        </p-overlayPanel>

        <p-overlayPanel #overlayApplyCode [dismissable]="false"
            [styleClass]="'overlay-second ' + (isAndroid ? ' isAndroid' : '') + (couponChannel === CouponChannel.CNX ? ' CNXChannel' : '') + (couponChannel === CouponChannel.NEXT ? ' NextChannel' : '')"
            [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
            <ng-template pTemplate>
                <app-coach-mark [isAndroid]="isAndroid" [stepCoachMark]="stepCoachMark"
                    (onActionBack)="displayCoachMark('first')"
                    (onActionNext)="displayCoachMark('third')"></app-coach-mark>
            </ng-template>
            <div class="overlay-skip-coachmark">
                <div (click)="displayCoachMark('start')">ข้ามวิธีใช้</div>
            </div>
        </p-overlayPanel>

        <p-overlayPanel #op [dismissable]="false"
            [styleClass]="'overlay-third ' + (isAndroid ? ' isAndroid' : '') + (couponChannel === CouponChannel.CNX ? ' CNXChannel' : '') + (couponChannel === CouponChannel.NEXT ? ' NextChannel' : '')"
            [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
            <ng-template pTemplate>
                <app-coach-mark [isAndroid]="isAndroid" (onActionBack)="displayCoachMark('second')"
                    (onActionStart)="displayCoachMark('start')"></app-coach-mark>
            </ng-template>
            <div class="overlay-skip-coachmark">
                <div (click)="displayCoachMark('start')">ข้ามวิธีใช้</div>
            </div>
        </p-overlayPanel>

    </div>
</div>