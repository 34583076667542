export function convertToLocaleDateString(date: Date) {
    return date.toLocaleDateString('th-TH', { year: '2-digit', month: 'short', day: 'numeric' });
}

export function convertToLocaleTimeString(date: Date) {
    return date.toLocaleTimeString('th-TH', {
        hour: '2-digit',
        minute: '2-digit',
    });
}

export function calculateTimeDiff(date: Date) {
    const currentDate = new Date();
    return Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60);
}