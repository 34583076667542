import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { UtilService } from 'src/app/service/util.service';
import { CouponService } from 'src/shared/coupon.service';

@Component({
  selector: 'app-offline-internet',
  templateUrl: './offline-internet.component.html',
  styleUrls: ['./offline-internet.component.scss']
})
export class OfflineInternetComponent implements OnInit {

  faRedo = faRedo;
  isAndroid: boolean = this.utilService.isMobileDevice();
  currentPage$ = this.couponService.currentPage$;
  currentPage: string = '';
  private _destroy = new Subject<void>();

  constructor(
    private router: Router,
    private utilService: UtilService,
    private couponService: CouponService,
  ) { }

  ngOnInit(): void {
    this.utilService.stopLoading()
    this.currentPage$.pipe(takeUntil(this._destroy)).subscribe((currentPageValue) => {
      this.currentPage = currentPageValue;
    })
  }

  async checkOffline() {
    if (navigator.onLine) {
      this.router.navigateByUrl(this.currentPage, { replaceUrl: true })
    }
  }
}
