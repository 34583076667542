import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-coach-mark',
  templateUrl: './coach-mark.component.html',
  styleUrls: ['./coach-mark.component.scss']
})
export class CoachMarkComponent implements OnInit {
  @Input() isFirstCoachMark: boolean = false;
  @Input() stepCoachMark: number = 0;
  @Input() isAndroid: boolean = false;

  @Output() onActionNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionStart: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

}
