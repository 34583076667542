import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observer, Subject } from 'rxjs';
import { PageRoute } from '../configs/route-config';
import { values } from 'lodash';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private router: Router
  ) {

  }

  // ---------- about loading
  pageRoute: PageRoute | undefined;
  private _loading: boolean = false;
  loadingStatus: any = new Subject();
  get loading(): boolean {
    return this._loading;
  }
  set loading(val) {
    this._loading = val;

    const loading = {
      isloading: val,
      pageRoute: this.pageRoute
    };

    this.loadingStatus.next(loading)
  }

  startLoading(pageRoute: PageRoute) {
    this.pageRoute = pageRoute;
    setTimeout(() => {
      this.loading = true;
    }, 100);
  }

  stopLoading() {
    setTimeout(() => {
      this.loading = false;
    }, 800);
  }
  // ---------- about loading

  checkErrorRes(error: HttpErrorResponse) {
    if (error?.error?.result?.code == 1004) {
      this.removeTokenSession();
      this.router.navigateByUrl(PageRoute.PATH_UNAUTHORIZED);
    }
    if (error?.error?.result?.code == 1007 || error.status == 404) {
      this.router.navigateByUrl(PageRoute.PATH_NOT_FOUND);
    }
    if ((error?.error?.result?.code == 8000) || (error.status == 403) || (error.status == 503) || (error.status == 0 && error.statusText == "Unknown Error")) {
      this.router.navigateByUrl(PageRoute.PATH_UNDER_MAINTENANCE);
    }
  }

  isMobileDevice() {
    return /(android)/i.test(navigator.userAgent);
  }

  safeURL(url: string) {
    const REGEX_WORDING_JAVASCRIPT = /(javascript|javascript:|<script>|<script|<\/script>)/i;
    if (REGEX_WORDING_JAVASCRIPT.test(url)) {
      return `unsafe:${url}`
    }
    return url;
  }

  parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    if (!base64) return '';
    var jsonPayload = decodeURIComponent(
      atob(base64)
        ?.split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  removeTokenSession() {
    sessionStorage.removeItem('codeToken');
    sessionStorage.removeItem('accessToken');
  }

}
