import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageRoute } from 'src/app/configs/route-config';

@Component({
  selector: 'skeleton-detail',
  templateUrl: './skeleton-detail.component.html',
  styleUrls: ['./skeleton-detail.component.scss']
})
export class SkeletonDetailComponent implements OnInit {

  @Input() withHeader: boolean;
  @Input() path: PageRoute;
  PageRoute = PageRoute;
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
