<div *ngIf="couponDetail" class="bg-standard bg-bottom-blue overflowhidden">
    <div class="h-full">
        <div [class]="'header has-text-centered header-flex' + (withHeader ? '' : ' without-header')">
            <div class="has-text-left back-btn">
                <a (click)="navigateBack(); removeStepRouteConfig();" id="CouponCodeScreen_Back"
                    data-testid="coupon-back-button">
                </a>
            </div>
            <div class="header-title">
                <h3>&nbsp;</h3>
            </div>
            <div class="header-temp-last">
            </div>
        </div>

        <div class="qr-code-card" [class.android-device]="isAndroid">
            <div id="qr-code-content"
                [class]="'notification' + (withHeader ? '' : ' mg-CNX') + (couponDetail.isUseButtonDisplay && disabledScan ? ' notification-android' : ' ')">
                <!-- remove mb-16 container -->
                <div class="container">
                    <div class="columns is-gapless">
                        <div
                            [class]="'column ' + ((disabledScan || !couponDetail.isUseButtonDisplay) ? 'remove-bottom-pd' : 'set-bottom-pd') ">

                            <div class="coupon-title font-bold">
                                {{couponDetail.couponTitle}}
                            </div>

                            <div class="">
                                <div class="tab-wrap">
                                    <input type="radio" (change)="checkValue('QR Code')" id="tab1" name="tabGroup1"
                                        class="tab" checked data-testid="check-value" [checked]="tabName === 'QR Code'">
                                    <input type="radio" (change)="checkValue('Barcode')" id="tab2" name="tabGroup1"
                                        class="tab" data-testid="check-value1" [checked]="tabName === 'Barcode'">
                                    <div class="bg-white" [class.pt-16]="couponDetail.isShowQr">
                                        <div class="label-container is-mobile box-radius"
                                            [hidden]="!couponDetail.isShowQr">
                                            <label for="tab1" class="label font-bold">QR</label>
                                            <label for="tab2" class="label font-bold">บาร์โค้ด</label>
                                        </div>
                                    </div>

                                    <div class="content-container">
                                        <div class="tab__content">
                                            <div class="bg-white"
                                                [ngClass]="{'displaynone': !couponDetail.hasCountdownTimer || disabledScan || isExpire}">
                                                <div class="has-text-centered font-bold coupon-status"
                                                    [class.text-timer-qr-barcode]="couponDetail.isShowQr">
                                                    คูปองนี้จะหมดอายุภายในเวลา
                                                </div>
                                                <div class="has-text-centered coupon-time"
                                                    [class.timer-qr-barcode]="couponDetail.isShowQr">
                                                    <table class="tbl-time">
                                                        <tr>
                                                            <td><img src="./assets/images/clock.gif" width="56" /></td>
                                                            <td>
                                                                <countdown data-testid="count-down-time" [config]="{ 
                                                              leftTime: countdownTimeInSecondsToday>0 ? countdownTimeInSecondsToday : couponDetail.countdownTimeInSeconds, 
                                                              format: countdownTimeInSecondsToday>0 ? 'hh:mm:ss' : 'mm:ss' 
                                                          }" (event)="handleEvent($event)">
                                                                </countdown>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div
                                                [ngClass]="{'displaynone': (couponDetail.hasCountdownTimer) || disabledScan || isExpire}">
                                                <div class="has-text-centered bg-white">
                                                    <img src="./assets/images/clock_coupon.gif" width="72"
                                                        class="clock-coupon"
                                                        [class.no-timer-qr-barcode]="!couponDetail.isShowQr && !(couponDetail.hasCountdownTimer)" />
                                                </div>
                                            </div>
                                            <div class="has-text-centered qrcode bg-white"
                                                [hidden]="!couponDetail.isShowQr"
                                                [ngClass]="{'is-disabled': disabledScan || isExpire}">
                                                <div class="card-image">
                                                    <div data-testid="stamp-used-qrcode"
                                                        [ngClass]="{'stamp-used': disabledScan || isExpire}">
                                                        <figure class="image is-inline-block">
                                                            <qr-code [value]="couponDetail.couponCode" size="200"
                                                                errorCorrectionLevel="M" [margin]="0"></qr-code>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- add pb-3  -->
                                            <div class="has-text-centered pb-3 text-comment-qr bg-white"
                                                [hidden]="!couponDetail.isShowQr">
                                                แสดง QR ให้กับพนักงาน <br>
                                                หรือคัดลอกโค้ดด้านล่างเพื่อใช้คูปอง
                                            </div>

                                            <!-- for QRCode doesn't show and confirmed -->
                                            <ng-container *ngIf="!couponDetail.isShowQr">
                                                <div class="has-text-centered bg-white"
                                                    [hidden]="isShowQrUsed && !disabledScan">
                                                    <div class="card-image pt-5">
                                                        <div
                                                            [ngClass]="{'noqr': disabledScan || isExpire, 'stamp-used': disabledScan || isExpire}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    class="has-text-centered text-comment no-display-qr-barcode bg-white">
                                                    คัดลอกโค้ดด้านล่างเพื่อใช้คูปอง
                                                </div>
                                            </ng-container>

                                            <div class="qr-mask mask-cir">
                                                <div class="linedot"></div>
                                                <div class="linedot2"></div>
                                            </div>

                                            <div class="has-text-centered pt-3 bg-white"
                                                [ngClass]="{'is-disabled': disabledScan || isExpire, 'increase-bottom': !couponDetail.isUseButtonDisplay || this.disabledScan}">
                                                <div class="has-text-centered text-comment">โค้ดคูปอง</div>
                                                <div class="coupon-code">
                                                    <div class="input-code">
                                                        {{couponDetail.couponCode}}
                                                    </div>
                                                    <img *ngIf="couponDetail.isUseExternalUrl && !disabledScan"
                                                        (click)="copyClipboard();"
                                                        src="../../../assets/images/Union.jpg" width="18" height="18"
                                                        alt="Union">

                                                </div>
                                                <ng-container
                                                    *ngIf="!couponDetail.isUseExternalUrl else useExternalUrl">
                                                    <div class="input-txt-below">
                                                        <button class="button font-bold btn-info is-long"
                                                            [disabled]="disabledScan" data-testid="copy-clipboard"
                                                            id="CouponCodeScreen_CopyCode" (click)="copyClipboard(); ">
                                                            คัดลอก
                                                        </button>
                                                    </div>
                                                </ng-container>
                                                <ng-template #useExternalUrl>
                                                    <div class="input-txt-below">
                                                        <button class="button font-bold btn-info is-long"
                                                            data-testid="external-url-qr" [disabled]="disabledScan"
                                                            (click)="navigateExternalURL($event)">
                                                            ไปยังช่องทางพาร์ทเนอร์
                                                        </button>
                                                        <!-- <a #navigateDeeplinkExternalUrl hidden
                                                        [href]="couponDetail.externalUrlDestination | safe : 'url'"
                                                        data-testid="deeplink-to-partner">
                                                    </a> -->
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div><!-- tab__content1 -->

                                        <div class="tab__content">
                                            <div class="bg-white"
                                                [ngClass]="{'displaynone': !couponDetail.hasCountdownTimer || disabledScan || isExpire}">
                                                <div class="has-text-centered font-bold coupon-status"
                                                    [class.pt-16]="couponDetail.isShowQr">
                                                    คูปองนี้จะหมดอายุภายในเวลา
                                                </div>
                                                <div class="has-text-centered coupon-time pt-8">
                                                    <table class="tbl-time">
                                                        <tr>
                                                            <td><img src="./assets/images/clock.gif" width="56" /></td>
                                                            <td>
                                                                <countdown data-testid="count-down-time" [config]="{ 
                                                            leftTime: countdownTimeInSecondsToday>0? countdownTimeInSecondsToday : couponDetail.countdownTimeInSeconds, 
                                                              format: countdownTimeInSecondsToday>0? 'hh:mm:ss' : 'mm:ss' 
                                                          }" (event)="handleEvent($event)">
                                                                </countdown>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div
                                                [ngClass]="{'displaynone': (couponDetail.hasCountdownTimer) || disabledScan || isExpire}">
                                                <div class="has-text-centered bg-white">
                                                    <img src="./assets/images/clock_coupon.gif" width="72"
                                                        class="clock-coupon"
                                                        [class.no-timer-qr-barcode]="!couponDetail.isShowQr && !(couponDetail.hasCountdownTimer)" />
                                                </div>
                                            </div>
                                            <div class="has-text-centered barcode bg-white"
                                                [hidden]="!couponDetail.isShowQr"
                                                [ngClass]="{'barcode-heighauto': disabledScan || isExpire}">
                                                <div data-testid="stamp-used-barcode"
                                                    [ngClass]="{'stamp-used-barcode': disabledScan || isExpire}">
                                                    <figure class="image is-inline-block">
                                                        <ngx-barcode *ngFor="let bcValue of values"
                                                            [bc-value]="couponDetail.couponCode" [bc-margin-top]="8"
                                                            [bc-margin-bottom]="8" [bc-margin-left]="0"
                                                            [bc-margin-right]="0" [bc-width]="barcodeWidth">
                                                        </ngx-barcode>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div class="has-text-centered text-comment-barcode pb-3 bg-white"
                                                [hidden]="!couponDetail.isShowQr">
                                                แสดง Barcode ให้กับพนักงาน <br>
                                                หรือคัดลอกโค้ดด้านล่างเพื่อใช้คูปอง
                                            </div>

                                            <div class="barcode-mask mask-cir">
                                                <div class="linedot"></div>
                                                <div class="linedot2"></div>
                                            </div>

                                            <div class="pt-3 has-text-centered bg-white"
                                                [ngClass]="{'is-disabled': disabledScan || isExpire, 'increase-bottom': !couponDetail.isUseButtonDisplay || this.disabledScan}">
                                                <div class="has-text-centered text-comment">โค้ดคูปอง</div>
                                                <div class="coupon-code">
                                                    <div class="input-code">
                                                        {{couponDetail.couponCode}}
                                                    </div>
                                                    <img *ngIf="couponDetail.isUseExternalUrl && !disabledScan"
                                                        (click)="copyClipboard();"
                                                        src="../../../assets/images/Union.jpg" width="18" height="18"
                                                        alt="Union">
                                                </div>
                                                <ng-container
                                                    *ngIf="!couponDetail.isUseExternalUrl else useExternalUrl">
                                                    <div class="input-txt-below">
                                                        <button class="button font-bold btn-info is-long"
                                                            [disabled]="disabledScan" data-testid="copy-clipboard"
                                                            id="CouponCodeScreen_CopyCode" (click)="copyClipboard(); ">
                                                            คัดลอก
                                                        </button>
                                                    </div>
                                                </ng-container>
                                                <ng-template #useExternalUrl>
                                                    <div class="input-txt-below">
                                                        <button class="button font-bold btn-info is-long"
                                                            data-testid="external-url-barcode" [disabled]="disabledScan"
                                                            (click)="navigateExternalURL($event)">
                                                            ไปยังช่องทางพาร์ทเนอร์
                                                        </button>
                                                        <!-- <a #navigateDeeplinkExternalUrl hidden
                                                        [href]="couponDetail.externalUrlDestination | safe : 'url'"
                                                        data-testid="deeplink-to-partner">
                                                    </a> -->
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div><!-- tab__content2 -->
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="couponDetail.isUseButtonDisplay && !disabledScan"
                                class="has-text-centered pd-used-coupon bg-white">
                                <div class="text-confirm-used">กดยืนยันเมื่อใช้คูปองแล้ว</div>
                                <button class="font-bold btn-usedcoupon " (click)="confirmRedeem();"
                                    data-testid="btn-confirm-id"
                                    id="CouponCodeScreen_ConfirmUse">ยืนยันว่าใช้คูปองแล้ว</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer" [class.footer-android-device]="isAndroid"
            *ngIf="couponDetail.isUseButtonDisplay && disabledScan">
            <button class="button is-primary font-bold btn-qrcode" (click)="navigateRedeemConfirmedBack()"
                data-testid="navigate-back-button">กลับหน้าคูปอง</button>
        </div>

        <div #toastPopup class="toast-noti bg-green is-hidden" data-testid="toast-popup">
            <table>
                <tr>
                    <td><img class="mr-2" src="/assets/icon/Icon-checked.png" width="25"></td>
                    <td><span>คัดลอกโค้ดคูปองสำเร็จ</span></td>
                </tr>
            </table>
        </div>
    </div>
</div>