import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-animation-loading',
  templateUrl: './animation-loading.component.html',
  styleUrls: ['./animation-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimationLoadingComponent implements OnInit {
  @Input() couponloading: boolean | null;
  @Input() loadingAnimation: boolean | null;
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }

}