<div id="not-found-content" class="bg-special">
    <div [class]="isAndroid ? ' android-device' : ''">
        <div class="bg-blue">
            <div class="container margin-top">
                <div class="has-text-centered">
                    <img class="coupon-empty-img" src="../../../assets/images/coupon_empty.png">
                    <p class="font-bold">
                        ไม่พบคูปองของคุณ
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="couponChannel==CouponChannelEnum.PT" class="box-bottom-action">
            <a [href]="callbackUrl | safe: 'url'" data-testid="link-back-icon"
                class="button btn-blue is-medium is-fullwidth font-bold has-text-weight-semibold">
                ตกลง
            </a>
        </div>
    </div>
</div>