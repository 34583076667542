<div>
  <div *ngIf="modalObj.templateName === 'registered' " class="top-img" >
    <img class="card-img mb-4" src="/assets/icon/ic_pay_scan.png"> 
  </div>
  <section class="modal-card-body modal-card-confirm" [class.modal-card-registered]="modalObj.templateName === 'registered'">
    <h2 class="has-text-centered text-title-confirm" [innerHTML]="modalObj.title"></h2>
    <div class="text-desc-confirm has-text-centered" [innerHTML]="modalObj.detail" *ngIf="modalObj.detail">
    </div>

    <ng-container *ngIf="modalObj.isFullWidthBtn; else notFullWidthBtn">
      <ng-container *ngIf="modalObj.templateName !== 'registerMultipleSOF' else displayTemplateRegisterMultipltSOF">
        <!-- original -->
        <div class="is-mobile row-button" *ngIf="isMultiButton && !modalObj.deeplinkCallbackUrl">
          <button class="button btn-blue is-medium is-fullwidth mb-4" data-testid="action-button" 
            (click)="clickAction(modalObj.button[1]);">{{modalObj.button[1].name}}</button>
          <button class="button btn-blue-outline is-medium is-fullwidth" data-testid="close-button" 
            (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
        </div>

        <!-- change button -> a.href -->
        <div class="is-mobile row-button" *ngIf="isMultiButton && modalObj.deeplinkCallbackUrl">
          <a class="button btn-blue is-medium is-fullwidth mb-4" data-testid="action-external-button"
            [href]="modalObj.deeplinkCallbackUrl | safe : 'url'"
            (click)="closeModal(modalObj.button[1]);">{{modalObj.button[1].name}}</a>
          <button class="button btn-blue-outline is-medium is-fullwidth" data-testid="close-button" 
            (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
        </div>
      </ng-container>

      <!-- register PTP and CASA -->
      <!-- Support PT Old version !! Remove deeplink next phase -->
      <ng-template #displayTemplateRegisterMultipltSOF>
        <div class="is-mobile row-button">
          <a class="button btn-blue is-medium is-fullwidth mb-4" data-testid="action-button" 
            [href]="modalObj.deeplinkCallbackUrl! | safe : 'url'"
            (click)="clickAction(modalObj.button[1]);">{{modalObj.button[1].name}}</a>
          <button class="button btn-blue is-medium is-fullwidth mb-4" data-testid="action-button" 
            (click)="clickAction(modalObj.button[2]);">{{modalObj.button[2].name}}</button>
          <button class="button btn-blue-outline is-medium is-fullwidth" data-testid="close-button" 
            (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
        </div>
      </ng-template>

    </ng-container>

    <ng-template #notFullWidthBtn>
      <!-- original -->
      <div class="columns is-mobile row-button" *ngIf="isMultiButton && !modalObj.deeplinkCallbackUrl">
        <div class="column btn-left">
          <button class="button btn-blue-outline is-medium is-fullwidth" data-testid="close-button" 
            (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
        </div>
        <div class="column">
          <button class="button btn-blue is-medium is-fullwidth" data-testid="action-button"
            (click)="clickAction(modalObj.button[1]);">{{modalObj.button[1].name}}</button>
        </div>
      </div>

      <!-- change button -> a.href -->
      <div class="columns is-mobile row-button" *ngIf="isMultiButton && modalObj.deeplinkCallbackUrl">
        <div class="column btn-left">
          <button class="button btn-blue-outline is-medium is-fullwidth" data-testid="close-button"
            (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
        </div>
        <div class="column">
          <a class="button btn-blue is-medium is-fullwidth" data-testid="action-external-button"
            [href]="modalObj.deeplinkCallbackUrl | safe : 'url'"
            (click)="closeModal(modalObj.button[1]);">{{modalObj.button[1].name}}</a>
        </div>
      </div>
    </ng-template>

    <div class="columns is-mobile row-button" *ngIf="!isMultiButton">
      <div class="column">
        <ng-container *ngIf="modalObj.templateName !== 'registerSOFPTP' else displayTemplateRegisterSOFPTP">
          <ng-container *ngIf="modalObj.deeplinkCallbackUrl else callBackMissionBaseOnNEXT">
            <a class="button btn-blue is-medium is-fullwidth" data-testid="action-external-button"
              [href]="modalObj.deeplinkCallbackUrl | safe : 'url'"
              (click)="closeModal(modalObj.button[0]);">{{modalObj.button[0].name}}</a>
          </ng-container>
  
          <ng-template #callBackMissionBaseOnNEXT>
            <ng-container
                *ngIf="modalObj.deeplinkCallbackUrl == '' && callbackDestination && couponChannel === CouponChannel.NEXT; else notDeeplink">
                <button class="button btn-blue is-medium is-fullwidth" data-testid="action-button"
                  (click)="closeModal(modalObj.button[0]);navigateBackWithJsbridgeOnNext();">{{modalObj.button[0].name}}</button>
            </ng-container>
          </ng-template>
  
          <ng-template #notDeeplink>
            <button class="button btn-blue is-medium is-fullwidth" data-testid="action-button"
              (click)="clickAction(modalObj.button[0]);">{{modalObj.button[0].name}}</button>
          </ng-template>
        </ng-container>

        <!-- register PTP support JSBridge and deeplink !! Remove deeplink next phase-->
        <ng-template #displayTemplateRegisterSOFPTP>
          <a class="button btn-blue is-medium is-fullwidth" data-testid="action-button"
            [href]="modalObj.deeplinkCallbackUrl! | safe : 'url'"
            (click)="clickAction(modalObj.button[0]);">{{modalObj.button[0].name}}</a>
        </ng-template>
      </div>
    </div>
  </section>
</div>