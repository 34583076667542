import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageRoute } from './configs/route-config';
import { CouponDetailComponent } from './pages/coupon-detail/coupon-detail.component';
import { CouponlistComponent } from './pages/couponlist/couponlist.component';
import { HomeComponent } from './pages/home/home.component';
import { MycouponComponent } from './pages/mycoupon/mycoupon.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { OfflineInternetComponent } from './pages/offline-internet/offline-internet.component';
import { QrcodeComponent } from './pages/qrcode/qrcode.component';
import { SkeletonLoaderComponent } from './pages/skeleton-loader/skeleton-loader.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { UnderMaintenanceComponent } from './pages/under-maintenance/under-maintenance.component';
import { SearchCouponComponent } from './pages/search-coupon/search-coupon.component';
import { ApplyCouponComponent } from './pages/apply-coupon/apply-coupon.component';
const routes: Routes = [
  { path: '', redirectTo: PageRoute.HOME, pathMatch: 'full' },
  { path: PageRoute.HOME, component: HomeComponent },
  { path: PageRoute.MY_COUPON, component: MycouponComponent },
  { path: PageRoute.QR_CODE, component: QrcodeComponent },
  { path: PageRoute.COUPON_DETAIL, component: CouponDetailComponent },
  { path: PageRoute.UNDER_MAINTENANCE, component: UnderMaintenanceComponent },
  { path: PageRoute.COUPON_LIST, component: CouponlistComponent},
  { path: PageRoute.UNAUTHORIZED, component: UnauthorizedComponent },
  { path: PageRoute.OFFLINE, component: OfflineInternetComponent },
  { path: PageRoute.NOT_FOUND, component: NotfoundComponent },
  { path: PageRoute.SKELETION, component: SkeletonLoaderComponent },
  { path: PageRoute.SEARCH_COUPON, component: SearchCouponComponent },
  { path: PageRoute.APPLY_COUPON, component: ApplyCouponComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
