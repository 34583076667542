import { ICouponListModel, ICouponModel, ICouponResponse } from "../model/coupon-model.model";

export function getPeriodTime(today: Date, dateTime: string) {
  const timeToday: any = today.toTimeString();
  const realTimeToday = today.getTime();
  const couponDatetime = new Date(dateTime);
  const couponStartHours = couponDatetime.getHours();
  const couponStartMinutes = couponDatetime.getMinutes();
  const couponStartSeconds = couponDatetime.getSeconds();

  let timeResult = 0;

  if (timeToday < couponDatetime.toTimeString()) {
    const realTimeCouponDatetime = new Date().setHours(couponStartHours, couponStartMinutes, couponStartSeconds);
    timeResult = realTimeToday - realTimeCouponDatetime;
  } else {
    const setRealTimeCouponDate = new Date(today.getTime() + (1000 * 60 * 60 * 24));
    const realTimeCouponDatetime = setRealTimeCouponDate.setHours(couponStartHours, couponStartMinutes, couponStartSeconds);
    timeResult = realTimeCouponDatetime - realTimeToday;
  }

  return Math.abs(timeResult);
}

export function getExpiredTime(today: Date, dateTime: string) {
  const realTimeToday = today.getTime();
  const couponDatetime = new Date(dateTime);
  const timeResult = couponDatetime.getTime() - realTimeToday;
  const result = (timeResult / 1000);
  return (result > 0 && result <= 3600) ? Math.ceil(result) : 0;
}

export function getCouponRepetitiveTime(data: ICouponListModel[] | ICouponModel[]) {
  const today = new Date();
  const secondsToday = (today.getHours() * 60 + today.getMinutes()) * 60 + today.getSeconds();
  let averageNew = 0;

  data.forEach((coupon: ICouponListModel | ICouponModel) => {
    const timeResult = coupon.repetitiveTime - secondsToday;
    
    if (averageNew === 0) {
      averageNew = timeResult > 0 ? timeResult : 0;
    } else {
      averageNew = timeResult > 0 ? (timeResult > averageNew ? averageNew : timeResult) : averageNew;
    }
  });

  return averageNew
}