<div class="columns is-gapless is-mobile">
    <div class="column">
        <div class="item">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
            'height': '279px', 
            'position': 'absolute',
            'left': '0',   
            'top': '0',
            'border-radius': '12px',
            'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '24px', 
                'width': '148px',
                'margin-top':'279px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '24px',
                'margin-top':'-1px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '24px',
                'margin-top':'-9px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px', 
                'width': '148px',
                'margin-top':'-1px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px', 
                'width': '148px',
                'margin-top':'7px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px',
                'margin-top':'-1px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px',
                'margin-top':'-9px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px', 
                'width': '148px',
                'margin-top':'7px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px',
                'margin-top':'-1px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
        <div class="item">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                'height': '16px',
                'margin-top':'-9px',
                'border-radius': '6px',
                'background': 'var(--white-gray-color)'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>



