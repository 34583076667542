import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CouponService } from '../../../shared/coupon.service';
import { UtilService } from '../../service/util.service';
import { RootStoreService } from '../../root-store/root-store.service';
import { Subject, filter, first, take, takeUntil } from 'rxjs';
import { CouponCardDialogTemplate, CouponCardTemplate } from '../../configs/coupon-card-config';
import { PageRoute } from '../../configs/route-config';
import { FormApplyCouponComponent } from 'src/app/components/form-apply-coupon/form-apply-coupon.component';
import { Router } from '@angular/router';
import { CountdownEvent } from 'ngx-countdown';
import { IApplyCouponDetail, ICouponInfo, IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { ApplyCouponActionType, CouponActionType, CouponStatus, PeriodStatus, UseActionTypeModalTemplate } from 'src/app/model/coupon-enum.model';
import { ModalService } from 'src/app/service/modal.service';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { ModalTemplateComponent } from 'src/app/components/modal-template/modal-template.component';
import { ResponseCode, modalWording } from 'src/app/configs/app-config';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { handleDisplayPermissionUse, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { calculateTimeDiff } from 'src/app/helpers/date-time.helper';
import { BottomContentSliderDialogComponent } from 'src/app/components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { getPeriodTime } from 'src/app/helpers/period-time.helper';
import { ApplyCouponCardHorizontalComponent } from 'src/app/components/apply-coupon/apply-coupon-card-horizontal/apply-coupon-card-horizontal.component';

@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply-coupon.component.html',
  styleUrls: ['./apply-coupon.component.scss']
})
export class ApplyCouponComponent implements OnInit {
  CouponStatus = CouponStatus;
  ApplyCouponActionType = ApplyCouponActionType;
  couponTemplate = CouponCardTemplate.COUPON_HOME;
  applyCouponTemplate = CouponCardTemplate.APPLY_COUPON;
  @ViewChild("formApplyCoupon") formSearchCoupon: FormApplyCouponComponent;
  @ViewChild("couponCard") couponCards: ApplyCouponCardHorizontalComponent;
  @ViewChild('toastPopup') toastPopup: any;
  stepRouteConfig$ = this.couponService.stepRouteConfig$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  isLoadingCollectCoupon$ = this.couponService.isLoadingCollectCoupon$;
  isAndroid: boolean = this.utilService.isMobileDevice();
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl;
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymemtCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  withHeader: boolean = this.rootStoreService.jwtSession!.withHeader ?? true;
  entryPoint: string = this.rootStoreService.jwtSession!.entryPoint;
  isloadingApplyCoupon = false;
  applyCouponKeyword: string = '';
  isStartSearch = false;
  applyCouponStatus: string;
  applyCouponResult: IApplyCouponDetail[] = [];
  isOpenModal: boolean = false;
  couponIdOverlay = '';
  countTimeCoupon = 0;
  userSOF: string[] = [];
  private _today: Date;
  private _countTimePeriod = 0;
  private _countTimeAllowed = 0;
  private _destroy = new Subject<void>();

  constructor(
    private utilService: UtilService,
    private rootStoreService: RootStoreService,
    private couponService: CouponService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private router: Router,
  ) {
    this.utilService.startLoading(PageRoute.APPLY_COUPON);
  }

  ngOnInit(): void {

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.paymemtCallbackUrl = jwtSession.paymentCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.entryPoint = jwtSession.entryPoint;
      this.withHeader = jwtSession.withHeader ?? true;
      this.userSOF = jwtSession.userSOF ?? [];
    })

    this.couponService.saveStepRouteConfig([PageRoute.PATH_APPLY_COUPON]);
    this.couponService.saveCurrentPage(PageRoute.PATH_APPLY_COUPON);

    this.couponService.applyCouponKeyword$.pipe(filter(v => !isEmpty(v)), take(1)).subscribe((v) => this.applyCouponKeyword = v);

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      if (!isEmpty(this.applyCouponKeyword)) {
        this.isStartSearch = true;
        this.isloadingApplyCoupon = true;
        this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
        this.changeDetectorRef.detectChanges();
      }
    });

    this.couponService.applyCouponResult$
      .pipe(filter((v) => Boolean(v.status)), takeUntil(this._destroy))
      .subscribe((response) => {

        // console.log("res : ", response);

        this.applyCouponStatus = response.status!;

        if (response.status === ApplyCouponActionType.UNMATCH || !response.coupon) {
          this.isloadingApplyCoupon = false;
          this.applyCouponResult = [];
          return;
        }

        this.applyCouponResult = [cloneDeep(response.coupon)];

        this._today = new Date();
        this._countTimePeriod = this.getCouponPeriodTimeCountNearCurrentTime();
        this._countTimeAllowed = this.getCouponAllowedTimeCountNearCurrentTime();

        // Active Coupon
        if (this._countTimePeriod > 0 && this._countTimeAllowed > 0)
          this.countTimeCoupon = this._countTimeAllowed > this._countTimePeriod ? this._countTimePeriod : this._countTimeAllowed;
        else
          this.countTimeCoupon = this._countTimeAllowed === 0 ? this._countTimePeriod : this._countTimeAllowed;

        if (!isEmpty(this.couponIdOverlay)) {

          const filterCouponOverlay = this.applyCouponResult.find(coupon => coupon.couponId === this.couponIdOverlay);
          if (filterCouponOverlay) this.modalService.couponData$.next(filterCouponOverlay);
          else this.modalService.couponData$.next(null);
        }

        this.isloadingApplyCoupon = false;
        this.changeDetectorRef.detectChanges();

        if (response.status === ApplyCouponActionType.COLLECT) {
          this.couponService.isLoadingCollectedSccuess$.next(true);
        }
      })

    this.couponService.isLoadingCollectedSccuess$
      .pipe(filter((v) => Boolean(v)), takeUntil(this._destroy))
      .subscribe(() => {
        this.couponService.isLoadingCollectCoupon$.next(false);
        this.toastPopup.nativeElement.classList.remove("is-hidden");
        this.toastPopup.nativeElement.classList.add("fade-out-text");
        setTimeout(() => {
          this.toastPopup.nativeElement.classList.add("is-hidden");
          this.toastPopup.nativeElement.classList.remove("fade-out-text");
        }, 3000);
      });
  }

  private getPeriodTimeCouponStartRedemptionDateTime(periodCoupons: IApplyCouponDetail[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: IApplyCouponDetail) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfos[0].couponStartRedemptionDatetime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndRedemptionDateTime(periodCoupons: IApplyCouponDetail[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: IApplyCouponDetail) => {
      const timeResult = getPeriodTime(this._today, coupon.couponInfos[0].couponEndRedemptionDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  getCouponPeriodTimeCountNearCurrentTime() {

    // Support case multiple status :: Collected and Redeem
    const collectedCouponsPeriod = this.applyCouponResult.filter((coupon: IApplyCouponDetail) => coupon.couponInfos.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.PERIOD && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponStartRedemptionDateTime(collectedCouponsPeriod);
    return averageCollected;
  }

  getCouponAllowedTimeCountNearCurrentTime() {
    // Support case multiple :: Collected and Redeem
    const collectedCouponsAllowed = this.applyCouponResult.filter((coupon: IApplyCouponDetail) => coupon.couponInfos.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.ALLOWED && couponInfo.status === CouponStatus.COLLECTED));
    const averageCollected = this.getPeriodTimeCouponEndRedemptionDateTime(collectedCouponsAllowed);

    return averageCollected;

  }

  removeStepRouteConfig() {
    this.couponService.clearApplyCoupon();
    this.couponService.saveSelectedCategoryIdValue('0');
    this.couponService.clearSelectedCouponValue();
    this.stepRouteConfig$.pipe(take(1)).subscribe((route) => {
      if (route.length > 1) this.couponService.removeStepRouteConfig(PageRoute.PATH_APPLY_COUPON);
    });
    // Support PT Old version !! Remove next phase
    localStorage.removeItem("applyCouponKeyword");
  }

  setGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.APPY_COUPON,
      eventCategory: AnalyticCategory.APPY_COUPON,
      eventLabel: label,
      eventAction: AnalyticAction.CLICK,
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? ''
    }

    this.couponGAService.trackActionGATag(gaObj);
  }

  setToLoadFech() {
    this.isloadingApplyCoupon = true;
    this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
    this.changeDetectorRef.detectChanges();
  }

  displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.couponService.clearPermissionUseCoupon();
        this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    if (this.applyCouponKeyword.length == 0) {
      const tmpElement = document.createElement('input');
      tmpElement.style.width = '0';
      tmpElement.style.height = '0';
      tmpElement.style.margin = '0';
      tmpElement.style.padding = '0';
      tmpElement.style.border = '0';
      tmpElement.style.opacity = '0';
      document.body.appendChild(tmpElement);
      tmpElement.focus({ preventScroll: true });
      setTimeout(() => {
        this.formSearchCoupon.inputSearch.nativeElement.focus({ preventScroll: true });
        document.body.removeChild(tmpElement);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.isLoadingCollectedSccuess$.next(false);
    this.couponService.clearApplyCouponResult();
  }

  onLoadApplyCoupon(keyword: string) {
    if (keyword.length >= 1) {
      this.isStartSearch = true;
      this.isloadingApplyCoupon = true;
      this.applyCouponKeyword = keyword;
      this.couponService.loadFetchApplyCoupon(keyword);
      this.couponService.clearSelectedCouponValue();
    }
  }

  navigateToCouponDetail(coupon: IApplyCouponDetail, isSetGA: boolean = false, index: number = 0) {
    if (isSetGA) {
      this.setGATag(`${AnalyticCategory.APPY_COUPON}_CouponDetails`, coupon.campaignCode);

      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: AnalyticCategory.APPY_COUPON,
          label: `${AnalyticCategory.APPY_COUPON}_CouponDetails`,
          firebase_screen: AnalyticCategory.APPY_COUPON,
        },
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode!,
          }
        ]
      );
    }

    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      order: coupon.couponInfos[index].receivedOrder,
      couponStatus: coupon.couponInfos[index].status
    }
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_APPLY_COUPON);
    this.router.navigate([PageRoute.PATH_COUPON_DETAIL], { queryParams: { couponId: coupon.couponId, order: coupon.couponInfos[index].receivedOrder }, replaceUrl: true });
  }

  useCoupon(element: ElementRef<any>, coupon: IApplyCouponDetail, index: number = 0) {
    this.setGATag(`${AnalyticCategory.APPY_COUPON}_Use`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.APPY_COUPON,
        label: `${AnalyticCategory.APPY_COUPON}_Use`,
        firebase_screen: AnalyticCategory.APPY_COUPON,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode!,
        }
      ]
    );

    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);
    const dateRedeemdiff = this.calculateTimeDiff(coupon.couponInfos[0].couponEndRedemptionDateTime);

    if (dateRedeemdiff < 0 && coupon.couponInfos[index].status !== CouponStatus.REDEEMED) {
      // Error case
      const modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode
            }
          ]
        },
        button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: "SeeOthers" }],
      };

      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
        return;
      });

    } else {

      // Success case
      this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_APPLY_COUPON);
      const queryParamsCoupon = cloneDeep(selectedCoupon);
      delete queryParamsCoupon['couponStatus'];
      if (coupon.couponInfos[index].status == CouponStatus.REDEEMED) {

        this.setGATag(`${AnalyticCategory.APPY_COUPON}_Code`, coupon.campaignCode);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.APPY_COUPON,
            label: `${AnalyticCategory.APPY_COUPON}_Code`,
            firebase_screen: AnalyticCategory.APPY_COUPON,
          },
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!,
            }
          ]
        );
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
      } else {
        // Check in period time
        this.couponService.loadPermissionUseCoupon(selectedCoupon.couponId, selectedCoupon.order!);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response?.permissionUseCoupon) {
              this.displayPermissionError(coupon.campaignCode, response);
              return;
            }

            let txtDetail = "";
            if (coupon.hasCountdownTimer && coupon.couponInfos[index].countdownTimeInSeconds! > 0) {
              let countdownTimer = (coupon.couponInfos[index].countdownTimeInSeconds!) / 60;
              txtDetail = modalWording.afterConfirmCouponHaveTime + countdownTimer + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode
                  }
                ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.couponCards.disableActionCard(element);
              this.couponService.clearPermissionUseCoupon();

              if (result == "confirm") {
                this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: queryParamsCoupon, replaceUrl: true });
              }
            });
          });
      }
    }
  }

  openOverlay(coupon: IApplyCouponDetail, isInChannel: boolean) {
    this.setGATag(`${AnalyticCategory.APPY_COUPON}_MultipleCoupons`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.APPY_COUPON,
        label: `${AnalyticCategory.APPY_COUPON}_MultipleCoupons`,
        firebase_screen: AnalyticCategory.APPY_COUPON,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode,
        }
      ]
    );

    this.couponIdOverlay = coupon.couponId;
    const filterRedeemStatus = coupon.couponInfos.some((couponInfo) => couponInfo.status === CouponStatus.REDEEMED);
    const periodStatus = filterRedeemStatus ? PeriodStatus.ALLOWED : coupon.couponInfos[0].periodStatus;
    const titleDialog = this.modalService.getHeaderDialogButtomContnet(periodStatus, coupon.couponInfos[0].status as CouponStatus, isInChannel);

    const couponData = {
      coupon: cloneDeep(coupon),
      couponChannel: this.couponChannel,
      entryPoint: this.entryPoint,
      titleDialog: titleDialog,
      isMultipleStatus: filterRedeemStatus,
      isInChannel: isInChannel,
      couponKeyword: this.applyCouponKeyword,
      template: CouponCardDialogTemplate.APPLY_COUPON_BOTTOM_DIALOG,
      categoryEvent: AnalyticCategory.APPY_COUPON_MULTIPLE,
      stepRouteConfig$: this.stepRouteConfig$,
      userSOF: this.userSOF
    }
    this.modalService.openDialogButtomContent(BottomContentSliderDialogComponent, { data: couponData, position: { bottom: '0px' } }).pipe(take(1)).subscribe((response) => {
      this.couponIdOverlay = '';
      if (response) {
        switch (response.path) {
          case PageRoute.COUPON_DETAIL: {
            this.navigateToCouponDetail(response.coupon);
            break;
          }
          case PageRoute.QR_CODE: {
            this.useCouponFormDialogBottomContent(response.coupon, response.actionType);
            break;
          }
          case PageRoute.SCAN_COPAY: {
            const isUseCoupon = response?.isUseCoupon;
            this.setStepRouteFlag(coupon.couponId, isUseCoupon);
            // Support PT Old version !! Remove next phase
            this.selectedCoupon$.pipe(take(1)).subscribe((selectedCoupon) => {
              localStorage.setItem('applyCouponKeyword', this.applyCouponKeyword);
              localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
            });
            break;
          }
          case PageRoute.EXTERNAL_URL: {
            this.navigateExternalURL(response.coupon, response.externalUrl);
            break;
          }
          case PageRoute.APPLY_COUPON: {
            this.setToLoadFech();
            break;
          }
        }
      }
    });
  }

  useCouponFormDialogBottomContent(coupon: IApplyCouponDetail, actionType: UseActionTypeModalTemplate, index: number = 0,) {
    const selectedCoupon: SelectedCoupon = this.mappingSelectedCoupon(coupon);

    switch (actionType) {
      case UseActionTypeModalTemplate.EXPIRED: {
        const modalObj: IModalTemplate = {
          title: modalWording.thisCouponExpired,
          detail: modalWording.chooseAnotherCoupon,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_EXPIRED,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ]
          },
          button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }],
        };
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
          this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
          return;
        });

        break;
      }
      case UseActionTypeModalTemplate.REDEEMED: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_APPLY_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
        break;
      }
      case UseActionTypeModalTemplate.SUCCESS: {
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_APPLY_COUPON);
        const queryParamsCoupon = cloneDeep(selectedCoupon);
        delete queryParamsCoupon['couponStatus'];
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
      }
    }
  }

  navigateExternalURL(coupon: any, externalUrl: string = "", index: number = 0) {
    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.couponInfos[index].receivedOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response?.permissionUseCoupon) {

          this.displayPermissionError(coupon.campaignCode, response, true);
          return;
        }
        this.setGATag(`${AnalyticCategory.APPY_COUPON}_Use`, coupon.campaignCode);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.APPY_COUPON,
            label: `${AnalyticCategory.APPY_COUPON}_ExternalURL`,
            firebase_screen: AnalyticCategory.APPY_COUPON,
          },
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!
            }
          ]
        );
        const modalObj: IModalTemplate = {
          title: modalWording.confirmNavigateExternalUrl,
          detail: modalWording.exitFromAppGoToParner,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!
              }
            ]
          },
          button: [
            { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
            { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
          ],
        };

        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            window.location.href = this.utilService.safeURL(externalUrl);
          }
        });

        this.couponService.clearPermissionUseCoupon();
      });
  }

  onActionUseCouponCopay(eventLabel: string, campaignCode: string) {
    this.setGATag(`${AnalyticCategory.APPY_COUPON}_${eventLabel}`, campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.APPY_COUPON,
        label: `${AnalyticCategory.APPY_COUPON}_${eventLabel}`,
        firebase_screen: AnalyticCategory.APPY_COUPON,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: campaignCode,
        }
      ]
    );
  }

  onActionUseCouponPaymentTopup(eventLabel: string, campaignCode: string, destination: string) {
    this.setGATag(`${AnalyticCategory.APPY_COUPON}_${eventLabel}`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.APPY_COUPON,
        label: `${AnalyticCategory.APPY_COUPON}_${eventLabel}_${destination}`,
        firebase_screen: AnalyticCategory.APPY_COUPON,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: campaignCode!
        }
      ]
    );
  }

  onActionSelectedCoupon(ev: IApplyCouponDetail) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.setStepRouteFlag(selectedCoupon.couponId);
    // Support PT Old version !! Remove next phase
    localStorage.setItem("selectedCoupon", JSON.stringify(selectedCoupon));
    localStorage.setItem("applyCouponKeyword", this.applyCouponKeyword);
  }

  setStepRouteFlag(couponId: string, isUseCoupon: boolean = false) {
    this.utilService.removeTokenSession();
    localStorage.setItem("navigateExternalPage", couponId);
    if (isUseCoupon) {
      this.couponService.removeStepRouteConfig(PageRoute.PATH_APPLY_COUPON);
      this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_LIST);
    }
  }

  handleCountdownEvent(e: CountdownEvent, index: number, order: number) {
    if (e.action === 'done') {
      const expiredCoupon = this.applyCouponResult[index];
      const expiredCouponOrder = this.applyCouponResult[index].couponInfos[order].receivedOrder
      this.applyCouponResult[index].couponInfos[order].status = CouponStatus.REDEEM_CONFIRMED;
      const redeemCoupon = this.applyCouponResult[index].couponInfos.filter((couponInfo: ICouponInfo) => couponInfo.status !== CouponStatus.REDEEM_CONFIRMED);
      if (redeemCoupon.length > 0) {
        this.applyCouponResult[index].numberOfCoupons = redeemCoupon.length;
        this.applyCouponResult[index].couponInfos = redeemCoupon;
      }

      this.modalService.handleCouponCountdown$.next({
        couponId: expiredCoupon.couponId,
        couponOrder: expiredCouponOrder
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.countTimeCoupon = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchApplyCouponDetail(this.applyCouponResult[0].couponId, ApplyCouponActionType.DISPLAY);
    }
  }

  private mappingSelectedCoupon(coupon: IApplyCouponDetail, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.couponInfos[index].couponCode ?? '',
      hasCountdownTimer: coupon.hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.couponInfos[index].countdownTimeInSeconds ?? 0,
      order: coupon.couponInfos[index].receivedOrder,
      couponStatus: coupon.couponInfos[index].status
    };

    return selectedCoupon;
  }
}
