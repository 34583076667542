import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, shareReplay, Subject, tap } from 'rxjs';
import { ICouponResponse, IFetchCouponData, IFetchCouponDetail, IFetchMyCouponData, IFetchCouponList, IFetchCollectCoupon, IFetchRedeemCoupon, IFetchConfirmRedeemCoupon, ICouponListModel, IFetchReseverCouponOrder, IFetchCompleteCoachMark, ICouponModel, ICustomerTutorial, ICollectApplyCoupon, IApplyCouponDetail } from 'src/app/model/coupon-model.model';
import { AppState, SelectedCoupon } from 'src/app/root-store/root-store.model';
import { environment } from '../environments/environment';
import { Actions } from '@ngrx/effects';

import * as CouponActions from '../app/store/coupon.actions';
import * as CouponSelectors from '../app/store/coupon.selectors'

import * as RootActions from '../app/root-store/root-actions';
import * as RootSelectors from '../app/root-store/root-selectors';
import { Mode } from 'src/app/configs/app-config';
import { transformIFetchApplyCouponDetail, transformIFetchCouponHomeData, transformIFetchCouponList, transformIFetchMyCouponData } from 'src/app/helpers/transform-data.helper';
import { ApplyCouponActionType } from 'src/app/model/coupon-enum.model';
@Injectable({ providedIn: 'root' })
export class CouponService {
  api_url = environment.api_url;
  PT_CALLBACK_URL = environment.callbackURL;
  /* Core */
  acessToken$ = this.store.select(CouponSelectors.selectAccressToken);
  accessToken = '';
  isInitialised$ = this.store.select(RootSelectors.selectIsInitialised);
  isLoading$ = this.store.select(CouponSelectors.selectIsLoading);
  couponId$ = this.store.select(RootSelectors.selectCouponId);
  selectedCoupon$ = this.store.select(RootSelectors.selectedCoupon);
  callbackUrl$ = this.store.select(RootSelectors.selectCallbackUrl);
  previousUrl$ = this.store.select(RootSelectors.selectPreviousUrl);
  currentPage$ = this.store.select(RootSelectors.selectCurrentPage);
  stepRouteConfig$ = this.store.select(RootSelectors.selectStepRouteConfig);
  /* End Core */

  /* Coupon Home */
  coupons$ = this.store.select(CouponSelectors.selectCoupons);
  customerTutorial$ = this.store.select(CouponSelectors.selectCustomerTutorial);
  /* End Coupon Home */

  /* Coupon Detail */
  couponDetail$ = this.store.select(CouponSelectors.selectCouponDetail);
  /* End Coupon Detail */

  /* My Coupon */
  myCoupons$ = this.store.select(CouponSelectors.selectMyCoupons);
  /* End My Coupon */

  /* Coupon Chooser */
  couponList$ = this.store.select(CouponSelectors.selectCouponList);
  /* End Coupon Chooser */

  /* Use Coupon */
  redeemCoupon$ = this.store.select(CouponSelectors.selectRedeemCoupon);
  reserveCouponOrder$ = this.store.select(CouponSelectors.selectReserveCouponOrder);
  permissionUseCoupon$ = this.store.select(CouponSelectors.selectPermissionUseCoupon);
  /* End Use Coupon */

  /* Category */
  selectedCategoryId$ = this.store.select(RootSelectors.selectCategoryId);
  couponCategory$ = this.store.select(CouponSelectors.selectCouponCategory);
  isLoadingAnimation$ = new Subject<boolean>();
  /* End Category */

  /* Coupon Search */
  searchKeyword$ = this.store.select(CouponSelectors.selectSearchKeyword);
  searchDefaultKeyword$ = this.store.select(CouponSelectors.selectDefaultSearchKeyword);
  searchDefaultKeywordIndex$ = this.store.select(CouponSelectors.selectDefaultSearchKeywordIndex);
  couponSearchResult$ = this.store.select(CouponSelectors.selectCouponSearchResult);
  /* End Coupon Search */

  /* Collect Coupon */
  collectCoupon$ = this.store.select(CouponSelectors.selectCollectCoupon);
  couponCollectedData$ = new Subject<any>();
  isLoadingCollectCoupon$ = new Subject<boolean>();
  isLoadingCollectedSccuess$ = new Subject<any>();
  /* End Collect Coupon */

  /* Apply Coupon */
  applyCouponKeyword$ = this.store.select(CouponSelectors.selectApplyCouponKeyword);
  applyCouponResult$ = this.store.select(CouponSelectors.selectApplyCouponResult);
  /* End Apply Coupon */

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private actions: Actions) {
    this.acessToken$.subscribe((accessToken) => {
      this.accessToken = accessToken;
    });
  }

  loadFetchCoupons(isLoading: boolean = false, isLoadingCollectedSuccess: boolean = false) {
    this.store.dispatch(CouponActions.loadCoupons({ isLoading: isLoading, isLoadingCollectedSuccess: isLoadingCollectedSuccess }));
  }

  loadFetchCouponDetail(isLoading: boolean = false, isQRCode: boolean = false, isLoadingCollectedSccuess: boolean = false) {
    this.store.dispatch(CouponActions.loadCouponDetail({ isLoading: isLoading, isQRCode: isQRCode, isLoadingCollectedSccuess: isLoadingCollectedSccuess }));
  }

  loadFetchMyCoupon(isLoading: boolean = false) {
    this.store.dispatch(CouponActions.loadMyCoupons({ isLoading: isLoading }));
  }

  loadFetchCouponList(amount: string, isLoading: boolean, couponId?: string) {
    this.store.dispatch(CouponActions.loadCouponList({ amount: amount, isLoading: isLoading, couponId: couponId }));
  }

  loadFetchCollectCoupon(couponId: string, couponOrder?: number) {
    this.store.dispatch(CouponActions.loadCollectCoupon({ couponId: couponId, couponOrder: couponOrder }));
  }

  clearCollectCoupon() {
    this.store.dispatch(CouponActions.clearCollectCoupon());
  }

  clearRedeemCoupon() {
    this.store.dispatch(CouponActions.clearRedeemCoupon());
  }

  loadFetchRedeemCoupon() {
    this.store.dispatch(CouponActions.loadRedeemCoupon());
  }

  loadConfirmRedeemCoupon(couponOrder: number) {
    this.store.dispatch(CouponActions.loadConfirmRedeemCoupon({ couponOrder: couponOrder }));
  }

  savePreviousUrl(previousUrl: string) {
    this.store.dispatch(RootActions.savePreviousUrl({ previousurl: previousUrl }));
  }
  saveCurrentPage(currentPage: string) {
    this.store.dispatch(RootActions.saveCurrentPage({ currentPage: currentPage }));
  }

  saveSelectedCouponValue(selectedCoupon: SelectedCoupon, previousUrl: string) {
    this.store.dispatch(RootActions.saveSelectedCoupon({ selectedCoupon: selectedCoupon, previousurl: previousUrl }));
  }

  clearSelectedCouponValue() {
    this.store.dispatch(RootActions.clearSelectedCoupon());
  }

  saveStepRouteConfig(routesUrl: string[]) {
    this.store.dispatch(RootActions.saveStepRouteConfig({ routesUrl: routesUrl }));
  }

  removeStepRouteConfig(routeUrl: string) {
    this.store.dispatch(RootActions.removeStepRouteConfig({ routeUrl: routeUrl }));
  }

  clearStepRouteConfig() {
    this.store.dispatch(RootActions.clearStepRouteConfig());
  }

  clearCouponDetail() {
    this.store.dispatch(CouponActions.clearCouponDetail());
  }

  loadReserveCouponOrder(couponId: string, couponOrder: number, mode?: string) {
    this.store.dispatch(CouponActions.loadReserveCouponOrder({ couponId: couponId, couponOrder: couponOrder, mode: mode }));
  }

  clearReserveCouponOrder() {
    this.store.dispatch(CouponActions.clearReserveCouponOrder());
  }

  loadGetCustomerTutorialCoachMark() {
    this.store.dispatch(CouponActions.loadGetCustomerTutorialCoachMark());
  }

  loadUpdateCustomerTutorialCoachMark() {
    this.store.dispatch(CouponActions.loadUpdateCustomerTutorialCoachMark());
  }

  loadPermissionUseCoupon(couponId: string, couponOrder: number, checkStatus: boolean = false) {
    this.store.dispatch(CouponActions.loadPermissionUseCoupon({ couponId: couponId, couponOrder: couponOrder, checkStatus: checkStatus }));
  }

  clearPermissionUseCoupon() {
    this.store.dispatch(CouponActions.clearPermissionUseCoupon());
  }

  saveSelectedCategoryIdValue(selectCategoryId: string) {
    this.store.dispatch(RootActions.saveSelectedCategoryId({ selectedCategoryId: selectCategoryId }))
  }

  saveSearchKeywordValue(searchKeyword: string) {
    this.store.dispatch(CouponActions.saveSeachKeyword({ keyword: searchKeyword }))
  }

  saveSaveDefaultSeachKeywordIndex(index: number) {
    this.store.dispatch(CouponActions.saveDefaultSeachKeywordIndex({ defaultSearchKeywordIndex: index }))
  }

  loadFetchSearchCoupon(keyWord: string, isLoadingCollectedSuccess: boolean = false) {
    this.store.dispatch(CouponActions.loadSeachCoupon({ keyword: keyWord, isLoadingCollectedSuccess: isLoadingCollectedSuccess }));
  }

  clearSearchCoupon() {
    this.store.dispatch(CouponActions.clearSearchCoupon());
  }

  clearSearchCouponResult() {
    this.store.dispatch(CouponActions.clearSearchCouponResult());
  }

  loadFetchApplyCoupon(keyword: string) {
    this.store.dispatch(CouponActions.loadApplyCoupon({ keyword: keyword }));
  }

  loadFetchApplyCouponDetail(couponId: string, action: ApplyCouponActionType) {
    this.store.dispatch(CouponActions.loadApplyCouponDetail({ couponId: couponId, applyCouponStatus: action }));
  }

  saveApplyCouponKeyword(keyword: string) {
    this.store.dispatch(CouponActions.saveApplyCouponKeyword({ keyword: keyword }))
  }

  clearApplyCoupon() {
    this.store.dispatch(CouponActions.clearApplyCoupon());
  }

  clearApplyCouponResult() {
    this.store.dispatch(CouponActions.clearApplyCouponResult());
  }

  fetchCoupons(code: string, accessToken: string, webTokenId: string): Observable<ICouponResponse<IFetchCouponData>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId);

    return this.http.get<ICouponResponse<IFetchCouponData>>(this.api_url + '/api/v1/coupons', { headers: headers }).pipe(
      map((response: ICouponResponse<IFetchCouponData>) => {
        return transformIFetchCouponHomeData(response);
      }), shareReplay(1)
    );
  }

  fetchMyCoupons(code: string, accessToken: string, webTokenId: string): Observable<ICouponResponse<IFetchMyCouponData>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId);

    return this.http.get<ICouponResponse<IFetchMyCouponData>>(this.api_url + '/api/v1/customers/coupons', { headers: headers }).pipe(
      map((response: ICouponResponse<IFetchMyCouponData>) => {
        return transformIFetchMyCouponData(response)
      })
    );
  }

  fetchCouponDetail(code: string, accessToken: string, couponId: any, couponOrder: number, webTokenId: string, mode?: string, amount?: string, fromPage?: string): Observable<ICouponResponse<IFetchCouponDetail>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId)
      .set('Cache-Control', 'no-cache');

    return this.http.get<ICouponResponse<IFetchCouponDetail>>(this.api_url +
      `/api/v1/coupons/${couponId}?couponOrder=${couponOrder}&mode=${mode === Mode.CANCEL ? 'cancelCopayPayment' : ''}${amount ? `&amount=${amount}` : ''}${fromPage ? `&fromPage=${fromPage}` : ''}`,
      { headers: headers }).pipe(
        map((response: ICouponResponse<IFetchCouponDetail>) => { return response }), shareReplay(1)
      );
  }

  fetchCollectCoupon(acccessToken: string, couponId: string, webTokenId: string, couponOrder?: number): Observable<ICouponResponse<IFetchCollectCoupon>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.post<ICouponResponse<IFetchCollectCoupon>>(this.api_url + `/api/v1/coupons/collect/${couponId}`, {},
      {
        headers: headers,
      }
    ).pipe(
      map((response: ICouponResponse<IFetchCollectCoupon>) => { return response }), shareReplay(1)
    );
  }

  fetchRedeemCoupon(acccessToken: string, couponId: string, webTokenId: string, couponOrder?: number): Observable<ICouponResponse<IFetchRedeemCoupon>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.post<ICouponResponse<IFetchRedeemCoupon>>(
      this.api_url + `/api/v1/coupons/redeem/${couponId}`, { couponOrder: couponOrder },
      {
        headers: headers,
      }
    ).pipe(
      map((response: ICouponResponse<IFetchRedeemCoupon>) => { return response })
    );
  }

  fetchConfirmRedeemCoupon(acccessToken: string, couponId: string, webTokenId: string, couponOrder?: number): Observable<IFetchConfirmRedeemCoupon> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.post<IFetchConfirmRedeemCoupon>(this.api_url + `/api/v1/coupons/confirm-redeemed/${couponId}`, { couponOrder: couponOrder },
      {
        headers: headers,
      }
    ).pipe(
      map((response: IFetchConfirmRedeemCoupon) => { return response })
    );
  }

  fetchCouponList(code: string, accessToken: string, webTokenId: string, amount: string = '0'): Observable<ICouponResponse<IFetchCouponList>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId);

    return this.http.get<ICouponResponse<IFetchCouponList>>(
      this.api_url + `/api/v1/eligible-coupons?amount=${amount}`,
      {
        headers: headers,
      }
    ).pipe(
      map((response: ICouponResponse<IFetchCouponList>) => {
        return transformIFetchCouponList(response)
      }),
      shareReplay(1),
    );
  }

  reseverCouponOrder(acccessToken: string, couponId: string, couponOrder: number, webTokenId: string, mode?: string): Observable<ICouponResponse<IFetchReseverCouponOrder>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.post<ICouponResponse<IFetchReseverCouponOrder>>(
      this.api_url + `/api/v1/coupons/reserve-order`, { campaignId: couponId, receivedOrder: couponOrder, mode: mode },
      {
        headers: headers,
      }
    ).pipe(
      map((response: ICouponResponse<IFetchReseverCouponOrder>) => { return response })
    );
  }

  getCustomerTutorialCoachMark(acccessToken: string, webTokenId: string): Observable<ICouponResponse<ICustomerTutorial>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.get<ICouponResponse<ICustomerTutorial>>(
      this.api_url + `/api/v1/customers/tutorial`, { headers: headers }
    ).pipe(
      map((response: ICouponResponse<ICustomerTutorial>) => { return response })
    );
  }

  updateCustomerTutorialCoachMark(acccessToken: string, webTokenId: string): Observable<IFetchCompleteCoachMark> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('web-token-id', webTokenId)

    return this.http.post<IFetchCompleteCoachMark>(
      this.api_url + `/api/v1/customers/tutorial`, {}, { headers: headers }
    ).pipe(
      map((response: IFetchCompleteCoachMark) => { return response })
    );
  }

  fetchCollectApplyCoupon(code: string, acccessToken: string, keyword: string, webTokenId: string): Observable<ICouponResponse<ICollectApplyCoupon>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + acccessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId)

    return this.http.post<ICouponResponse<ICollectApplyCoupon>>(
      this.api_url + `/api/v1/coupons/collect/applyCouponCode`, { couponCode: keyword },
      {
        headers: headers,
      }
    ).pipe(
      map((response: ICouponResponse<ICollectApplyCoupon>) => { return response }), shareReplay(1)
    );
  }

  fetchApplyCouponDetail(code: string, accessToken: string, couponId: any, webTokenId: string): Observable<ICouponResponse<IApplyCouponDetail>> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-Auth-Code', code)
      .set('web-token-id', webTokenId)
      .set('Cache-Control', 'no-cache');

    return this.http.get<ICouponResponse<IApplyCouponDetail>>(this.api_url + `/api/v1/coupons/${couponId}?mode=applyCouponCode`,
      { headers: headers }).pipe(
        map((response: ICouponResponse<IApplyCouponDetail>) => { return transformIFetchApplyCouponDetail(response) }), shareReplay(1)
      );
  }

}