import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safe'
})
export class SafePipe implements PipeTransform {

	constructor(protected sanitizer: DomSanitizer) { }

	public transform(value: string, type: string) {
		switch (type) {
			case 'html': return this.sanitizer.sanitize(SecurityContext.HTML, value);
			case 'url': return this.sanitizer.sanitize(SecurityContext.URL, value);
			default: return value;
		}
	}
}