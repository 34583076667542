import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as RootStoreReducer from './root-reducers';
import { RootStoreEffects } from './root-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({ session: RootStoreReducer.sessionReducer }, {}),
    EffectsModule.forRoot([RootStoreEffects]),
  ],
})
export class RootStoreModule {}
