import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subject, filter, take } from 'rxjs';
import { ResponseCode, modalWording } from 'src/app/configs/app-config';
import { convertToLocaleDateString, convertToLocaleTimeString } from 'src/app/helpers/date-time.helper';
import { AdditionalParams, BadgeLabel, CopayNavigationType, CouponChannel, CouponEntryPoint, CouponMethod, CouponStatus, DeductionType, InternalNavigationType, PeriodStatus } from 'src/app/model/coupon-enum.model';
import { ICouponInfo, ICouponModel } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { ModalService } from 'src/app/service/modal.service';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { CouponService } from 'src/shared/coupon.service';
import { ModalTemplateComponent } from '../../modal-template/modal-template.component';
import { checkRegisterStatus, checkRegisterStatusAndType, handleErrorCouponStatus, mappingUserSOFWithCoupon } from 'src/app/helpers/coupon.helper';
import { isNil } from 'lodash';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';

@Component({
  selector: 'search-coupon-card-horizontal',
  templateUrl: './search-coupon-card-horizontal.component.html',
  styleUrls: ['./search-coupon-card-horizontal.component.scss']
})
export class SearchCouponCardHorizontalComponent implements OnInit {

  @ViewChildren('pickCouponCallbackUrl') pickCouponCallbackUrl: QueryList<ElementRef>;
  @ViewChildren("couponCard") couponCards: QueryList<ElementRef>;
  @ViewChild('coachMarkButton', { static: false }) coachMarkButton: ElementRef;
  @Input() prefixId: string = ''; // Use For Unit test
  private _coupons: ICouponModel[];
  @Input()
  set coupons(v: any) {
    this._coupons = v;
  }

  public get coupons(): ICouponModel[] {
    return this._coupons
  }

  @Input() selectedCoupon: SelectedCoupon | null;
  @Input() couponChannel: string;
  @Input() entryPoint: string;
  @Input() chooseCouponCallbackUrl: string;
  @Input() paymentCallbackUrl: string;
  @Input() registerCallbackUrl: string;
  @Input() processingCallbackUrl: string;
  @Input() searchKeyword: string;
  @Input() userSOF: string[] = [];
  @Output() onActionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponCopay: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetStepRoute: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOpenDialogButtom: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionNavigateToCouponDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSelectedCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPermissionError: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetToLoadFech: EventEmitter<any> = new EventEmitter<any>();
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  BadgeLabel = BadgeLabel;
  CouponMethod = CouponMethod;
  DeductionType = DeductionType;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  isLoadingButton: boolean = false;
  collectedCoupon: string[] = [];
  sofList: string[] = [];
  private _destroy = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private couponService: CouponService,
    private modalService: ModalService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.couponCollectedData$.next([]);
  }

  ngAfterViewInit() {
    this.updateContentScroll();
    this.setScrollContent();
  }

  updateContentScroll() {
    this.couponCards.notifyOnChanges();
    this.changeDetectorRef.detectChanges();
  }

  setScrollContent() {
    if (this.couponCards) {
      let couponScroll = undefined;
      const couponStatus = this.selectedCoupon?.couponStatus === CouponStatus.COLLECTED || this.selectedCoupon?.couponStatus === CouponStatus.REDEEMED ? CouponStatus.COLLECTED + CouponStatus.REDEEMED : this.selectedCoupon?.couponStatus;
      couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}_${couponStatus}`));
      couponScroll?.nativeElement?.scrollIntoView();
    }
  }

  identify(index: number, coupon: any) {
    return coupon.couponId;
  }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

  getCouponCard(index: number) {
    return this.couponCards.toArray()[index];
  }

  getPrefixCouponStatus(couponInfo: ICouponInfo[]) {
    const multipleStatus = couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.COLLECTED || couponInfo.status === CouponStatus.REDEEMED || couponInfo.status === CouponStatus.NOT_ELIGIBLE_TO_USE)
    if (multipleStatus) return CouponStatus.COLLECTED + CouponStatus.REDEEMED;
    return couponInfo[0].status;
  }

  isDisplayButton(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayBadgeLabel(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayMultipleCoupons(status: string, numberCoupon: number, couponOrder: number) {
    return status === CouponStatus.NEW || status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || (status === CouponStatus.OUT_OF_USED_QUOTA && numberCoupon <= 1 && couponOrder === 0);
  }

  isDisplayProgressBarLabel(status: string) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status as CouponStatus);
  }

  displayNumberOfQuota(couponstatus: string, repetitiveStatus: boolean, numberOfTemporaryOutOfQuotaCoupon: number, numberOfOutOfQuotaCoupon: number, maximumRepetitiveCouponPerUser: number, maximumCouponPerUser: number) {

    if (couponstatus === CouponStatus.TEMPO_OUT_OF_QUOTA) {
      return numberOfTemporaryOutOfQuotaCoupon;
    }

    if (couponstatus === CouponStatus.OUT_OF_QUOTA) {
      return numberOfOutOfQuotaCoupon;
    }

    if (repetitiveStatus) {
      return maximumRepetitiveCouponPerUser;
    }

    return maximumCouponPerUser;
  }

  loadAnimationCollectButton(value: boolean = false) {
    const wrapCouponClass = document?.querySelector(".coupon-list-card")
    const loadpageClass = document?.querySelector(".loading-page");
    if (value) {
      wrapCouponClass?.classList?.add('disabled-click-action')
      loadpageClass?.classList?.add('loading-style')
    } else {
      wrapCouponClass?.classList?.remove('disabled-click-action')
      loadpageClass?.classList?.remove('loading-style')
    }
  }

  disableActionCard(element: ElementRef<any>, value: boolean = false) {
    if (value) {
      element.nativeElement.classList.add('disabled-click-action')
    } else {
      element.nativeElement.classList.remove('disabled-click-action')
    }
  }

  isInChannel(coupon: ICouponModel) {
    if (coupon.copayChannels && coupon.couponUseMethod == CouponMethod.COPAY) {
      if (coupon.copayChannels[0] === CouponChannel.PT && !coupon.copayChannels.includes(this.couponChannel || '')) {
        return false
      }

      // Scan QR only use on PT
      if (coupon.copayChannels[0] === CouponChannel.PT && coupon.isUseButtonDisplay && (this.entryPoint === CouponEntryPoint.PWP || this.couponChannel !== CouponChannel.PT)) {
        return false
      }

      if (coupon.copayChannels[0] === CouponChannel.PW && (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate) && this.entryPoint !== CouponEntryPoint.PWP) {
        return false;
      }

      if (coupon.copayChannels[0] === CouponChannel.PW && (this.couponChannel !== CouponChannel.PT || (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate))) {
        return false
      }

      return true
    }
    return true
  }

  setStepRouteFlag(couponId: string) {
    this.onActionSetStepRoute.emit(couponId);
  }

  setToLoadFech() {
    this.onActionSetToLoadFech.emit();
  }

  openOverlay(coupon: ICouponModel) {
    const openOverlayEmit = {
      coupon: coupon,
      isInChannel: this.isInChannel(coupon)
    };
    this.emitOpenDialogButtom.emit(openOverlayEmit);
  }

  navigateToCouponDetail(coupon: ICouponModel, index: number = 0) {

    let selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle,
      numberOfCoupons: coupon.numberOfCoupons,
      couponInfo: [] as ICouponInfo[]
    };

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  pickCoupon(couponId: string, couponOrder: number, campaignCode: string, couponShortTitle: string, index: number = 0) {
    const cardEl = this.getCouponCard(index);
    this.couponService.isLoadingCollectCoupon$.next(true);
    this.loadAnimationCollectButton(true)
    cardEl.nativeElement.classList.remove('rotating');

    const collectedCoupon = {
      element: cardEl,
      couponId: couponId,
      couponOrder: couponOrder,
      campaignCode: campaignCode,
      couponShortTitle: couponShortTitle
    };
    this.onActionPickCoupon.emit(collectedCoupon)
  }

  useCoupon(coupon: ICouponModel, cardIndex: number, index: number = 0) {
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponShortTitle: coupon.couponShortTitle,
        hasCountdownTimer: coupon.hasCountdownTimer,
        couponInfo: [] as any
      }
    }

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.coupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionUseCoupon.emit(selectCoupon)
  }

  useScanCopayCoupon(event: any, coupon: ICouponModel, couponOrder: number, elementId: string, cardIndex: number, mode?: string) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);

    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle
    }

    this.onActionUseCouponCopay.emit(gaObject);

    const selectCoupon = {
      couponId: coupon.couponId,
      hasCountdownTimer: coupon.hasCountdownTimer,
      couponInfo: [coupon.couponInfo[0]] ?? []
    }

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.sofList = mappingUserSOFWithCoupon(this.userSOF, response.sof);
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {

          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.setStepRouteFlag(coupon.couponId);
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            const cardEl = this.getCouponCard(cardIndex);
            this.disableActionCard(cardEl, false);
            this.setToLoadFech();
          });
          return
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          this.couponService.clearReserveCouponOrder();
          this.onActionSelectedCoupon.emit(selectCoupon);

          // Support PT Old version !! Remove deeplink next phase
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            return;
          }

          if (mode === 'ScanCoPay') {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: interfaceToNativeContentDestination.DESTINATION_SCAN_COPAY,
                  sof: this.sofList,
                  campaignId: coupon.couponId,
                  callbackMode: "S",
                  additionalParams: `${AdditionalParams.SEARCH_KEYWORD}=${encodeURIComponent(this.searchKeyword)}`
                }
              }
            );
            // Support PT Old version !! Remove next phase
            if (this.pickCouponCallbackUrl) {
              const allCoupons = this.pickCouponCallbackUrl.toArray();
              const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
              pickCoupon!.nativeElement.click();
            }
          }
          this.disableActionCard(cardEl, false);
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            const cardEl = this.getCouponCard(cardIndex);
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }

  usePaymentTopup(coupon: ICouponModel, couponOrder: number, cardIndex: number, index: number = 0, mode?: string) {
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);
    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode,
      couponShortTitle: coupon.couponShortTitle,
      destination: coupon.copayNavigation.destination
    }
    this.onActionUseCouponPaymentTopup.emit(gaObject);
    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
            isNavigate: true
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.setStepRouteFlag(coupon.couponId)
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.couponService.loadFetchCoupons();
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          // Support PT Old version !! Remove deeplink next phase
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            this.disableActionCard(cardEl, false);
            this.couponService.clearReserveCouponOrder();
            return;
          }

          this.disableActionCard(cardEl, false);
          this.couponService.clearReserveCouponOrder();
          //check destination
          this.webviewToNativeInterfaceService.interfaceToNative(
            {
              command: interfaceToNativeCommand.COMMAND_ROUTING,
              content: {
                destination: coupon.copayNavigation.destination,
                payeeCode: coupon.copayNavigation.compCode,
                campaignId: coupon.couponId,
                channel: "PT",
                callbackMode: "S",
                additionalParams: `${AdditionalParams.SEARCH_KEYWORD}=${encodeURIComponent(this.searchKeyword)}`
              }
            }
          );
          this.setStepRouteFlag(coupon.couponId);
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }

  navigateExternalURL(coupon: ICouponModel, cardIndex: number, index: number = 0) {
    const cardEl = this.getCouponCard(cardIndex);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponShortTitle: coupon.couponShortTitle,
        couponInfo: [coupon.couponInfo[index]] ?? []
      },
      externalUrl: coupon.copayNavigation.url
    }
    this.onActionUseCouponExternalURL.emit(selectCoupon);
  }
}
