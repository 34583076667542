<table class="wrap-content" [attr.data-testid]="'coupon-bottom-dialog'">
    <tr *ngFor="let coupon of coupons.couponInfo; let i = index" [attr.data-testid]="'coupon-bottom-dialog-row' + i"
        #couponCard>
        <td class="coupon-column wrap-coupon" (click)="navigateToCouponDetail(coupons, i)">
            <img class="mask-left" [src]="coupons.couponImage" loading="lazy"
                [attr.data-testid]="'link-coupon-image-detail' + i">
            <!-- Stamp coupon need to comfirm status with BE -->
            <div [ngClass]="{
                        'stamp-used': coupon.status === CouponStatus.REDEEM_CONFIRMED, 
                        'stamp-expired': coupon.status === CouponStatus.EXPIRED, 
                        'stampsold-out': coupon.status === CouponStatus.OUT_OF_QUOTA || coupon.status === CouponStatus.OUT_OF_USED_QUOTA,
                        'stamp-tempo-sold-out': coupon.status === CouponStatus.TEMPO_OUT_OF_QUOTA,
                        'stamp-unconditional': coupon.status === CouponStatus.NOT_ELIGIBLE_TO_USE }">
            </div>

            <!-- Badge Level parameter with BE -->
            <ng-container *ngIf="isDisplayBadgeLabel(coupon.status)">
                <div class="badge badge-green" *ngIf="coupons.badgeLabel === BadgeLabel.NEW">New</div>
                <div class="badge badge-red" *ngIf="coupons.badgeLabel === BadgeLabel.HOT">Hot</div>
            </ng-container>
            <!-- <div class="badge badge-blue" *ngIf="i == 2">Only You</div> -->
        </td>

        <td class="wrap-coupon">
            <div class="shadow-content">
                <div class="box-content mask-right">
                    <div class="padding-content box-top-height" [attr.data-testid]="'link-card-coupon-detail-' + i"
                        (click)="navigateToCouponDetail(coupons, i)">

                        <h4 class="newline-wrap coupon-title" [id]="'title-'+i">{{coupons.couponShortTitle}}</h4>
                        <span class="newline-wrap coupon-description" [id]="'description-'+i">{{
                            coupons.couponSubtitle }}</span>

                        <!-- Check logic progress bar >= 80 && <= 99 and check parameter with BE -->
                        <div class="section-progress"
                            *ngIf="isDisplayProgressBarLabel(coupon.status) && !coupons.isHiddenProgressBar && coupons.progressionPercent >= 80 && coupons.progressionPercent <= 99">
                            <div
                                *ngIf="(coupons.deductionType === DeductionType.COLLECTED && coupon.status === CouponStatus.NEW) || coupons.deductionType === DeductionType.REDEEMED">
                                <div class="is-inline">
                                    <progress class="progress orange" [value]="coupons.progressionPercent"
                                        max="100"></progress>
                                    <div class="is-inline-block v-align-top">
                                        <div class="pl-2">
                                            <span class="text-progress">{{coupon.status === CouponStatus.COLLECTED
                                                ?
                                                (
                                                "ใช้แล้ว"
                                                ) : (
                                                coupons.deductionType === DeductionType.COLLECTED ?
                                                ( "เก็บแล้ว" ) : ( "ใช้แล้ว" )
                                                )}}</span>
                                            <span class="text-progress-num orange">
                                                {{coupons.progressionPercent}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-bottom">
                        <table class="wrap-content"
                            [class.table-fixed]="coupon.status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                            <tr>
                                <td class="v-align-bottom" width="80%"
                                    [class.coupon-info-ellipsis-text]="coupon.status === CouponStatus.NOT_ELIGIBLE_TO_USE"
                                    (click)="navigateToCouponDetail(coupons, i)">

                                    <!-- Check status display bottom text with parameter from BE  -->
                                    <span class="font-light expire-text"
                                        *ngIf="coupon.status === CouponStatus.REDEEM_CONFIRMED">
                                        วันที่ใช้ {{convertDateFormat(coupon.redeemedDate)}}
                                    </span>
                                    <span class="font-light expire-text"
                                        *ngIf="coupon.status === CouponStatus.OUT_OF_QUOTA">
                                        ถูกเก็บหมดแล้ว
                                    </span>
                                    <span class="font-light expire-text"
                                        *ngIf="coupon.status === CouponStatus.OUT_OF_USED_QUOTA">
                                        ถูกใช้หมดแล้ว
                                    </span>
                                    <span class="font-light disabled-interactive-text"
                                        *ngIf="coupon.status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                        กดเพื่อดูเงื่อนไขการเก็บคูปองในครั้งถัดไป
                                    </span>

                                    <ng-container *ngIf="coupon.status === CouponStatus.NEW">
                                        <ng-container *ngIf="coupon.periodStatus === PeriodStatus.ALLOWED">
                                            <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                                [id]="'showDate' + i">
                                                หมดอายุ {{ convertDateFormat(coupon.couponEndDatetime)
                                                + ' ' +
                                                convertTimeFormat(coupon.couponEndDatetime) + ' น.' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="coupon.periodStatus === PeriodStatus.PERIOD">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertTimeFormat(coupon.couponStartDatetime)+ '-' +
                                                convertTimeFormat(coupon.couponEndDatetime) + ' น.' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="coupon.periodStatus === PeriodStatus.DATE">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertDateFormat(coupon.couponStartDatetime) }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="coupon.periodStatus === PeriodStatus.START_COLLECT">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertTimeFormat(coupon.couponStartDatetime) + ' น.'
                                                }}
                                            </span>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="coupon.status === CouponStatus.COLLECTED">
                                        <ng-container *ngIf="isInChannel(coupons) else notInChannel">
                                            <ng-container *ngIf="coupon.periodStatus === PeriodStatus.ALLOWED">
                                                <span class="font-light expire-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    หมดอายุ {{
                                                    convertDateFormat(coupon.couponEndRedemptionDateTime)
                                                    + ' ' +
                                                    convertTimeFormat(coupon.couponEndRedemptionDateTime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                            <ng-container *ngIf="coupon.periodStatus === PeriodStatus.PERIOD">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertTimeFormat(coupon.couponStartRedemptionDatetime)
                                                    + '-' +
                                                    convertTimeFormat(coupon.couponEndRedemptionDateTime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                            <ng-container *ngIf="coupon.periodStatus === PeriodStatus.DATE">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertDateFormat(coupon.couponStartRedemptionDatetime)
                                                    }}
                                                </span>
                                            </ng-container>

                                            <ng-container *ngIf="coupon.periodStatus === PeriodStatus.START_REDEEM">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertTimeFormat(coupon.couponStartRedemptionDatetime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                        </ng-container>

                                        <ng-template #notInChannel>
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i">
                                                ไม่สามารถใช้กับช่องทางนี้ได้
                                            </span>
                                        </ng-template>
                                    </ng-container>

                                    <span class="font-light expire-text"
                                        *ngIf="(coupon.status === CouponStatus.REDEEMED || coupon.status === CouponStatus.EXPIRED)"
                                        [attr.data-testid]="'couponListshowDate-' + i" [id]="'showDate' + i">
                                        หมดอายุ {{ convertDateFormat(coupon.couponEndRedemptionDateTime) + ' ' +
                                        (convertTimeFormat(coupon.couponEndRedemptionDateTime)) + ' น.' }}
                                    </span>
                                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                        *ngIf="coupon.status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                        {{coupon.segmentErrorMessage}}
                                    </span>
                                </td>
                                <td *ngIf="isDisplayButton(coupon.status)">
                                    <button *ngIf="coupon.status == CouponStatus.REDEEMED"
                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                        [attr.data-testid]="'btn-collected-redeem-' + i"
                                        (click)="useCoupon(coupons, coupon.receivedOrder, i)">
                                        ดูโค้ด
                                    </button>

                                    <ng-container
                                        *ngIf="coupon.periodStatus === PeriodStatus.ALLOWED else disabledAction">
                                        <ng-container
                                            *ngIf="coupons.couponUseMethod === CouponMethod.COPAY else QRCoupon">
                                            <ng-container *ngIf="isInChannel(coupons); else NotInChannelButton">

                                                <ng-container *ngIf="coupon.status == CouponStatus.COLLECTED">

                                                    <ng-container
                                                        *ngIf="coupons.isUseButtonDisplay && !coupons.isCoPayNavigate">

                                                        <a #pickCouponCallbackUrl
                                                            class="is-hidden button font-bold btn-coupon btn-redeem is-pulled-right"
                                                            [id]="'btn-redeem-' + i"
                                                            [attr.data-testid]="'btn-redeem-' + i"
                                                            [href]="paymentCallbackUrl.replace('${campaignId}', coupons.couponId) + '&callbackMode=H' | safe : 'url'"
                                                            (click)="setStepRouteFlag($event)">
                                                            ใช้
                                                        </a>

                                                        <!-- Used copay -->
                                                        <button
                                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                            [attr.data-testid]="'btn-collected-redeem-' + i"
                                                            (click)="useScanCopayCoupon(coupons, coupon.receivedOrder, 'btn-redeem-' + i, i, 'ScanCoPay')">
                                                            ใช้
                                                        </button>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="!coupons.isUseButtonDisplay && coupons.isCoPayNavigate">
                                                        <ng-container
                                                            *ngIf="coupons.copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT">

                                                            <button
                                                                *ngIf="coupons.copayNavigation.destination === InternalNavigationType.PT_PAYMENT || 
                                                                    coupons.copayNavigation.destination === InternalNavigationType.PT_TOPUP"
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                (click)="usePaymentTopup($event, coupons, coupon.receivedOrder, i, 'ScanCoPay')">
                                                                ใช้
                                                            </button>

                                                            <button
                                                                *ngIf="coupons.copayNavigation.destination !== InternalNavigationType.PT_PAYMENT && 
                                                                    coupons.copayNavigation.destination !== InternalNavigationType.PT_TOPUP"
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                (click)="usePaymentTopup($event, coupons, coupon.receivedOrder, i, 'ScanCoPay')">
                                                                ใช้
                                                            </button>

                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="coupons.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_URL || 
                                                                coupons.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK">

                                                            <button
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                (click)="navigateExternalURL(coupons , i)">
                                                                ใช้
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                            </ng-container>

                                            <ng-template #NotInChannelButton>
                                                <button *ngIf="coupon.status === CouponStatus.COLLECTED"
                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                    [attr.data-testid]="'btn-collected-disabled-' + i">ใช้
                                                </button>
                                            </ng-template>
                                        </ng-container>

                                        <ng-template #QRCoupon>
                                            <!-- Use QR Code -->
                                            <button *ngIf="coupon.status == CouponStatus.COLLECTED"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                (click)="useCoupon(coupons, coupon.receivedOrder, i)">
                                                ใช้
                                            </button>

                                        </ng-template>
                                    </ng-container>

                                    <!-- Disabled Action -->
                                    <ng-template #disabledAction>
                                        <button
                                            *ngIf="(coupon.status === CouponStatus.NEW || coupon.status === CouponStatus.COLLECTED) && 
                                            (coupon.periodStatus === PeriodStatus.PERIOD || coupon.periodStatus === PeriodStatus.DATE || coupon.periodStatus === PeriodStatus.START_REDEEM)"
                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                            [attr.data-testid]="'btn-collected-disabled-' + i">ใช้
                                        </button>
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</table>