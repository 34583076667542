import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { isEmpty } from "lodash";
import { filter, take } from "rxjs";
import { CouponService } from "src/shared/coupon.service";
import { CouponEventTagAction, CouponEventTagPageView } from "../model/coupon-ga.model";
import { AppState } from "../root-store/root-store.model";
import { RootStoreService } from "../root-store/root-store.service";
import * as CouponSelectors from '../store/coupon.selectors'
import { UtilService } from "./util.service";

declare let gtag: Function;
@Injectable({ providedIn: 'root' })
export class CouponGAService {
    accessToken: any;

    constructor(
        // private http: HttpClient,
        private utilService: UtilService,
        private couponService: CouponService
    ) {

        this.couponService.acessToken$.pipe(filter((v) => !isEmpty(v)), take(1))
            .subscribe((accessToken) => {
                this.accessToken = this.utilService.parseJwt(JSON.stringify(accessToken));
            })
    }

    trackActionGATag(gaEvent: CouponEventTagAction) {
        gtag('event', gaEvent.eventName, {
            'event_category': gaEvent.eventCategory,
            'event_label': gaEvent.eventLabel,
            'event_action': gaEvent.eventAction,
            'campaign_code': gaEvent.campaignCode,
            'channel': gaEvent.couponChannel,
            'user_id': this.accessToken?.ga2,
            'ga1': this.accessToken?.ga1,   // mobile_hmac
            'ga2': this.accessToken?.ga2,   // citizen_hmac
            'ga3': this.accessToken?.ga3,   // channel_id
            'ga4': this.accessToken?.ga4,   // deviced
            'ga5': this.accessToken?.ga5,   // callback
        });
    }

    trackPageViewGATag(pageView: CouponEventTagPageView) {
        gtag('event', 'page_view', {
            'page_title': pageView.pageTitle,
            'channel': pageView.couponChannel,
            'user_id': this.accessToken?.ga2,
            'ga1': this.accessToken?.ga1,   // mobile_hmac
            'ga2': this.accessToken?.ga2,   // citizen_hmac
            'ga3': this.accessToken?.ga3,   // channel_id
            'ga4': this.accessToken?.ga4,   // deviced
            'ga5': this.accessToken?.ga5,   // callback
        });
    }
}