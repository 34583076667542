import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { isNil } from 'lodash';
import { filter, Subject, take } from 'rxjs';
import { modalWording, ResponseCode } from 'src/app/configs/app-config';
import { checkRegisterStatus, checkRegisterStatusAndType, handleErrorCouponStatus, mappingUserSOFWithCoupon } from 'src/app/helpers/coupon.helper';
import { convertToLocaleDateString, convertToLocaleTimeString } from 'src/app/helpers/date-time.helper';
import { AdditionalParams, BadgeLabel, CopayNavigationType, CouponChannel, CouponEntryPoint, CouponMethod, CouponStatus, DeductionType, InternalNavigationType, PeriodStatus } from 'src/app/model/coupon-enum.model';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { IApplyCouponDetail, ICouponInfo } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { ModalService } from 'src/app/service/modal.service';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { CouponService } from 'src/shared/coupon.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { ModalTemplateComponent } from '../../modal-template/modal-template.component';
import { PageRoute } from 'src/app/configs/route-config';

@Component({
  selector: 'apply-coupon-card-horizontal',
  templateUrl: './apply-coupon-card-horizontal.component.html',
  styleUrls: ['./apply-coupon-card-horizontal.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, background: '#925505' }),
            animate('1s ease-in',
              style({ opacity: 0, background: '#2B77FF' }))
          ]
        )
      ]
    )
  ]
})
export class ApplyCouponCardHorizontalComponent implements OnInit {
  @ViewChildren('pickCouponCallbackUrl') pickCouponCallbackUrl: QueryList<ElementRef>;
  @ViewChildren("couponCard") couponCards: QueryList<ElementRef>;
  @ViewChild('coachMarkButton', { static: false }) coachMarkButton: ElementRef;
  private _coupons: IApplyCouponDetail[];
  @Input()
  set coupons(v: any) {
    this._coupons = v;
  }

  public get coupons(): IApplyCouponDetail[] {
    return this._coupons
  }

  @Input() selectedCoupon: SelectedCoupon | null;
  @Input() couponChannel: string;
  @Input() couponTab: string;
  @Input() entryPoint: string;
  @Input() chooseCouponCallbackUrl: string;
  @Input() paymentCallbackUrl: string;
  @Input() registerCallbackUrl: string;
  @Input() processingCallbackUrl: string;
  @Input() stepRouteConfig: string[] = [];
  @Input() applyCouponKeyword: string = '';
  @Input() userSOF: string[] = [];
  @Output() onActionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponCopay: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetStepRoute: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOpenDialogButtom: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionNavigateToCouponDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSelectedCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionPermissionError: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetToLoadFech: EventEmitter<any> = new EventEmitter<any>();
  isloadingAnimation$ = this.couponService.isLoadingAnimation$;
  PageRoute = PageRoute;
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  BadgeLabel = BadgeLabel;
  CouponMethod = CouponMethod;
  DeductionType = DeductionType;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  private _destroy = new Subject<void>();
  sofList: string[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private couponService: CouponService,
    private modalService: ModalService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,

  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.couponCollectedData$.next([]);
  }

  ngAfterViewInit() {
    this.updateContentScroll();
    this.setScrollContent();
  }

  updateContentScroll() {
    this.couponCards.notifyOnChanges();
    this.changeDetectorRef.detectChanges();
  }

  setScrollContent() {
    if (this.couponCards) {
      let couponScroll = undefined;
      const couponStatus = this.selectedCoupon?.couponStatus === CouponStatus.COLLECTED || this.selectedCoupon?.couponStatus === CouponStatus.REDEEMED ? CouponStatus.COLLECTED + CouponStatus.REDEEMED : this.selectedCoupon?.couponStatus;
      couponScroll = this.couponCards.toArray().find((element: ElementRef) => element.nativeElement.id === (`${this.selectedCoupon?.couponId}_${couponStatus}`));
      couponScroll?.nativeElement?.scrollIntoView();
    }
  }

  getPrefixCouponStatus(couponInfo: ICouponInfo[]) {
    const multipleStatus = couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.COLLECTED || couponInfo.status === CouponStatus.REDEEMED || couponInfo.status === CouponStatus.NOT_ELIGIBLE_TO_USE)
    if (multipleStatus) return CouponStatus.COLLECTED + CouponStatus.REDEEMED;
    return couponInfo[0].status;
  }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

  navigateToCouponDetail(coupon: IApplyCouponDetail, index: number = 0) {

    const selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      numberOfCoupons: coupon.numberOfCoupons,
      couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
    };

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  openOverlay(coupon: IApplyCouponDetail) {
    const openOverlayEmit = {
      coupon: coupon,
      isInChannel: this.isInChannel(coupon)
    };
    this.emitOpenDialogButtom.emit(openOverlayEmit);
  }


  useCoupon(coupon: IApplyCouponDetail, cardIndex: number, index: number = 0) {

    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        hasCountdownTimer: coupon.hasCountdownTimer,
        couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
      }
    }

    this.onActionUseCoupon.emit(selectCoupon)
  }

  setStepRouteFlag(couponId: string, isUseCoupon: boolean = false) {
    const selectCoupon = {
      couponId: couponId,
      isUseCoupon: isUseCoupon
    }
    this.onActionSetStepRoute.emit(selectCoupon)
  }

  setToLoadFech() {
    this.onActionSetToLoadFech.emit()
  }

  usePaymentTopup(coupon: IApplyCouponDetail, couponOrder: number, cardIndex: number, index: number = 0, mode?: string) {
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);

    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode,
      destination: coupon.copayNavigation.destination
    }

    this.onActionUseCouponPaymentTopup.emit(gaObject);

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
            isNavigate: true
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        switch (response.result.code) {
          case ResponseCode.ERROR_NOT_REGISTERED:
          case ResponseCode.ERROR_REGISTERING: {
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_REGISTERED]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
              [ResponseCode.ERROR_REGISTERING]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode,
                }
              ]
            };

            const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.setStepRouteFlag(coupon.couponId)
              this.couponService.clearReserveCouponOrder();
            });

            break;
          }

          case ResponseCode.ERROR_NOT_ELIGIBLE: {
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_ELIGIBLE]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
            };

            const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.changeDetectorRef.detectChanges();
              this.couponService.clearReserveCouponOrder();
              this.couponService.loadFetchCoupons();
            });
            break;
          }

          case ResponseCode.SUCCESS: {
            // Support PT Old version !! Remove next phase
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            // check sof for navigate to register
            if (response.data.isNavigate) {
              const firebaseParams: FirebaseParam[] = [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ];
              const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
              this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
                if (result) {
                  this.webviewToNativeInterfaceService.interfaceToNative(
                    {
                      command: interfaceToNativeCommand.COMMAND_ROUTING,
                      content: {
                        destination: result
                      }
                    }
                  );
                }
              });
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
              return;
            }

            this.disableActionCard(cardEl, false);
            this.couponService.clearReserveCouponOrder();

            this.webviewToNativeInterfaceService.interfaceToNative({
              command: interfaceToNativeCommand.COMMAND_ROUTING,
              content: {
                destination: coupon.copayNavigation.destination,
                payeeCode: coupon.copayNavigation.compCode,
                campaignId: coupon.couponId,
                channel: "PT",
                callbackMode: "A",
                additionalParams: `${AdditionalParams.APPLY_KEYWORD}=${encodeURIComponent(this.applyCouponKeyword)}`
              }
            });
            this.setStepRouteFlag(coupon.couponId);
            break;
          }

          default: {
            const modalObj: IModalTemplate = {
              title: modalWording.outOfService,
              detail: modalWording.pleaseTryAgainLater,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_API_ERROR,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode!
                  }
                ]
              },
              button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
              deeplinkCallbackUrl: undefined
            };

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
            });
            break;
          }
        }
      });
  }

  navigateExternalURL(coupon: IApplyCouponDetail, cardIndex: number, index: number = 0) {
    const cardEl = this.getCouponCard(cardIndex);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
      },
      externalUrl: coupon.copayNavigation.url
    }

    this.onActionUseCouponExternalURL.emit(selectCoupon)
  }

  useScanCopayCoupon(event: any, coupon: IApplyCouponDetail, couponOrder: number, elementId: string, cardIndex: number, mode?: string) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(cardIndex);
    this.disableActionCard(cardEl, true);

    const gaObject = {
      eventLabel: 'Use',
      campaignCode: coupon.campaignCode
    }

    this.onActionUseCouponCopay.emit(gaObject);

    const selectCoupon = {
      couponId: coupon.couponId,
      hasCountdownTimer: coupon.hasCountdownTimer,
      couponInfos: [coupon.couponInfos[0]] ?? [] as ICouponInfo[]
    }

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          let permissionError = {
            campaignCode: coupon.campaignCode,
            permisionCoupon: response,
          };
          this.onActionPermissionError.emit(permissionError);
          return;
        }

        this.sofList = mappingUserSOFWithCoupon(this.userSOF, response.sof);
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        switch (response.result.code) {
          case ResponseCode.ERROR_NOT_REGISTERED:
          case ResponseCode.ERROR_REGISTERING: {
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";

            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_REGISTERED]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
              [ResponseCode.ERROR_REGISTERING]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ]
            };

            const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.setStepRouteFlag(coupon.couponId);
              this.couponService.clearReserveCouponOrder();
            });
            break;
          }

          case ResponseCode.ERROR_NOT_ELIGIBLE: {
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_ELIGIBLE]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
            };
            const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              const cardEl = this.getCouponCard(cardIndex);
              this.disableActionCard(cardEl, false);
              this.setToLoadFech()
            });
            break;
          }

          case ResponseCode.SUCCESS: {
            // Support PT Old version !! Remove next phase
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            // check sof for navigate to register
            if (response.data.isNavigate) {
              const firebaseParams: FirebaseParam[] = [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ];
              const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
              this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
                if (result) {
                  this.webviewToNativeInterfaceService.interfaceToNative(
                    {
                      command: interfaceToNativeCommand.COMMAND_ROUTING,
                      content: {
                        destination: result
                      }
                    }
                  );
                }
              });
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
              return;
            }

            this.onActionSelectedCoupon.emit(selectCoupon);
            this.couponService.clearReserveCouponOrder();
            if (mode === 'ScanCoPay' && !this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST)) {
              this.webviewToNativeInterfaceService.interfaceToNative(
                {
                  command: interfaceToNativeCommand.COMMAND_ROUTING,
                  content: {
                    destination: interfaceToNativeContentDestination.DESTINATION_SCAN_COPAY,
                    sof: this.sofList,
                    campaignId: coupon.couponId,
                    callbackMode: "A",
                    additionalParams: `${AdditionalParams.APPLY_KEYWORD}=${encodeURIComponent(this.applyCouponKeyword)}`
                  }
                }
              );
              // Support PT Old version !! Remove next phase
              if (this.pickCouponCallbackUrl) {
                const allCoupons = this.pickCouponCallbackUrl.toArray();
                const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
                pickCoupon!.nativeElement.click();
              }
            } else {
              if (this.pickCouponCallbackUrl) {
                const allCoupons = this.pickCouponCallbackUrl.toArray();
                const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
                pickCoupon!.nativeElement.click();
              }
            }
            this.disableActionCard(cardEl, false);
            break;
          }

          default: {
            const modalObj: IModalTemplate = {
              title: modalWording.outOfService,
              detail: modalWording.pleaseTryAgainLater,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_API_ERROR,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode
                  }
                ]
              },
              button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
              deeplinkCallbackUrl: undefined
            };

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              const cardEl = this.getCouponCard(cardIndex);
              this.disableActionCard(cardEl, false);
            });
            break;
          }
        }
      });
  }

  isInChannel(coupon: any) {
    if (coupon.copayChannels && coupon.couponUseMethod == CouponMethod.COPAY) {

      if (coupon.copayChannels[0] === CouponChannel.PT && !coupon.copayChannels.includes(this.couponChannel || ''))
        return false;

      // Scan QR and Paotang Internal page only use on PT
      if (coupon.copayChannels[0] === CouponChannel.PT && (coupon.isUseButtonDisplay || (coupon.isCoPayNavigate && coupon.copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT)) && (this.entryPoint === CouponEntryPoint.PWP || this.couponChannel !== CouponChannel.PT)) {
        return false
      }

      //No Navigate
      if (coupon.copayChannels[0] === CouponChannel.PW && (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate) && this.entryPoint !== CouponEntryPoint.PWP)
        return false;

      if (!this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST) && coupon.copayChannels[0] === CouponChannel.PW && (this.couponChannel !== CouponChannel.PT || (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate)))
        return false;

      return true;
    }

    if (this.stepRouteConfig[this.stepRouteConfig.length - 2] === PageRoute.PATH_COUPON_LIST && coupon.couponUseMethod !== CouponMethod.COPAY) {
      return false;
    }

    return true;
  }

  isDisplayBadgeLabel(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayProgressBarLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  isDisplayButton(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayMultipleCoupons(status: string, numberCoupon: number, couponOrder: number) {
    return status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || (status === CouponStatus.OUT_OF_USED_QUOTA && numberCoupon <= 1 && couponOrder === 0);
  }

  isCouponApplyCodeQRONCouponList(coupon: any) {
    return this.stepRouteConfig[this.stepRouteConfig.length - 2] === PageRoute.PATH_COUPON_LIST && coupon.couponUseMethod !== CouponMethod.COPAY
  }

  getCouponCard(index: number) {
    return this.couponCards.toArray()[index];
  }

  disableActionCard(element: ElementRef<any>, value: boolean = false) {
    if (value) {
      element.nativeElement.classList.add('disabled-click-action')
    } else {
      element.nativeElement.classList.remove('disabled-click-action')
    }
  }

  loadAnimationCollectButton(value: boolean = false) {
    const wrapCouponClass = document?.querySelector(".coupon-list-card")
    const loadpageClass = document?.querySelector(".loading-page");
    if (value) {
      wrapCouponClass?.classList?.add('disabled-click-action')
      loadpageClass?.classList?.add('loading-style')
    } else {
      wrapCouponClass?.classList?.remove('disabled-click-action')
      loadpageClass?.classList?.remove('loading-style')
    }
  }

  identify(index: number, coupon: any) {
    return coupon.couponId;
  }
}

