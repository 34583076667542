<div class="container">
    <swiper #swiper class="swiper-container"
        [slidesPerView]="'auto'"
        [spaceBetween]="10"
        [slideToClickedSlide]="true"
        [centeredSlidesBounds]="true"
        [preventClicks]="true"
        [centeredSlides]="centeredSlides"
        [initialSlide]="indexSlide"
        (setTranslate)="onSetTranslate($event)"
        [freeMode]="true"
        (tap)="tap($event)"
        [updateOnWindowResize]="false"
        >
        <ng-template swiperSlide *ngFor="let category of categories ;let idx = index">
            <div class = "btn-category" 
                data-testid="category-button"
                [ngClass]="categoryActive === category.categoryUUID?'active-btn-category':'inactive-btn-category'"
                [class.first]="idx === 0"
                [class.last]="idx === categories.length - 1" 
                (click)="categoryActive !== category.categoryUUID ? onClickCategory(category.categoryUUID) : false "
                >
                {{category.name }}
            </div>
        </ng-template>
    </swiper>
</div>